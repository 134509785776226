import XhrRequest from "./xhrRequest";

interface Header {
  name: string;
  value: string;
}

class Network {
  private static jsonHeaders: Header[] = [{ name: "Content-Type", value: "application/json" }];
  private static pngHeaders: Header[] = [{ name: "Content-Type", value: "image/png" }];
  private static svgHeaders: Header[] = [{ name: "Content-Type", value: "image/svg+xml" }];
  private static octetStreamHeaders: Header[] = [
    { name: "Content-Type", value: "application/octet-stream" },
  ];

  // Generic fetch.
  static fetch(url: string, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(url, "GET", [], responseType);
  }

  // Generic remove.
  static remove(url: string, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(url, "DELETE", [], responseType);
  }

  // Fetches json from the given url and returns it as a parsed object.
  static fetchJSON(url: string): Promise<any> {
    return XhrRequest(url, "GET", this.jsonHeaders).then((responseValue: string) => {
      return new Promise((resolve, reject) => {
        resolve(JSON.parse(responseValue));
      });
    });
  }

  // Sends json to the given url. payload is object.
  static sendJSON(url: string, payload: any, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(
      url,
      "POST",
      this.jsonHeaders,
      responseType,
      JSON.stringify(payload)
    );
  }

  // Updates json to the given url. payload is object.
  static putJSON(url: string, payload: any, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(
      url,
      "PUT",
      this.jsonHeaders,
      responseType,
      JSON.stringify(payload)
    );
  }

  // Fetches image from the given url and returns it as an array buffer.
  static fetchImage(url: string): Promise<ArrayBuffer> {
    return XhrRequest(url, "GET", this.pngHeaders, "arraybuffer");
  }

  // Sends image to the given url. payload is blob.
  static sendImage(url: string, payload: Blob, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(url, "POST", this.pngHeaders, responseType, payload);
  }

  // Fetches svg from the given url and returns it as a string.
  static fetchSVG(url: string): Promise<string> {
    return XhrRequest(url, "GET", this.svgHeaders);
  }

  // Sends svg to the given url. payload is blob.
  static putSVG(url: string, payload: Blob, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(url, "PUT", this.svgHeaders, responseType, payload);
  }

  // Sends svg to the given url. payload is blob.
  static sendSVG(url: string, payload: Blob, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(url, "POST", this.svgHeaders, responseType, payload);
  }

  // Fetches octet-stream from the given url and returns it as an array buffer.
  // Usually used for fetching videos.
  static fetchOctetStream(url: string): Promise<ArrayBuffer> {
    return XhrRequest(url, "GET", this.octetStreamHeaders, "arraybuffer");
  }

  // Sends octet-stream to the given url. payload is blob.
  static sendOctetStream(url: string, payload: Blob, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(url, "POST", this.octetStreamHeaders, responseType, payload);
  }

  // Sends form to the given url. payload is FormData object.
  static sendForm(url: string, payload: FormData, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(url, "POST", [], responseType, payload);
  }

  static putForm(url: string, payload: FormData, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(url, "PUT", [], responseType, payload);
  }

  static patchJSON(url: string, payload: any, responseType: XMLHttpRequestResponseType = ""): Promise<any> {
    return XhrRequest(
      url,
      "PATCH",
      this.jsonHeaders,
      responseType,
      JSON.stringify(payload)
    );
  }
}

export default Network;