import Chunk from "./chunk";

class ChunkManager {
  private chunks: Chunk[]
  private firstFrames: number[]

  constructor() {
    this.chunks = [];
    this.firstFrames = [];
  }

  getFloorAmount(): number {
    return this.chunks.reduce((maxFloor, chunk) => {
      return Math.max(maxFloor, chunk.floor);
    }, 0) + 1;
  }

  getChunkAtIndex(index: number) {
    return this.chunks[index];
  }

  addChunk(chunk: Chunk) {
    this.chunks.push(chunk);
    this.firstFrames.push(chunk.firstFrameNumber);
  }

  removeChunk(chunk: Chunk) {
    const index = this.chunks.indexOf(chunk);
    if (index === -1) {
      return;
    }
    this.chunks.splice(index, 1);
    this.firstFrames.splice(index, 1);
  }

  getChunkByFrame(frameNumber: number): Chunk | null {
    for (let i = 0; i < this.firstFrames.length - 1; i++) {
      if (
        frameNumber >= this.firstFrames[i] &&
        frameNumber < this.firstFrames[i + 1]
      ) {
        return this.chunks[i];
      }
    }
    // If frameNumber is greater than the last firstFrameNumber, return the last chunk
    if (frameNumber >= this.firstFrames[this.firstFrames.length - 1]) {
      return this.chunks[this.chunks.length - 1];
    }
    // If no chunk is found, return undefined
    return null;
  }

  getAllChunks(): Chunk[] {
    return this.chunks;
  }

  getChunk(index: number): Chunk {
    return this.chunks[index];
  }

  getIndexForChunk(chunk: Chunk): number {
    return this.chunks.indexOf(chunk);
  }

  getChunksAfter(chunk: Chunk | null): Chunk[] {
    if (!chunk) {
      return this.chunks;
    }
    const index = this.chunks.indexOf(chunk);
    return this.chunks.slice(index + 1);
  }

  getChunksAfterInSameFloor(chunk: Chunk): Chunk[] {
    const index = this.chunks.indexOf(chunk);
    const floor = chunk.floor;
    const chunks = this.chunks.slice(index + 1);
    return chunks.filter((chunk) => chunk.floor === floor);
  }

  getAllChunksInFloor(floorNumber: number): Chunk[] {
    return this.chunks.filter((chunk) => chunk.floor === floorNumber);
  }

  splitChunk(chunk: Chunk, frameNumber: number): Chunk {
    const index: number = this.getIndexForChunk(chunk);
    const newChunk: Chunk = chunk.split(frameNumber);

    this.chunks.splice(index + 1, 0, newChunk);
    this.firstFrames.splice(index + 1, 0, frameNumber);

    return newChunk;
  }
}

export default ChunkManager;
