import { Command } from './command';
import { Chunk } from '../chunk';
import ChunkManager from '../chunkManager';


export class SplitChunkCommand implements Command {
    private chunk: Chunk;
    private splitFrameNumber: number;
    private originalFrames: any;
    private originalCanvas: HTMLCanvasElement;
    private originalFrameMap: any;
    private newChunk: Chunk | null = null;
    private chunkManager: ChunkManager;
  
    constructor(chunkManager: ChunkManager, chunk: Chunk, splitFrameNumber: number) {
      this.chunkManager = chunkManager;
      this.chunk = chunk;
      this.splitFrameNumber = splitFrameNumber;
      // Store original state to be able to undo the operation
      this.originalFrames = [...chunk.frames];
      this.originalCanvas = chunk.canvas;
      this.originalFrameMap = chunk.frameMap;
    }
  
    execute() {
      this.newChunk = this.chunkManager.splitChunk(this.chunk, this.splitFrameNumber);
      this.chunkManager.markDirty();
    }
  
    undo() {
      if (!this.newChunk) {
        return;
      }
      // Restore original state
      this.chunk.frames = this.originalFrames;
      this.chunk.canvas = this.originalCanvas;
      this.chunk.frameMap = this.originalFrameMap;

      this.chunkManager.removeChunk(this.newChunk);
      this.chunkManager.markDirty();
    }
  }