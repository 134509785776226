import { v4 as uuidv4 } from 'uuid';

import { Point, Rectangle } from './types';
import { IndexData } from './interfaces';

class Chunk {
  position: Point;
  sceneWidth: number;
  sceneHeight: number;
  globalRotation: number;
  canvas: OffscreenCanvas;
  pathStartPosition: Point;
  rotationPoint: Point;
  floor: number;
  chunkBoundaries: Rectangle;
  firstFrameNumber: number;
  shift: Point;
  rotation: number;
  frames: any;
  frameMap: any;
  id: string;

  constructor(
    sceneWidth: number,
    sceneHeight: number,
    chunkCanvas: OffscreenCanvas,
    chunkBoundaries: Rectangle, 
    frames: any,
    frameMap: IndexData,
  ) {
    this.position = Point.zero();
    this.sceneWidth = sceneWidth;
    this.sceneHeight = sceneHeight;
    this.globalRotation = 0;
    this.canvas = chunkCanvas;
    this.chunkBoundaries = chunkBoundaries;
    this.frames = frames;
    this.frameMap = frameMap;

    const pathStartPoint = frames[0].imageCameraPosition;
    this.pathStartPosition = Point.fromArray(pathStartPoint);
    this.rotationPoint = Point.fromArray(pathStartPoint);
            
    this.firstFrameNumber = frames[0].frameNumber;
    this.floor = frames[0].floorNumber;

    this.shift = Point.zero();
    this.rotation = 0;

    const context = this.canvas.getContext('2d', { willReadFrequently: true });

    if (context) {
      context.drawImage(chunkCanvas, 0, 0);
    }

    this.id = uuidv4();
  }

  moveBy(dx: number, dy: number, isShift: boolean): void {
    const delta = new Point(dx, dy);
    this.position = this.position.add(delta);

    this.pathStartPosition = this.pathStartPosition.add(new Point(dx, dy));

    if (isShift) {
      this.shift = this.shift.add(new Point(dx, dy));
    }
  }

  calculatePositionForRotation(rotationPoint: Point, point: Point, angle: number): Point {
    const delta = point.subtract(rotationPoint);
    return rotationPoint.add(delta.rotate(angle));
  }

  rotateAboutPoint(rotationPoint: Point, angle: number, isShift: boolean): void {
    const rotatedPoint = this.calculatePositionForRotation(rotationPoint, this.position, angle);
    this.position = rotatedPoint;
    this.globalRotation = (this.globalRotation + angle) % 360;
    this.rotationPoint = Point.zero();
    this.pathStartPosition = this.calculatePositionForRotation(
      rotationPoint,
      this.pathStartPosition,
      angle
    );

    if (isShift) {
      this.rotation += angle;
    }
  }
  
  containsPoint(point: Point): boolean {
    const localPoint = point.subtract(this.position);
    const rad = this.globalRotation * (Math.PI / 180);
    const cos = Math.cos(-rad);
    const sin = Math.sin(-rad);
    
    const rotatedPoint = new Point(
      cos * localPoint.x - sin * localPoint.y,
      sin * localPoint.x + cos * localPoint.y
    );

    const boundaryPoint = rotatedPoint.subtract(this.chunkBoundaries.startPoint);

    return (
      boundaryPoint.x >= 0 &&
      boundaryPoint.x <= this.chunkBoundaries.size.width &&
      boundaryPoint.y >= 0 &&
      boundaryPoint.y <= this.chunkBoundaries.size.height
    );
  }

  split(splitFrameNumber: number): Chunk {
    const newFrames = this.frames.filter((frame: any) => frame.frameNumber >= splitFrameNumber);
    const oldFrames = this.frames.filter((frame: any) => frame.frameNumber < splitFrameNumber);

    const oldCanvas = new OffscreenCanvas(this.canvas.width, this.canvas.height);
    const oldContext = oldCanvas.getContext('2d', { willReadFrequently: true });

    const newCanvas = new OffscreenCanvas(this.canvas.width, this.canvas.height);
    const newContext = newCanvas.getContext('2d', { willReadFrequently: true });
 
    const oldFrameMap: IndexData = { width: this.frameMap.width, height: this.frameMap.height, frameIndices: []}
    const newFrameMap: IndexData = { width: this.frameMap.width, height: this.frameMap.height, frameIndices: []}

    const ctx = this.canvas.getContext('2d', { willReadFrequently: true });
    for (let i = 0; i < this.frameMap.height; i += 1) {
      for (let j = 0; j < this.frameMap.width; j += 1) {
        const index = i * this.frameMap.width + j;
        const frameNumber = this.frameMap.frameIndices[index];
        if (frameNumber !== 0) {
            const imageData = ctx.getImageData(j, i, 1, 1);
          if (frameNumber < splitFrameNumber) {
            oldContext?.putImageData(imageData, j, i);
            oldFrameMap.frameIndices.push(frameNumber)  
            newFrameMap.frameIndices.push(0)
          } else {    
            newContext?.putImageData(imageData, j, i);
            newFrameMap.frameIndices.push(frameNumber)
            oldFrameMap.frameIndices.push(0)
          }
        } else {
          oldFrameMap.frameIndices.push(0)
          newFrameMap.frameIndices.push(0)
        }
      }
    }
    this.frames = oldFrames;
    this.canvas = oldCanvas
    this.frameMap = oldFrameMap

    const newChunk = new Chunk(
      this.sceneWidth,
      this.sceneHeight,
      newCanvas,
      this.chunkBoundaries,
      newFrames,
      newFrameMap
    );
    newChunk.rotateAboutPoint(this.rotationPoint, this.globalRotation, false);
    newChunk.moveBy(this.position.x, this.position.y, false);
    return newChunk;
  }
}

export default Chunk;