class Video {
  fileObjectUrl: string;
  arkitData: any; 
  currentFrame: number;
  framerate: number;
  playEvent: number | null;
  paused: boolean;
  lastFrame: number;
  video: HTMLVideoElement;
  videoWidth: number;
  videoHeight: number;
  lastTimestamp: number | null; 
  multiplier: number;
  private seekQueue: number[];
  private isProcessingSeek: boolean;
  private lastSeekTime: number;
  private readonly SEEK_THROTTLE_MS = 50; // Adjust this value based on testing
  private readonly multipliers = [1, 3, 5, 7];

  constructor(video: HTMLVideoElement, fileObjectUrl: string, arkitData: any) {
    this.video = video;
    this.fileObjectUrl = fileObjectUrl;
    this.arkitData = arkitData;
    this.currentFrame = 0;
    this.framerate = 1 / 10;
    this.playEvent = null;
    this.paused = true;
    this.lastFrame = this.arkitData.frames.length - 1;

    this.videoWidth = 0;
    this.videoHeight = 0;

    this.video.style.transform = 'translateZ(0)';
    this.video.src = fileObjectUrl;
    this.video.autoplay = false;
    this.video.loop = true;
    this.video.muted = true;
    this.video.preload = "auto";
    this.video.load();
    this.video.addEventListener("loadeddata", ()  => {
      this.videoWidth = this.video.videoWidth;
      this.videoHeight = this.video.videoHeight;
    });
    this.lastTimestamp = null;
    this.seekQueue = [];
    this.isProcessingSeek = false;
    this.lastSeekTime = 0;
    this.multiplier = 1;
    // Add buffering hints
    this.video.preload = "auto";
    this.video.addEventListener('loadedmetadata', () => {
      // Request buffering of the entire video
      if (this.video.buffered.length === 0) {
        this.video.load();
      }
    });

    // Handle tab/window visibility changes
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.releaseVideoResources();
      } else {
        this.restoreVideoResources();
      }
    });

    // Handle alt-tab or switching to other applications
    window.addEventListener('blur', () => {
      this.releaseVideoResources();
    });

    window.addEventListener('focus', () => {
      this.restoreVideoResources();
    });

    this.video.addEventListener('error', this.handleVideoError);
    this.video.addEventListener('stalled', () => {
      console.warn('Video playback stalled', {
        readyState: this.video.readyState,
        networkState: this.video.networkState,
        currentTime: this.video.currentTime
      });
    });

    this.video.addEventListener('suspend', () => {
      console.warn('Video loading suspended', {
        readyState: this.video.readyState,
        networkState: this.video.networkState
      });
    });

    // Monitor for black frames
    this.video.addEventListener('timeupdate', () => {
      if (this.video.videoWidth > 0 && this.isBlackFrame()) {
        console.warn('Black frame detected', {
          currentTime: this.video.currentTime,
          frame: this.currentFrame
        });
      }
    });

    // Add periodic health check
    setInterval(() => {
      if (this.video && this.video.readyState === 0) {
        console.warn('Video in invalid state, attempting recovery');
        this.restoreVideoResources();
      }
    }, 5000);
  }

  private releaseVideoResources(): void {
    this.video.src = '';  // Release video resources
    this.paused = true;
  }

  private restoreVideoResources(): void {
    this.video.src = this.fileObjectUrl;  // Restore video
    this.video.currentTime = this.currentFrame * this.framerate;
  }

  incrementMultiplier() {
    this.multiplier = this.multipliers[(this.multipliers.indexOf(this.multiplier) + 1) % this.multipliers.length];
  }

  decrementMultiplier() {
    this.multiplier = this.multipliers[(this.multipliers.indexOf(this.multiplier) - 1 + this.multipliers.length) % this.multipliers.length];
  }

  setMultiplier(multiplier: number) {
    this.multiplier = multiplier;
  }

  nextFrame(frameAmount: number) {
    if (this.currentFrame + frameAmount >= this.lastFrame) {
      return this.lastFrame;
    }
    this.currentFrame += frameAmount;
    const frame = this.arkitData.frames[this.currentFrame];
    this.video.currentTime += this.framerate * frameAmount;
    return frame.frameNumber;
  }

  prevFrame(frameAmount: number) {
    if (this.currentFrame - frameAmount <= 0) {
      return 0;
    }
    this.currentFrame = Math.max(this.currentFrame - frameAmount, 0);
    const frame = this.arkitData.frames[this.currentFrame];
    this.video.currentTime -= this.framerate * frameAmount;
    return frame.frameNumber;
  }

  seekToFrame(frameNumber: number) {
    // Add to queue instead of immediate seeking
    this.seekQueue.push(frameNumber);
    this.processSeekQueue();
  }

  private async processSeekQueue() {
    if (this.isProcessingSeek || this.seekQueue.length === 0) {
      return;
    }

    const now = Date.now();
    if (now - this.lastSeekTime < this.SEEK_THROTTLE_MS) {
      setTimeout(() => this.processSeekQueue(), this.SEEK_THROTTLE_MS);
      return;
    }

    this.isProcessingSeek = true;
    
    // Get the latest frame number from queue
    const frameNumber = this.seekQueue[this.seekQueue.length - 1];
    this.seekQueue = []; // Clear queue

    this.currentFrame = frameNumber;
    const frame = this.arkitData.frames[this.currentFrame];
    if (!frame) {
      this.isProcessingSeek = false;
      return;
    }

    const targetTime = this.framerate * frame.frameNumber;
    
    try {
      // Only update if time difference is significant
      if (Math.abs(this.video.currentTime - targetTime) > this.framerate / 2) {
        this.video.currentTime = targetTime;
      }
    } catch (e) {
      console.warn('Seek error:', e);
    }

    this.lastSeekTime = now;
    this.isProcessingSeek = false;

    // Process any frames that came in while we were seeking
    if (this.seekQueue.length > 0) {
      setTimeout(() => this.processSeekQueue(), this.SEEK_THROTTLE_MS);
    }
  }

  togglePlay(callback: (frame: number) => void) {
    const play = (timestamp: number) => {
      try {
        if (!this.lastTimestamp) {
          this.lastTimestamp = timestamp;
        }

        const elapsed = timestamp - this.lastTimestamp;

        if (elapsed > this.framerate * 1000) {
          const nextFrame = this.nextFrame(this.multiplier);
          callback(nextFrame);
          this.lastTimestamp = timestamp;

          if (nextFrame === this.lastFrame) {
            this.pause();
            return;
          }
        }

        if (!this.paused) {
          this.playEvent = requestAnimationFrame(play);
        }
      } catch (e) {
        console.error('Play error:', e);
        this.pause();
        this.restoreVideoResources();
      }
    };

    if (this.paused) {
      this.paused = false;
      this.playEvent = requestAnimationFrame(play);
    } else {
      this.pause();
    }
  }

  private handleVideoError = () => {
    const error = this.video.error;
    console.error('Video Error Details:', {
      code: error?.code,
      message: error?.message,
      timestamp: new Date().toISOString(),
      videoState: {
        readyState: this.video.readyState,
        networkState: this.video.networkState,
        paused: this.video.paused,
        currentTime: this.video.currentTime,
        src: this.video.src,
        error: this.video.error
      }
    });

    // Log specific error type
    switch(error?.code) {
      case MediaError.MEDIA_ERR_ABORTED:
        console.error('Video loading aborted');
        break;
      case MediaError.MEDIA_ERR_NETWORK:
        console.error('Network error while loading video');
        break;
      case MediaError.MEDIA_ERR_DECODE:
        console.error('Video decode error');
        break;
      case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
        console.error('Video format not supported');
        break;
      default:
        console.error('Unknown video error');
    }

    this.restoreVideoResources();
  }

  private isBlackFrame(): boolean {
    try {
      const canvas = new OffscreenCanvas(1, 1);
      const ctx = canvas.getContext('2d');
      if (!ctx) return false;

      ctx.drawImage(this.video, 0, 0, 1, 1);
      const pixel = ctx.getImageData(0, 0, 1, 1).data;
      return pixel[0] === 0 && pixel[1] === 0 && pixel[2] === 0;
    } catch (e) {
      console.error('Error checking for black frame:', e);
      return false;
    }
  }

  pause(): void {
    // Cancel any ongoing animation frame
    if (this.playEvent !== null) {
      cancelAnimationFrame(this.playEvent);
      this.playEvent = null;
    }

    // Ensure video is paused
    this.paused = true;    
  }
}


export default Video;
