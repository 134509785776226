import Chunk from "./chunk.js";

// Pure utility functions for matrix operations
export function matrixMultiply(...matrices: number[][][]) {
  return matrices.reduce((result, matrix) => {
    return result.map((row, i) => {
      return row.map((_, j) => {
        return matrix.reduce((sum, _, k) => {
          return sum + result[i][k] * matrix[k][j];
        }, 0);
      });
    });
  });
}

export function transpose(matrix: number[][]) {
  return matrix[0].map((_, i) => matrix.map((row) => row[i]));
}

export function rotateArkitData(arkitData: any, chunks: Chunk[]) {
  const newFrames = [...arkitData["frames"]];
  const nFrames = arkitData["frames"].length;

  for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
    const rot = -chunks[chunkIndex].rotation * (Math.PI / 180);
    const shift = [chunks[chunkIndex].shift.x / 100, chunks[chunkIndex].shift.y / 100];
    const firstFrameNumber = chunks[chunkIndex].firstFrameNumber;

    let rotation = [
      [Math.cos(rot), 0, Math.sin(rot), 0],
      [0, 1, 0, 0],
      [-Math.sin(rot), 0, Math.cos(rot), 0],
      [0, 0, 0, 1],
    ];

    let camLocation = newFrames[firstFrameNumber]["cameraTransform"][3];
    let camTranslation = [
      [1, 0, 0, camLocation[0]],
      [0, 1, 0, camLocation[1]],
      [0, 0, 1, camLocation[2]],
      [0, 0, 0, 1],
    ];
    let camTranslationInv = [
      [1, 0, 0, -camLocation[0]],
      [0, 1, 0, -camLocation[1]],
      [0, 0, 1, -camLocation[2]],
      [0, 0, 0, 1],
    ];

    let fullTransformation = matrixMultiply(
      camTranslation,
      rotation,
      camTranslationInv
    );
    fullTransformation[0][3] -= shift[0];
    fullTransformation[2][3] -= shift[1];

    for (let frameNumber = firstFrameNumber; frameNumber < nFrames; frameNumber++) {
      let cameraTransform = transpose(
        newFrames[frameNumber]["cameraTransform"]
      );
      let newCameraTransform = matrixMultiply(
        fullTransformation,
        cameraTransform
      );

      newFrames[frameNumber] = {
        ...arkitData["frames"][frameNumber],
        cameraTransform: transpose(newCameraTransform),
        floorNumber: chunks[chunkIndex].floor, // Replace frameNumber with chunk.floor
      };
    }
  }

  const newArkitData = { ...arkitData, frames: newFrames };
  return newArkitData;
}
