import ChunkEditor from "../chunkEditor";
import ChunkManager from "../chunkManager";
import { Line } from "../types";

export class SwapFloorsCommand {
    private chunkEditor: ChunkEditor;
    private chunkManager: ChunkManager;
    private oldFloor: number
    private newFloor: number;
    private oldChunkFloors: number[];
    private originalHelpLines: { [key: number]: Line[] };
 
    
    constructor(chunkEditor: ChunkEditor, oldFloor: number, newFloor: number) {
        this.chunkEditor = chunkEditor;
        this.chunkManager = chunkEditor.chunkManager;
        this.newFloor = newFloor;
        this.oldFloor = oldFloor;
        this.oldChunkFloors = [];
        this.originalHelpLines = {}
    }
    
    execute() {
        this.oldChunkFloors = this.chunkManager.getAllChunks().map((chunk) => chunk.floor);
        this.originalHelpLines = { ...this.chunkEditor.helpLines };

        const oldFloorChunks = this.chunkManager.getAllChunksInFloor(this.oldFloor);
        const newFloorChunks = this.chunkManager.getAllChunksInFloor(this.newFloor);

        const oldHelpLines = this.chunkEditor.helpLines[this.oldFloor];
        const newHelpLines = this.chunkEditor.helpLines[this.newFloor];

        this.chunkEditor.helpLines[this.oldFloor] = newHelpLines;
        this.chunkEditor.helpLines[this.newFloor] = oldHelpLines;

        oldFloorChunks.forEach((chunk) => {
            chunk.floor = this.newFloor;
        });

        newFloorChunks.forEach((chunk) => {
            chunk.floor = this.oldFloor;
        });
    }
    
    undo() {
        this.chunkManager.getAllChunks().forEach((chunk, index) => {
            chunk.floor = this.oldChunkFloors[index];
        });
        this.chunkEditor.helpLines = { ...this.originalHelpLines };
    }
}