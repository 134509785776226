import Cookie from 'js-cookie';
import _ from 'lodash';
import ImageChunkMover from './imageChunkMover.js';
import { FileUploader } from './fileUploader.js';
import API from './networking/api.js';
import { Point } from './types.js';
import BlobObjectURL from './networking/blobObjectURL';
import { KeyboardShortcut } from './keyboard.js';

enum EditorMode {
  DEFAULT = 0,
  EDIT = 1,
  LINE_DRAWING = 2
}

let cameraFollowing = false;

let currentMode: EditorMode = EditorMode.DEFAULT;

const isMoveKey = (key: string): boolean => {
  return [
    KeyboardShortcut.MOVE_RIGHT,
    KeyboardShortcut.MOVE_LEFT,
    KeyboardShortcut.MOVE_UP,
    KeyboardShortcut.MOVE_DOWN,
  ].includes(key as KeyboardShortcut);
};

let rightMouseDown = false;
let leftMouseDown = false;
let lineDrawing = false;
let isPointSet = false;

let userId: string | null = null;
let ticketId: string | null = null;

let opacityIndex = 0;
let opacityValues = [0.5, 0.05, 1];
let mousePoint: Point | null = null;

const getMousePosition = (canvas, event) => {
  const rect = canvas.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;
  return new Point(x, y);
};

const api = new API('https://api.cubi.casa');

const throttle = <T extends (...args: any[]) => any>(
  func: T,
  limit: number,
): ((...args: Parameters<T>) => void) => {
  let inThrottle: boolean;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

const resized = (mover: ImageChunkMover) => {
  mover.resized()
};

const togglePlay = (mover: ImageChunkMover) => {
  // Throttled version of updateVideo
  const throttledUpdateVideo = throttle((frame, mover) => {
    updateVideo(frame, mover);
  }, 10);

  mover.togglePlay((currentFrame) => {
    throttledUpdateVideo(currentFrame, mover);
    if (cameraFollowing) {
      centerToCamera(mover);
    }
  });
  const playPauseButton = document.getElementById(
    'play-pause-btn',
  ) as HTMLButtonElement;
  const playIcon = playPauseButton.querySelector('.play-icon');
  const pauseIcon = playPauseButton.querySelector('.pause-icon');

  if (playIcon?.classList.contains('hidden')) {
    playIcon.classList.remove('hidden');
    pauseIcon?.classList.add('hidden');
  } else {
    playIcon?.classList.add('hidden');
    pauseIcon?.classList.remove('hidden');
  }
};

const centerToCamera = (mover: ImageChunkMover) => {
  const frameNumber = mover.currentFrame;
  const chunk = mover.chunkManager.getChunkByFrame(frameNumber);
  const imageCameraPosition = chunk.frames[frameNumber - chunk.firstFrameNumber].imageCameraPosition;
  const point = new Point(imageCameraPosition[0], imageCameraPosition[1]);
  mover.centerToPoint(point)
  mover.redrawChunks();
}

const attachEventListeners = (mover: ImageChunkMover) => {
  document.addEventListener('keydown', (event) => {
    const shiftPressed = event.shiftKey;
    const ctrlPressed = event.ctrlKey;
    const metaPressed = event.metaKey;
    // Handle arrow key presses
    if (isMoveKey(event.key)) {
      event.preventDefault();
      // If there is a selected chunk we move it.
      if (mover.isMovingChunk()) {
        const moveAmount = shiftPressed ? 10 : 1;
        const dx =
          event.key === KeyboardShortcut.MOVE_RIGHT
            ? moveAmount
            : event.key === KeyboardShortcut.MOVE_LEFT
              ? -moveAmount
              : 0;
        const dy =
          event.key === KeyboardShortcut.MOVE_DOWN
            ? moveAmount
            : event.key === KeyboardShortcut.MOVE_UP
              ? -moveAmount
              : 0;

        mover.moveSelectedChunk(dx, dy);
      } else {
        // Otherwise pan the canvas
        if (event.key === KeyboardShortcut.MOVE_RIGHT) mover.panXBy(-10);
        if (event.key === KeyboardShortcut.MOVE_LEFT) mover.panXBy(10);
        if (event.key === KeyboardShortcut.MOVE_UP) mover.panYBy(10);
        if (event.key === KeyboardShortcut.MOVE_DOWN) mover.panYBy(-10);
      }
    }

    // Handle Rotation events
    if (
      event.code === KeyboardShortcut.ROTATE_LEFT ||
      event.code === KeyboardShortcut.ROTATE_RIGHT
    ) {
      if (mover.isMovingChunk()) {
        event.preventDefault();
        const rotationAmount = shiftPressed ? 22.5 : 0.5;
        mover.rotateSelectedChunk(
          event.code === KeyboardShortcut.ROTATE_LEFT
            ? -rotationAmount
            : rotationAmount,
        );
      }
    }

    // Handle zoom events
    if (
      event.key === KeyboardShortcut.ZOOM_IN ||
      event.key === KeyboardShortcut.ZOOM_OUT
    ) {
      event.preventDefault();
      mover.zoomToCenter(event.key === KeyboardShortcut.ZOOM_IN ? 0.1 : -0.1);
      updateInfobox(mover.currentFloor, mover.selectedChunk, mover);
    }

    // Handle chunk selection events
    if (
      event.key === KeyboardShortcut.SELECT_PREV_CHUNK ||
      event.key === KeyboardShortcut.SELECT_NEXT_CHUNK ||
      event.key === KeyboardShortcut.SELECT_CHUNK_BY_FRAME
    ) {
      event.preventDefault();
      if (currentMode === EditorMode.EDIT) {
        changeModeTo(EditorMode.DEFAULT);
      }

      if (event.key === KeyboardShortcut.SELECT_PREV_CHUNK && !shiftPressed && !ctrlPressed && !metaPressed) {
        const [floorIndex, chunk] = mover.selectPrevChunk();
        updateInfobox(floorIndex, chunk, mover);
        updateVideo(chunk.firstFrameNumber, mover);
      } else if (event.key === KeyboardShortcut.SELECT_NEXT_CHUNK) {
        const [floorIndex, chunk] = mover.selectNextChunk();
        updateInfobox(floorIndex, chunk, mover);
        updateVideo(chunk.firstFrameNumber, mover);
      } else if (event.key === KeyboardShortcut.SELECT_CHUNK_BY_FRAME) {
        const [floorIndex, chunk] = mover.selectChunkByCurrentFrame();
        updateInfobox(floorIndex, chunk, mover);
        updateVideo(mover.currentFrame, mover);
      }
    }

    // Split chunk
    if (event.key === KeyboardShortcut.SPLIT_CHUNK) {
      if (!mover.selectedChunk) {
        return;
      }
      showLoadingModal();
      event.preventDefault();

      setTimeout(() => {
        mover.splitChunk();
        hideLoadingModal();
        updateDebugPanel(mover);
      }, 100);
    }

    // start linedrawing
    if (event.key === KeyboardShortcut.TOGGLE_LINE_DRAWING) {
        if (!lineDrawing) {
          changeModeTo(EditorMode.LINE_DRAWING);
          lineDrawing = true
          const canvas = document.getElementById('canvas'); 
          canvas.style.cursor = 'crosshair';
        } else {
          changeModeTo(EditorMode.DEFAULT);
          lineDrawing = false
          isPointSet = false
          const canvas = document.getElementById('canvas'); 
          canvas.style.cursor = 'default';
          if (mousePoint) {
            mover.stopLineDrawing(mousePoint, !event.shiftKey)
          }
        }
      }
    

    // Move copy selected line to all floors
    if (event.key === KeyboardShortcut.COPY_LINE_TO_FLOORS) {
      mover.copySelectedLineToAllFloors();
    }

    // Handle delete events
    if (event.key === KeyboardShortcut.DELETE_LINE) {
      event.preventDefault();
      mover.removeSelectedHelpLine();
    }

    // toggle videoelement visibiility
    if (event.key === KeyboardShortcut.TOGGLE_VIDEO) {
      const videoContainer = document.getElementById('video-container');
      if (videoContainer.style.display === 'block') {
        videoContainer.style.display = 'none';
      } else {
        videoContainer.style.display = 'block';
      }
    }

    // Handle video control events with throttling
    let key = event.key.toLowerCase();
    if (key === KeyboardShortcut.NEXT_FRAME) {
      const currentFrame = mover.nextFrame(shiftPressed ? 10 : 1);
      updateVideo(currentFrame, mover);
    } else if (key === KeyboardShortcut.PREV_FRAME) {
      const currentFrame = mover.prevFrame(shiftPressed ? 10 : 1);
      updateVideo(currentFrame, mover);
    } else if (
      key === KeyboardShortcut.TOGGLE_PLAY &&
      !shiftPressed &&
      !ctrlPressed
    ) {
      togglePlay(mover);
    } else if (key === KeyboardShortcut.TOGGLE_PLAY && shiftPressed) {
      mover.incrementMultiplier();
      if (cameraFollowing) {
        centerToCamera(mover);
      }
      const speedMultiplier = document.getElementById("speed-multiplier") as HTMLSelectElement;
      speedMultiplier.value = mover.videoController?.multiplier.toString() ?? "1";
    } else if (key === KeyboardShortcut.TOGGLE_PLAY && ctrlPressed) {
      mover.decrementMultiplier();
      const speedMultiplier = document.getElementById(
        'speed-multiplier',
      ) as HTMLSelectElement;
      speedMultiplier.value =
        mover.videoController?.multiplier.toString() ?? '1';
    }

    if (key === KeyboardShortcut.TOGGLE_FOLLOW_CAMERA) {
      cameraFollowing = !cameraFollowing;
      const followCameraButton = document.getElementById("follow-camera-btn") as HTMLButtonElement;
      followCameraButton.classList.toggle('active');
    }


    // Handle deselection event
    if (event.key === KeyboardShortcut.CANCEL) {
      changeModeTo(EditorMode.DEFAULT);
      if (lineDrawing) {
        lineDrawing = false;
        isPointSet = false;
        const canvas = document.getElementById('canvas');
        canvas.style.cursor = 'default';
        mover.cancelLineDrawing();
      }
      event.preventDefault();
      mover.deselectChunk();
    }

    // Handle floor selection event
    if (shiftPressed && event.code.startsWith('Digit')) {
      const floorIndex = parseInt(event.code.slice(-1)) - 1;
      const currentFrame = mover.selectFloor(floorIndex);
      updateVideo(currentFrame, mover);
      updateInfobox(floorIndex, mover.selectedChunk, mover);
    }

    // Handle debug panel toggle
    if (ctrlPressed) {
      if (event.key === KeyboardShortcut.TOGGLE_DEBUG) {
        toggleDebugPanel();
      }
    }
    if (event.key === KeyboardShortcut.TOGGLE_OPACITY) {
      opacityIndex = (opacityIndex + 1) % opacityValues.length;
      mover.selectedChunkOpacity = opacityValues[opacityIndex];
      mover.redrawChunks(); // Draws now all the chunks, should only update the current chunk maybe.
    }

    if (event.key === KeyboardShortcut.CENTER_TO_CAMERA) {
      centerToCamera(mover);
    }

    // handle move chunk between floors events
    if (event.key.toLowerCase() === KeyboardShortcut.MOVE_CHUNK_UP) {
      event.preventDefault();
      const [floorIndex, chunk] = mover.moveSelectedChunkToUpperFloor(
        shiftPressed,
        ctrlPressed || metaPressed,
      );
      updateInfobox(floorIndex, chunk, mover);
    }

    if (event.key.toLowerCase() === KeyboardShortcut.MOVE_CHUNK_DOWN) {
      event.preventDefault();
      const [floorIndex, chunk] = mover.moveSelectedChunkToLowerFloor(
        shiftPressed,
        ctrlPressed || metaPressed,
      );
      updateInfobox(floorIndex, chunk, mover);
    }

    // Handle swap floors events
    if (event.key.toLowerCase() === KeyboardShortcut.SWAP_FLOOR_UP) {
      event.preventDefault();
      const [floorIndex, chunk] = mover.swapSelectedChunkFloorWithUpperFloor();
      updateInfobox(floorIndex, chunk, mover);
    }

    if (event.key.toLowerCase() === KeyboardShortcut.SWAP_FLOOR_DOWN) {
      event.preventDefault();
      const [floorIndex, chunk] = mover.swapSelectedChunkFloorWithBelowFloor();
      updateInfobox(floorIndex, chunk, mover);
    }

    // Handle undo and redo events
    if (
      event.key === KeyboardShortcut.UNDO &&
      (ctrlPressed || metaPressed) &&
      !shiftPressed
    ) {
      event.preventDefault();
      mover.undo();
      updateInfobox(mover.currentFloor, mover.selectedChunk, mover);
    }
    if (
      (event.key === KeyboardShortcut.UNDO &&
        shiftPressed &&
        (metaPressed || ctrlPressed)) ||
      (event.key === KeyboardShortcut.REDO && (ctrlPressed || metaPressed))
    ) {
      event.preventDefault();
      mover.redo();
      updateInfobox(mover.currentFloor, mover.selectedChunk, mover);
    }
    updateDebugPanel(mover);
  });

  // Throttled wheel event handler
  const throttledWheelHandler = throttle((event) => {
    event.preventDefault();
    const zoomStep = 0.05; // fixed zoom step
    const direction = event.deltaY > 0 ? -1 : 1; // zoom in for scroll up, out for scroll down
    const factor = direction * zoomStep;
    const screenPoint = getMousePosition(mover.canvas, event);
    mover.zoomToPoint(factor, screenPoint);
    updateInfobox(mover.currentFloor, mover.selectedChunk, mover);
  }, 75); // Adjust this value as needed

  // Add event listener
  document.addEventListener(
    'wheel',
    (event) => {
      event.preventDefault();
      throttledWheelHandler(event);
    },
    { passive: false },
  );

  document.addEventListener('mousemove', (event) => {
    mousePoint = getMousePosition(mover.canvas, event);
    if (rightMouseDown) {
      mover.panWith(mousePoint);
    }

    if (lineDrawing) {
      mover.setCurrentEndPoint(mousePoint, !event.shiftKey);
    }

    if (mover.isDragging && leftMouseDown) {
      mover.dragWith(mousePoint);
    }
  });

  document.addEventListener('mouseup', (event) => {
    if (event.button === 2) {
      // Right mouse button
      rightMouseDown = false;
      mover.stopPanning();
    }

    if (event.button === 0) {
      leftMouseDown = false;
      mover.stopDraggingChunk();
    }
  });

  document.addEventListener('contextmenu', (event) => {
    event.preventDefault();
  });

  document.addEventListener('mousedown', (event) => {
    // Allow default behavior for select and its options
    if (
      event.target instanceof HTMLSelectElement ||
      event.target instanceof HTMLOptionElement
    ) {
      return;
    }

    // Prevent default for everything else
    event.preventDefault();

    if (event.button === 2) {
      rightMouseDown = true;
      const point = getMousePosition(mover.canvas, event);
      mover.startPanning(point);
    }

    if (event.button === 0 && event.altKey && event.shiftKey) {
      let screenPoint = getMousePosition(mover.canvas, event);
      const currentFrame = mover.selectPathPoint(screenPoint);
      updateVideo(currentFrame, mover);
    }

    if (event.button === 0 && lineDrawing && mousePoint) {
      if (!isPointSet) {
        isPointSet = true;
        mover.startLineDrawing(mousePoint);
      } else {
        mover.continueLineDrawing(mousePoint, !event.shiftKey);
      }
    }

    if (
      event.button === 0 &&
      mover.selectedChunk &&
      !lineDrawing &&
      !event.shiftKey &&
      !event.altKey
    ) {
      const screenPoint = getMousePosition(mover.canvas, event);
      leftMouseDown = true;
      mover.startDraggingChunk(screenPoint);
    }

    if (event.button === 0 && !mover.selectedChunk && !lineDrawing) {
      const screenPoint = getMousePosition(mover.canvas, event);
      mover.selectHelpLine(screenPoint);
    }
  });

  const frameIndicator = document.getElementById('elevation-canvas');

  let mouseDown = false;

  const updateFrame = (event) => {
    if (event.type === 'mousemove' && !mouseDown) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    const canvas = document.getElementById('elevation-canvas');
    const canvasWidth = canvas.offsetWidth;

    const x = event.offsetX;
    const frameNumber = Math.round(
      (x / canvasWidth) * mover.arkitData.frames.length,
    );
    mover.currentFrame = frameNumber;
    mover.videoController?.seekToFrame(frameNumber);
    mover.frameChanged();
    mover.redrawChunks();
    updateVideo(frameNumber, mover);
  };

  frameIndicator?.addEventListener('mousedown', (event) => {
    mouseDown = true;
    updateFrame(event);
  });
  frameIndicator?.addEventListener('mousemove', updateFrame);
  frameIndicator?.addEventListener('mouseup', () => (mouseDown = false));
};

function showLoadingModal() {
  document.getElementById('loading-modal').style.display = 'flex';
}

function hideLoadingModal() {
  document.getElementById('loading-modal').style.display = 'none';
}

function hideCompleteModal() {
  document.getElementById('completed-modal').style.display = 'none';
}

function showCompleteModal() {
  document.getElementById('completed-modal').style.display = 'flex';
}

function changeModeTo(mode: EditorMode) {
  const modes = ["", "Edit mode", "Line drawing mode"];
  const colors = ["#1E4357", "red", "green"];
  currentMode = mode;
  document.getElementById("mode-indicator").textContent = modes[mode];
  const topBar = document.getElementById("ui");
  topBar.style.backgroundColor = colors[mode];
}

const updateVideo = _.throttle(
  (frameNumber: number, mover: ImageChunkMover) => {
    const frameIndicator = document.getElementById('frame-indicator');
    const chunkIndicator = document.getElementById('chunk-indicator');
    const deviceModelIndicator = document.getElementById(
      'device-model-indicator',
    );
    const elevationCanvas = document.getElementById(
      'elevation-canvas',
    ) as HTMLCanvasElement;

    const ctx = elevationCanvas.getContext('2d');

    if (
      !frameIndicator ||
      !chunkIndicator ||
      !deviceModelIndicator ||
      !elevationCanvas ||
      !ctx
    ) {
      return;
    }

    // Update video position
    requestAnimationFrame(() => {
      mover.videoController?.seekToFrame(frameNumber);
    });

    // Update UI in next frame to avoid blocking
    requestAnimationFrame(() => {
      if (
        !frameIndicator ||
        !chunkIndicator ||
        !deviceModelIndicator ||
        !elevationCanvas
      ) {
        return;
      }

      const frame = mover.arkitData.frames[frameNumber];
      frameIndicator.textContent = `Frame: ${frameNumber} `;
      chunkIndicator.textContent = `Chunk: ${frame ? frame.chunk : 'undefined'}`;

      deviceModelIndicator.textContent = `Device Model: ${mover.arkitData.phoneMarketName}, ${mover.arkitData.phoneModel}`;

      // Draw elevation curve
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, elevationCanvas.width, elevationCanvas.height);

      // get elevation data and gather min and max values
      const elevationData: number[] = [];
      let min = Infinity;
      let max = -Infinity;
      const minElevationPerFloor: Record<number, number> = {};
      let firstFrameFloor = mover.arkitData.frames[0].floorNumber;
      const floorChangeFrames = [[0, firstFrameFloor]];
      mover.arkitData.frames.forEach((frame) => {
        const elevation = frame.cameraTransform[3][1] * 100;
        elevationData.push(elevation);
        min = Math.min(min, elevation);
        max = Math.max(max, elevation);

        const floor = frame.floorNumber;

        if (
          !minElevationPerFloor[floor] ||
          elevation < minElevationPerFloor[floor]
        ) {
          minElevationPerFloor[floor] = elevation;
        }
        if (floor !== firstFrameFloor) {
          floorChangeFrames.push([frame.frameNumber, floor]);
          firstFrameFloor = floor;
        }
      });

      // Draw elevation curve
      ctx.beginPath();
      ctx.strokeStyle = '#000';
      ctx.lineWidth = 1;
      const width = elevationCanvas.width;
      const height = elevationCanvas.height;
      const step = width / elevationData.length;
      ctx.moveTo(0, height - 10);
      elevationData.forEach((elevation, i) => {
        const x = i * step;
        const y =
          height - 20 - ((elevation - min) / (max - min)) * (height - 25);
        ctx.lineTo(x, y);
      });
      ctx.stroke();

      // draw floor levels
      floorChangeFrames.forEach(([frame, floor]) => {
        ctx.beginPath();
        ctx.strokeStyle = '#00f';
        ctx.lineWidth = 1;
        ctx.moveTo(step * frame, 0);
        ctx.lineTo(step * frame, elevationCanvas.height);
        ctx.stroke();

        ctx.save();
        ctx.font = '18px Monaco ';
        ctx.fillStyle = '#000';
        ctx.fillText(
          `${floor + 1}`,
          step * frame + 5,
          elevationCanvas.height - 5,
        );
        ctx.restore();
      });

      // Draw current frame indicator
      ctx.beginPath();
      ctx.strokeStyle = '#f00';
      ctx.lineWidth = 1;
      const x = (frameNumber / elevationData.length) * width;
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
      ctx.stroke();
    });
  },
  50,
); // Throttle to 50ms

const getFloorIndicator = (
  floorIndex: number,
  mover: ImageChunkMover,
): string => {
  const floors = [];
  for (let i = 0; i < mover.chunkManager.getFloorAmount(); i += 1) {
    if (i === floorIndex) {
      floors.push(`<span id="selectedFloor">${i + 1}</span>`);
    } else {
      floors.push(`<span id="floor">${i + 1}</span>`);
    }
  }
  return floors.join(' | ');
};

const updateInfobox = (floorIndex, chunk, mover) => {
  const ui = document.getElementById('ui');
  const infobox = document.getElementById('infobox');
  if (!ui || !infobox) {
    return;
  }

  ui.style.visibility = 'visible';
  infobox.innerHTML = `Floor: ${getFloorIndicator(
    floorIndex,
    mover,
  )} Selected chunk: ${chunk ? mover.chunkManager.getIndexForChunk(chunk) : 'None'} Zoom: ${Math.round(
    mover.zoom * 100,
  )}%`;
};

const attachButtonListeners = (mover) => {
  const followCameraButton = document.getElementById("follow-camera-btn");
  followCameraButton.addEventListener("click", () => {
    cameraFollowing = !cameraFollowing;
    followCameraButton.classList.toggle('active');
  });

  const nextChunkButton = document.getElementById("nextChunk");
  nextChunkButton.addEventListener("click", () => {
    const [floorIndex, chunk] = mover.selectNextChunk();
    updateInfobox(floorIndex, chunk, mover);
    updateDebugPanel(mover);
  });

  const previousChunkButton = document.getElementById('prevChunk');
  previousChunkButton.addEventListener('click', () => {
    const [floorIndex, chunk] = mover.selectPrevChunk();
    updateInfobox(floorIndex, chunk, mover);
    updateDebugPanel(mover);
  });

  const exportButton = document.getElementById('export');
  exportButton.addEventListener('click', () => {
    showLoadingModal();
    setTimeout(() => {
      const arkitData = mover.exportData();
      const data = JSON.stringify(arkitData);
      const blob = new Blob([data], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'arkitData.json';
      a.click();
      hideLoadingModal();
    }, 100);
  });

  const playButton = document.getElementById("play-pause-btn") as HTMLButtonElement;
  playButton.addEventListener("click", () => {
    togglePlay(mover);
  });

  const speedMultiplier = document.getElementById("speed-multiplier") as HTMLSelectElement;
  speedMultiplier.addEventListener("change", () => {
    mover.videoController?.setMultiplier(parseInt(speedMultiplier.value));
  });

  const rerunAutomationButton = document.getElementById('rerun-automation');
  if (ticketId && rerunAutomationButton) {
    rerunAutomationButton?.addEventListener('click', () => {
      const saveDialog = document.getElementById('save-dialog');
      saveDialog.style.visibility = 'visible';

      const cancelButton = document.getElementById('cancel-button');
      const rerunButton = document.getElementById('rerun-button');

      cancelButton.addEventListener('click', () => {
        saveDialog.style.visibility = 'hidden';
      });

      rerunButton.addEventListener('click', () => {
        showLoadingModal();
        saveDialog.style.visibility = 'hidden';
        const configOptions = {
          fix_mode: 0,
          realign_chunks: 0,
          chunking_method: 'spatial',
          fix_relocalizations: 0,
          use_depth: 1,
        };

        const intKeys = [
          'fix_mode',
          'realign_chunks',
          'fix_relocalizations',
          'use_depth',
        ];

        Object.keys(configOptions).forEach((optionName) => {
          const selector = `input[name="${optionName}"]:checked`;
          const value = document.querySelector(selector).value;
          configOptions[optionName] = intKeys.includes(optionName)
            ? parseInt(value, 10)
            : value;
        });

        const arkitData = mover.exportData();
        arkitData.data_configs = configOptions;
        api.createSourceFix(ticketId!, arkitData).then((response) => {
          hideLoadingModal();
          showCompleteModal();
        });
      });
    });
  } else {
    rerunAutomationButton.style.display = 'none';
  }
};

const toggleDebugPanel = () => {
  const debugPanel = document.getElementById('debug-panel');
  if (debugPanel.style.display === 'none' || debugPanel.style.display === '') {
    debugPanel.style.display = 'block';
  } else {
    debugPanel.style.display = 'none';
  }
};

const updateDebugPanel = (mover) => {
  const chunkInfoDiv = document.getElementById('chunk-info');
  const chunks = mover.chunkManager.getAllChunksInFloor(mover.currentFloor);

  const debugTableRow = (label, value) => {
    return `<tr><td>${label}</td><td>${value}</td></tr>`;
  };

  let html = '<table><thead>';
  chunks.forEach((chunk) => {
    html += `<tr><th>Chunk ${mover.chunkManager.getIndexForChunk(chunk)}, f:${chunk.floor} c:${chunk.frames[0].chunk}</th></tr></thead><tbody>`;
    html += debugTableRow('Coordinates', chunk.position.toString());
    html += debugTableRow('PathStartPoint', chunk.pathStartPosition.toString());
    html += debugTableRow(
      'GlobalRotation',
      `${chunk.globalRotation.toFixed(1)}°`,
    );
    html += debugTableRow('Rotation', `${chunk.rotation.toFixed(1)}°`);
    html += debugTableRow('Shift', chunk.shift.toString());
  });
  html += '</tbody></table>';
  chunkInfoDiv.innerHTML = html;
};

const startEditing = (mover: ImageChunkMover) => {
  window.addEventListener('resize', () => {
    resized(mover);
  });

  updateDebugPanel(mover);
  updateInfobox(mover.currentFloor, mover.selectedChunk, mover);
  updateVideo(0, mover);
  attachButtonListeners(mover);
  attachEventListeners(mover);
  document.getElementById('video-container').style.display = 'block';
};

const start = async () => {
  showLoadingModal();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  ticketId = urlParams.get('ticket_id');

  const video = document.getElementById('video') as HTMLVideoElement;

  if (ticketId !== null) {
    const [arkitFile, videoObjectUrl, fixfile] = await getTicketData(ticketId);
    const mover = new ImageChunkMover(
      arkitFile,
      videoObjectUrl,
      fixfile,
      video,
    );
    await mover.init();
    startEditing(mover);
    hideLoadingModal();
  } else {
    hideLoadingModal();
    const dropArea = document.getElementById('drop-area');
    dropArea.style.visibility = 'visible';
    const uploader = new FileUploader(document, async (uploadContent) => {
      showLoadingModal();
      // Use zip here and pass it to ImageChunkMover if necessary
      setTimeout(async () => {
        const config = JSON.parse(
          await uploadContent.files['config.json'].async('string'),
        );
        const arkitFile = JSON.parse(
          await uploadContent.files[config['arKit'][0]].async('string'),
        );
        const videoFile =
          await uploadContent.files[config['video_lowres'][0]].async('blob');
        const chunkDataFiles =
          await uploadContent.files[config['chunk_data'][0]].async('blob');

        const mover = new ImageChunkMover(
          arkitFile,
          await BlobObjectURL(videoFile, 'video/mp4'),
          chunkDataFiles,
          video,
        );
        await mover.init();
        startEditing(mover);
        hideLoadingModal();
      }, 100);
    });
  }
};

const getTicketData = (ticketId: string): Promise<[any, any, any]> => {
  return api
    .getTicket(ticketId)
    .then((response) => {
      const iterations = response.iterations;

      if (iterations.length > 1) {
        iterations.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
      }

      // Order events in each iteration from the most recent to oldest.
      // Most recent is the 1. element in array
      iterations.forEach((iteration) => {
        iteration.Events.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
      });

      const latestProcessEvent = iterations[0].Events[0];

      // if (
      //   ["waiting AT", "automating"].includes(latestProcessEvent.process_state) ||
      //  !["drawing", "needITHelp", "SFing"].includes(latestProcessEvent.process_state)) {
      //  alert("Cannot start source fix!\n\nTicket state need to be one of the following \n"
      //   + "drawing, needITHelp, SFing\n"+
      //   `Now the state is: ${latestProcessEvent.process_state}`);
      //   return Promise.reject("Wrong ticket state");
      // }

      // const processEventPromise =
      // latestProcessEvent.process_state === "drawing"
      //   ? api.createProcessEvent(
      //       "SFing",
      //       iterations[0].iteration_id,
      //       userId,
      //     )
      //   : Promise.resolve();
      const processEventPromise = Promise.resolve();

      return processEventPromise.then(
        () => {
          const sourceId = response.source_id.UUID;
          return api.getTicketSource(sourceId);
        },
        (error) => {
          window.alert(`Cannot upload file!.\n${error}`);
          return Promise.reject();
        },
      );
    })
    .then((response) => {
      return api.getTicketSourceKey(response.id);
    })
    .then((response) => {
      const video = response.find(
        (source) => source.source_type === 'videolowres',
      );
      const arkit = response.find(
        (source) => source.source_type === 'vbimdata',
      );
      const fixdata = response.find(
        (source) => source.source_type === 'chunkdata',
      );

      // Use Promise.all to fetch both video and arkit data
      return Promise.all([
        api.getTicketSourceKeyFile(
          video.source_id,
          video.id,
          video.source_type,
        ),
        api.getTicketSourceKeyFile(
          arkit.source_id,
          arkit.id,
          arkit.source_type,
        ),
        api.getTicketSourceKeyFile(
          fixdata.source_id,
          fixdata.id,
          fixdata.source_type,
        ),
      ]);
    })
    .then(async ([videoData, arkitData, fixdata]) => {
      // Return both video and arkit data here
      return [arkitData, videoData, fixdata];
    })
    .catch((error) => {
      alert(`Error during ticket fetching: ${error}`);
      throw error; // Re-throw the error to ensure the promise is rejected
    });
};

window.onload = () => {
  hideCompleteModal();
  const loginDialog = document.getElementById('login-dialog');
  loginDialog.style.visibility = 'visible';

  const usernameInput = document.getElementById('username');
  const passwordInput = document.getElementById('password');
  const eulaCheckbox = document.getElementById('eula');
  document.querySelector('form').addEventListener('submit', function (event) {
    event.preventDefault();

    const username = usernameInput.value;
    const password = passwordInput.value;

    api.login(username, password).then((response) => {
      if (response.error) {
        alert('Login failed');
        usernameInput.value = '';
        passwordInput.value = '';
        eulaCheckbox.checked = false;
        reject('Login failed');
        return;
      }
      loginDialog.style.visibility = 'hidden';

      if (response.is_2fa_enable) {
        const sessionId = response.session_id;
        // TODO handke token
        if (!response.token) {
        }
        const twofaDialog = document.getElementById('twofa-dialog');
        twofaDialog.style.visibility = 'visible';
        const inputs = document.querySelectorAll('.twofa-input');
        inputs[0].focus();
        const verifyButton = document.getElementById(
          '2fa-button',
        ) as HTMLButtonElement;

        verifyButton.addEventListener('click', (e) => {
          e.preventDefault();
          const code = Array.from(inputs)
            .map((input) => input.value)
            .join('');
          api
            .verify2FA(sessionId, code, username)
            .then(async (response) => {
              if (response.error) {
                alert('2FA verification failed');
                reject('2FA verification failed');
                twofaDialog.style.visibility = 'visible';
                return;
              }
              twofaDialog.style.visibility = 'hidden';

              localStorage.setItem('token', response.token);
              localStorage.setItem('userId', response.user.id);
              userId = response.user.id;
              localStorage.setItem('tokenExpiration', Date.now() + 86400000);
              const token = response.token;
              await api.getApiKey(userId, token);
              start();
            })
            .catch(() => {
              alert('2FA verification failed');
              twofaDialog.style.visibility = 'hidden';
              loginDialog.style.visibility = 'visible';
            });
        });

        inputs.forEach((input, index) => {
          input.addEventListener('input', (e) => {
            const target = e.target as HTMLInputElement;
            if (!/^\d$/.test(target.value)) {
              target.value = ''; // Clear the input if it's not a numeric character
            }

            if (input.value.length === 1 && index < inputs.length - 1) {
              inputs[index + 1].focus();
            }

            if (index === inputs.length - 1) {
              verifyButton.disabled = false;
            }
          });

          input.addEventListener('keydown', (e) => {
            if (e.key === 'Backspace') {
              setTimeout(() => {
                verifyButton.disabled = true;
              }, 0);
              if (input.value.length === 0 && index > 0) {
                inputs[index - 1].focus();
              }
            }
          });
        });
      } else {
        localStorage.setItem('token', response.token);
        localStorage.setItem('userId', response.user.id);
        userId = response.user.id;
        localStorage.setItem('tokenExpiration', Date.now() + 86400000);
        const token = response.token;
        api.getApiKey(userId, token).then(() => {
          start();
        });
      }
    });
  });

  // Handle login by locally stored or temporary cookie token
  let token;
  let tokenExpiration;

  if (Cookie.get('cc_temp_login_token')) {
    token = Cookie.get('cc_temp_login_token');
    userId = Cookie.get('cc_temp_user_id');
    tokenExpiration = new Date().getTime() + 10 * 60 * 1000; // 10 minutes

    // Remove temporary cookie
    Cookie.remove('cc_temp_login_token');
    Cookie.remove('cc_temp_user_id');

    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId);
    localStorage.setItem('tokenExpiration', tokenExpiration);
  } else {
    token = localStorage.getItem('token');
    tokenExpiration = localStorage.getItem('tokenExpiration');
    userId = localStorage.getItem('userId');
  }

  if (token && tokenExpiration > Date.now()) {
    api
      .getApiKey(userId, token)
      .then((response) => {
        if (response.error) {
          alert('Automatic login failed. Please use your credentials.');
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          localStorage.removeItem('tokenExpiration');
          return;
        }
        loginDialog.style.display = 'none';
        start();
      })
      .catch((error) => {
        console.warn('Error during automatic login:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('tokenExpiration');
      });
  }
};
