import Network from "./network";
import  BlobObjectURL from "./blobObjectURL";

class API {
  baseURL: string;
  apiKey: string | null;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.apiKey = null;
  }

  async login(username: string, password: string, eulaAccepted: boolean) {
    const response = Network.sendJSON(`${this.baseURL}/login`, {
      email: username,
      password,
      ...{ is_eula_accepted: eulaAccepted },
    },
    "json");
    return response
  }

  async verify2FA(sessionId: string, otpToken: string, email: string) {
    const encodedEmail = encodeURIComponent(email);
    const path = `${this.baseURL}/2fa/validate?otp_token=${otpToken}&session_id=${sessionId}&email=${encodedEmail}`;
    return Network.fetchJSON(path); 
  };

  async getApiKey(userId: string, token: string) {
    const path = `${this.baseURL}/user/${userId}/key?token=${token}`;
    const response = await Network.fetchJSON(path);
    const apiKey = response[0].id;
    this.apiKey = apiKey;
    return response;
  }

  async getTicket(ticketId: string) {
    const path = `${this.baseURL}/conversion/ticket/${ticketId}?api_key=${this.apiKey}`;
    const response = Network.fetchJSON(path);
    return response;
  }

  async getTicketSource(sourceId: string) {
    const path = `${this.baseURL}/conversion/source/${sourceId}?api_key=${this.apiKey}`;
    return Network.fetchJSON(path);
  }

  async getTicketSourceKey(sourceId: string) {
    const path = `${this.baseURL}/conversion/source/${sourceId}/sourcekey?api_key=${this.apiKey}`;
    return Network.fetchJSON(path);
  }

  async getTicketSourceKeyFile(sourceId: string, sourceKeyId: string, sourceKeyType: string) {
    const path = `${this.baseURL}/conversion/source/${sourceId}/sourcekey/${sourceKeyId}?api_key=${this.apiKey}`;
 
    if (sourceKeyType === "videolowres" ) {
      return Network.fetchOctetStream(path).then((response) => {
        return BlobObjectURL(response, "video/mp4");
      });
    }
    if (sourceKeyType ===  "vbimdata") {
      return Network.fetchJSON(path);
    }
  
    if (sourceKeyType === "chunkdata") {
      return Network.fetchOctetStream(path).then((response) => {
        return new Blob([response], { type: "application/zip" });
      });
    }
  
    return Promise.reject(new Error("Invalid source key type"));
  };

  createSourceFix(ticketId:string, vbimData: object) {
    const path = `${this.baseURL}/conversion/ticket/${ticketId}/source/fixsource?api_key=${this.apiKey}`;

    const blob = new Blob([JSON.stringify(vbimData)], {
      type: "text/json;charset=utf-8",
    });

    const formData = new FormData();
    formData.append("data", blob);

    return Network.sendForm(path, formData, "json");
  };

  createProcessEvent(state:string, iterationId: string, userId: String | null) {
    const path = `${this.baseURL}/conversion/iteration/event?api_key=${this.apiKey}`;

    const payloadJSON = {
      Process_state: state,
      iteration_id: iterationId,
      producer_id: userId,
    };
  
    return Network.sendJSON(path, payloadJSON);
  };
}
export default API;
