import { chunkBoundaries } from "./interfaces";

export class Rectangle {
  startPoint: Point;
  size: Size

  static fromArray(boundaries: ChunkBoundaries): Rectangle {
      return new Rectangle(new Point(boundaries.x, boundaries.y), boundaries.width, boundaries.height);
  }

  constructor(startPoint: Point, width: number, height: number) {
      this.startPoint = startPoint.copy();
      this.size = new Size(width, height);
  }
}

export class Size {
  constructor(public width: number, public height: number) {
      this.width = width;
      this.height = height;
  }
}

export class Point {
  static fromArray(arr: number[]): Point {
    if (arr.length !== 2) {
      throw new Error('Array must contain exactly two elements');
    }
    return new Point(arr[0], arr[1]);
  }
  
  static zero(): Point {
    return new Point(0, 0);
  }

  constructor(public x: number, public y: number) {
    this.x = x;
    this.y = y;
  }

  copy(): Point {
    return new Point(this.x, this.y);
  }

  add(other: Point): Point {
    return new Point(this.x + other.x, this.y + other.y);
  }

  subtract(other: Point): Point {
    return new Point(this.x - other.x, this.y - other.y);
  }

  divide(value: number): Point {
    return new Point(this.x / value, this.y / value);
  }

  multiply(value: number): Point {
    return new Point(this.x * value, this.y * value);
  }

  rotate(angle: number): Point {
    const rad = angle * (Math.PI / 180);
    const x = this.x * Math.cos(rad) - this.y * Math.sin(rad);
    const y = this.x * Math.sin(rad) + this.y * Math.cos(rad);
    return new Point(x, y);
  }

  distanceTo(other: Point): number {
    const x = this.x - other.x;
    const y = this.y - other.y;
    return Math.sqrt(x * x + y * y);
  }

  toString(): string {
    return `(${this.x.toFixed(3)}, ${this.y.toFixed(3)})`;
  }
}

export class Line {
  constructor(public startPoint: Point, public endPoint: Point) {
    this.startPoint = startPoint;
    this.endPoint = endPoint;
  }

  distanceTo(point: Point): number {
    const a = this.startPoint.distanceTo(point);
    const b = this.endPoint.distanceTo(point);
    const c = this.startPoint.distanceTo(this.endPoint);

    if (a >= b + c) {
      return b;
    }

    if (b >= a + c) {
      return a;
    }

    const p = (a + b + c) / 2;
    const area = Math.sqrt(p * (p - a) * (p - b) * (p - c));
    return 2 * area / c;
  }

  toString(): string {
    return `${this.startPoint.toString()} -> ${this.endPoint.toString()}`;
  }

  copy(): Line {
    return new Line(this.startPoint.copy(), this.endPoint.copy());
  }
}

export enum Direction {
  Prev = "prev",
  Next = "next",
};
  