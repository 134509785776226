import ChunkManager from "../chunkManager";
import Chunk from "../chunk";

export class ChangeChunkFloorCommand {
    private chunkManager: ChunkManager;
    private chunk: Chunk;
    private newFloor: number;
    private moveConsecutiveChunks: boolean;
    private createNewFloor: boolean;
    private oldChunkFloors: number[];    
 
    
    constructor(chunkManager: ChunkManager, chunk: Chunk, newFloor: number, moveConsecutiveChunks: boolean, createNewFloor: boolean) {
        this.chunkManager = chunkManager;
        this.chunk = chunk;
        this.newFloor = newFloor;
        this.moveConsecutiveChunks = moveConsecutiveChunks;
        this.createNewFloor = createNewFloor;
        this.oldChunkFloors = [];
    }
    
    execute() {
        this.oldChunkFloors = this.chunkManager.getAllChunks().map((chunk) => chunk.floor);

        const levelAdjustment = this.newFloor < 0 ? 1 : 0

        if (this.createNewFloor) {
            this.chunkManager.getAllChunks().forEach((chunk) => {
                if (chunk.floor >= this.newFloor) {
                    chunk.floor += 1;
                }
            });
        }

        if (this.moveConsecutiveChunks) {
            this.chunkManager.getChunksAfterInSameFloor(this.chunk).forEach((chunk) => {
                chunk.floor = this.newFloor + levelAdjustment;
            });
        }
        this.chunk.floor = this.newFloor + levelAdjustment;
        this.normalizeFloors();
    }
    
    undo() {
        this.chunkManager.getAllChunks().forEach((chunk, index) => {
            chunk.floor = this.oldChunkFloors[index];
        });
    }

    normalizeFloors() {
    const uniqueFloors = Array.from(new Set(this.chunkManager.getAllChunks().map(chunk => chunk.floor))).sort((a, b) => a - b);

    this.chunkManager.getAllChunks().forEach(chunk => {
        chunk.floor = uniqueFloors.indexOf(chunk.floor);
    });
    }
}