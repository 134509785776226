/*
Creates and send XMLHttpRequest.
Input parameters:
    url: String which defines URL to send the request to.
    method: String which defines the request method to use, e.g. "GET", "POST", etc
    headers: Array of objects {name : "", value : ""}, which defines the request headers.
    responseType: String which defines the response type.
    payload: A body of data to be sent in the request.
*/

const statusSucceed = [200, 204, 201];

interface Header {
  name: string;
  value: string;
}

const xhrRequest = (
  url: string,
  method: string,
  headers: Header[] = [],
  responseType: XMLHttpRequestResponseType = "",
  payload: Document | XMLHttpRequestBodyInit | null = null
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = responseType;
    xhr.open(method, url, true);

    if (headers) {
      headers.forEach((header) => {
        xhr.setRequestHeader(header.name, header.value);
      });
    }

    xhr.onload = () => {
      if (xhr.readyState === 4) {
        if (statusSucceed.includes(xhr.status)) {
          if (responseType === "") {
            resolve(xhr.responseText);
          } else if (responseType === "arraybuffer" || responseType === "json") {
            resolve(xhr.response);
          } else {
            resolve();
          }
        } else if (responseType === "") {
          reject(xhr.responseText);
        } else if (responseType === "arraybuffer" || responseType === "json") {
          reject(xhr.response);
        } else {
          reject();
        }
      }
    };

    xhr.onerror = () => {
      reject(xhr.statusText);
    };
    xhr.send(payload);
  });
};

export default xhrRequest;