import { Command } from "./command.js";
import { Frame } from "../interfaces";
export class AddFloorChangeCommand implements Command {
  private originalFrames: Frame[];
  private originalFloors: number[];
  private floorChangeNumber: number;
  private frameNumber: number;

  constructor(originalFrames: Frame[], floorChangeNumber: number, frameNumber: number) {
    this.originalFrames = originalFrames;
    this.originalFloors = originalFrames.map((frame: Frame) => frame.floorNumber);
    this.floorChangeNumber = floorChangeNumber;
    this.frameNumber = frameNumber;
  }  

  execute() {
    for (let i = 0; i <= this.frameNumber; i += 1) {
      if (this.originalFrames[i].floorNumber === -1) {
        this.originalFrames[i].floorNumber = this.floorChangeNumber;
      }
    }
  }
   
  undo() {
    for (let i = 0; i <= this.floorChangeNumber; i += 1) {
      this.originalFrames[i].floorNumber = this.originalFloors[i];
    }
  }
  
}