// DragChunkCommand.ts
import { Command } from './command';
import { ChunkManager } from '../chunkManager';
import { Chunk } from '../chunk';
import { Point } from '../types';

export class DragChunkCommand implements Command {
  private chunkManager: ChunkManager;
  private selectedChunk: Chunk | null;
  private dragStart: Point | null;
  private dragEnd: Point | null;
  private movedChunks: Chunk[];

  constructor(chunkManager: ChunkManager, selectedChunk: Chunk, dragStart: Point, dragEnd: Point) {
    this.chunkManager = chunkManager;
    this.movedChunks = [];
    this.selectedChunk = selectedChunk;
    this.dragStart = dragStart;
    this.dragEnd = dragEnd;
  }

  setDragEnd(dragEnd: Point) {
    this.dragEnd = dragEnd;
  }

  execute() {
    if (this.selectedChunk && this.dragStart && this.dragEnd) {
      const delta = this.dragEnd.subtract(this.dragStart);

      this.movedChunks = this.chunkManager.getChunksAfter(this.selectedChunk);

      this.movedChunks.forEach((chunk) => {
        chunk.moveBy(delta.x, delta.y, false);
      });

      this.selectedChunk.moveBy(delta.x, delta.y, true);
    }
  }

  undo() {
    if (this.selectedChunk && this.dragStart && this.dragEnd) {
      const delta = this.dragStart.subtract(this.dragEnd);
      this.movedChunks = this.chunkManager.getChunksAfter(this.selectedChunk);

      this.movedChunks.forEach((chunk) => {
        chunk.moveBy(delta.x, delta.y, false);
      });

      this.selectedChunk.moveBy(delta.x, delta.y, true);
    }
  }


}