import { Point } from "./types";

const screenToCanvas = (screenPoint: Point, screenOffset: Point, zoom: number): Point => {
  return screenPoint.subtract(screenOffset).divide(zoom);
};

const canvasToScreen = (point: Point, offset: Point, zoom: number): Point => {
  return point.multiply(zoom).add(offset);
};

export { screenToCanvas, canvasToScreen };
