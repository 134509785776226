// Define an interface for keyboard shortcuts with descriptions
export interface KeyboardShortcutItem {
  key: string;
  description: string;
}

// Define the keyboard shortcuts as a constant object instead of an enum
export const KeyboardShortcut: Record<string, KeyboardShortcutItem> = {
  // Arrow keys for movement
  MOVE_RIGHT: { key: "ArrowRight", description: "Move right/Pan canvas right" },
  MOVE_LEFT: { key: "ArrowLeft", description: "Move left/Pan canvas left" },
  MOVE_UP: { key: "ArrowUp", description: "Move up/Pan canvas up" },
  MOVE_DOWN: { key: "ArrowDown", description: "Move down/Pan canvas down" },

  // Rotation
  ROTATE_LEFT: { key: "Comma", description: "Rotate selected chunk counterclockwise" },
  ROTATE_RIGHT: { key: "Period", description: "Rotate selected chunk clockwise" },

  // Zoom
  ZOOM_IN: { key: "+", description: "Zoom in" },
  ZOOM_OUT: { key: "-", description: "Zoom out" },

  // Chunk selection
  SELECT_PREV_CHUNK: { key: "z", description: "Select previous chunk" },
  SELECT_NEXT_CHUNK: { key: "x", description: "Select next chunk" },
  SELECT_CHUNK_BY_FRAME: { key: "e", description: "Select chunk by current frame" },

  // Floor Editing
  TOGGLE_FLOOR_EDITING: { key: "f", description: "Toggle floor editing mode" },
  RESET_FLOORS: { key: "r", description: "Reset floor assignments" },

  // Chunk operations
  SPLIT_CHUNK: { key: "s", description: "Split chunk at current frame" },
  TOGGLE_LINE_DRAWING: { key: "w", description: "Toggle line drawing mode" },
  COPY_LINE_TO_FLOORS: { key: "a", description: "Copy selected line to all floors" },
  DELETE_LINE: { key: "Backspace", description: "Delete selected line" },

  // View controls
  TOGGLE_VIDEO: { key: "v", description: "Toggle video visibility" },
  NEXT_FRAME: { key: "h", description: "Go to next frame" },
  PREV_FRAME: { key: "g", description: "Go to previous frame" },
  TOGGLE_PLAY: { key: "j", description: "Play/pause video" },
  TOGGLE_FOLLOW_CAMERA: { key: "d", description: "Toggle camera following" },

  CANCEL: { key: "Escape", description: "Cancel current operation/Return to default mode" },

  // Chunk opacity
  TOGGLE_OPACITY: { key: "t", description: "Toggle chunk opacity" },

  // Camera position
  CENTER_TO_CAMERA: { key: "c", description: "Center view to camera position" },

  // Floor operations
  MOVE_CHUNK_UP: { key: "u", description: "Move chunk to upper floor" },
  MOVE_CHUNK_DOWN: { key: "d", description: "Move chunk to lower floor" },
  SWAP_FLOOR_UP: { key: "b", description: "Swap with upper floor" },
  SWAP_FLOOR_DOWN: { key: "n", description: "Swap with lower floor" },

  // Debug
  TOGGLE_DEBUG: { key: "i", description: "Toggle debug mode" },

  // History
  UNDO: { key: "z", description: "Undo last action (with Ctrl/Cmd)" },
  REDO: { key: "y", description: "Redo last undone action (with Ctrl/Cmd)" },

  // Show help
  SHOW_HELP: { key: "?", description: "Show help" }
};

// Helper function to find a shortcut by its key value
export const getShortcutByKey = (keyValue: string): string | null => {
  for (const [name, shortcut] of Object.entries(KeyboardShortcut)) {
    if (shortcut.key === keyValue) {
      return name;
    }
  }
  return null;
};

// Update utility functions to work with the new structure
export const isMoveKey = (key: string): boolean => {
  return [
    KeyboardShortcut.MOVE_RIGHT.key,
    KeyboardShortcut.MOVE_LEFT.key,
    KeyboardShortcut.MOVE_UP.key,
    KeyboardShortcut.MOVE_DOWN.key,
  ].includes(key);
};

export const isLineDrawingKey = (key: string): boolean => {
  return key === KeyboardShortcut.TOGGLE_LINE_DRAWING.key ||
         key === KeyboardShortcut.DELETE_LINE.key ||
         key === KeyboardShortcut.COPY_LINE_TO_FLOORS.key;
};

export const isChunkSelectionKey = (key: string): boolean => {
  return [
    KeyboardShortcut.SELECT_PREV_CHUNK.key,
    KeyboardShortcut.SELECT_NEXT_CHUNK.key,
    KeyboardShortcut.SELECT_CHUNK_BY_FRAME.key,
  ].includes(key);
};

export const isFloorEditingKey = (key: string): boolean => {
  return key === KeyboardShortcut.TOGGLE_FLOOR_EDITING.key || 
         isDigitKey(key) || 
         key === KeyboardShortcut.RESET_FLOORS.key;
};

export const isDigitKey = (key: string): boolean => {
  return /^[0-9]$/.test(key);
};

export const isDigitKeyCode = (code: string): boolean => {
  return /^Digit[0-9]$/.test(code);
};

export const isChunkOpacityKey = (key: string): boolean => {
  return key === KeyboardShortcut.TOGGLE_OPACITY.key;
};

// Helper to get keyboard shortcut descriptions for UI display
export const getKeyboardShortcutDescriptions = (): Record<string, string> => {
  const descriptions: Record<string, string> = {};
  
  for (const [name, shortcut] of Object.entries(KeyboardShortcut)) {
    descriptions[name] = `${shortcut.key}: ${shortcut.description}`;
  }
  
  return descriptions;
};
