import _ from 'lodash';

import { getChunkColor } from "./drawUtils";
import Chunk from "./chunk";
import ChunkManager from "./chunkManager";
import Video from "./video";
import DataCalculator from "./dataCalculator";
import { screenToCanvas } from "./calculations";
import { Point, Direction, Rectangle, Line } from './types'; // Assuming Point class is in Point.ts
import { drawGrid, drawLineOnChunk, drawCameraFrustum, drawHelplines } from "./lineDrawing";
import { FloorGroupEntry, createFloorGroups, floorGroupingDifference } from "./floorGrouping";
import parseData from "./dataParser";

import { CommandHistory } from "./commands/commandHistory";
import { MoveSelectedChunkCommand } from "./commands/moveSelectedChunkCommand";
import { RotateSelectedChunkCommand } from "./commands/rotateSelectedChunkCommand";
import { SplitChunkCommand } from "./commands/splitChunkCommand";
import { DragChunkCommand } from "./commands/dragSelectedChunkCommand";
import { ChangeChunkFloorCommand } from "./commands/changeChunkFloorCommand"; 
import { SwapFloorsCommand } from "./commands/swapFloorsCommand";
import { AddHelpLineCommand } from './commands/addHelpLineCommand';
import { RemoveHelpLineCommand } from './commands/removeHelpLineCommand';
import { CopyHelpLinesToAllFloorsCommand } from './commands/copyHelpLinesToAllFloorsCommand';

class ImageChunkMover {
  chunkManager: ChunkManager;
  selectedChunk: Chunk | null;
  isPanning: boolean;
  isDragging: boolean;
  zoom: number;
  screenOffset: Point;
  panStart: Point;
  dragStart: Point;
  arkitData: any;
  videoObjectUrl: string;
  chunkData: any;
  images: Blob[] | null;
  indexMaps: any | null;
  currentFloor: number;
  selectedChunkOpacity: number;
  currentFrame: number;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D | null;
  automationData: any; 
  dataCalculator: DataCalculator;
  videoController: Video | null;
  helpLines: Record<number, Line[]>;
  lineStartPoint: Point | null;
  lineEndPoint: Point | null;
  history: CommandHistory;
  dragChunkCommand: DragChunkCommand | null;
  floorGroupings: FloorGroupEntry[];
  selectedLineIndex: number | null;

  constructor(arkitData: any, videoObjectUrl: string, chunkData: any) { 
    this.chunkManager = new ChunkManager();
    this.selectedChunk = null;
    this.isPanning = false;
    this.isDragging = false;
    this.zoom = 1;
    this.screenOffset = Point.zero();
    this.panStart = Point.zero()
    this.dragStart = Point.zero();
    this.currentFloor = 0;
    this.selectedChunkOpacity = 0.5;
    this.currentFrame = 0;

    this.canvas = document.getElementById("canvas");
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.ctx = this.canvas.getContext("2d");
    this.arkitData = arkitData;
    this.videoObjectUrl = videoObjectUrl;
    this.chunkData = chunkData;
    this.images = null;
    this.indexMaps = null;
    this.dataCalculator = new DataCalculator();
    this.videoController = null;
    this.helpLines = {};
    this.lineStartPoint = null;
    this.lineEndPoint = null;
    this.history = new CommandHistory();
    this.dragChunkCommand = null;
    this.floorGroupings = [];
    this.selectedLineIndex = null;
  }

  async init() {
    const parsedData = await parseData(this.chunkData);
    this.images = parsedData.images;
    this.indexMaps = parsedData.indexMaps;
    this.chunkData = parsedData.chunkData;
    await this.loadChunkImages();
    // Load the floor of the first floor.
    this.currentFloor = this.arkitData.frames[0].floorNumber;
    this.videoController = new Video(this.videoObjectUrl, this.arkitData);
    const firstCameraPosition = new Point(
      this.arkitData.frames[0].imageCameraPosition[0],
      this.arkitData.frames[0].imageCameraPosition[1]
    );
    this.centerToPoint(firstCameraPosition);
    this.redrawChunks();
  }

  frameChanged() {
    if (this.selectedChunk) {
      const frameChunk = this.chunkManager.getChunkByFrame(this.currentFrame);
      if (this.selectedChunk !== frameChunk) {
        this.selectedChunk = frameChunk;
        this.currentFloor = frameChunk.floor
      }
    } else {
      const frameChunk = this.chunkManager.getChunkByFrame(this.currentFrame);
      this.currentFloor = frameChunk.floor;
    }
  }

  nextFrame(frameAmount: number): number {
    this.currentFrame = this.videoController?.nextFrame(frameAmount) ?? this.currentFrame;
    this.frameChanged();
    this.redrawChunks();
    return this.currentFrame;
  }

  prevFrame(frameAmount: number): number {
    this.currentFrame = this.videoController?.prevFrame(frameAmount) ?? this.currentFrame;
    this.frameChanged();
    this.redrawChunks();
    return this.currentFrame;
  }

  togglePlay(multiplier:number, callback: (frame: number) => void) {
    this.videoController?.togglePlay(multiplier, () => {
      this.currentFrame = this.videoController?.currentFrame ?? this.currentFrame;
      this.frameChanged();
      this.redrawChunks();
      callback(this.currentFrame);
    });
  }

  async loadImage(file: Blob): Promise<{ imageWidth: number; imageHeight: number; chunkCanvas: OffscreenCanvas }> {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file); // create a URL for the Blob
      const img = new Image();
      img.src = url;
  
      img.onload = () => {
        // Create a new canvas to draw the image on
        const chunkCanvas = new OffscreenCanvas(img.width, img.height);
        const chunkCtx = chunkCanvas.getContext("2d", { willReadFrequently: true });
  
        if (chunkCtx) {
          chunkCtx.drawImage(img, 0, 0, img.width, img.height);
          URL.revokeObjectURL(url); // Revoke the URL to free up memory
          resolve({ imageWidth: img.width, imageHeight: img.height, chunkCanvas });
        } else {
          reject(new Error("Failed to get 2D context"));
        }
      };
  
      img.onerror = (err) => {
        console.error(`Error loading image ${file}:`, err);
        URL.revokeObjectURL(url); // Revoke the URL to free up memory
        reject(err);
      };
    });
  }

  async loadChunkImages() {
    return new Promise<void>((resolve) => {
      if (!this.images || this.images.length === 0) {
        console.error("No data");
        return;
      }
      // Asynchronously load all the images
      const loadPromises: Promise<any>[] = [];  
      this.chunkData.forEach((chunkDataItem, i) => {
          loadPromises.push(
            (async () => {
              const { imageWidth, imageHeight, chunkCanvas } = await this.loadImage(this.images[i]);
              const frames = []
              for (let i = chunkDataItem.firstFrameId; i <= chunkDataItem.lastFrameId; i += 1) {
                frames.push(this.arkitData.frames[i]);
              }
              return {
                imageWidth,
                imageHeight,
                chunkCanvas,
                chunkBoundaries: chunkDataItem.boundingBox,
                frames: frames,
                indexMap: this.indexMaps[i],
              };
            })()
          );
        });
    
      Promise.all(loadPromises).then((chunkData) => {
        // Order chunks based on the first frame number
        chunkData.sort((a, b) => a.frames[0].frameNumber - b.frames[0].frameNumber);
        chunkData.forEach(
          (
            {
              imageWidth,
              imageHeight,
              chunkCanvas,
              chunkBoundaries,
              frames,
              indexMap
            }
          ) => {
            this.chunkManager.addChunk(
              new Chunk(
                imageWidth,
                imageHeight,
                chunkCanvas,
                Rectangle.fromArray(chunkBoundaries),
                frames,
                indexMap
              )
            );
          }
        );
        this.floorGroupings = createFloorGroups(this.arkitData.frames)
        resolve();
      });
    });
  }

  undo() {
    this.history.undo();
    this.redrawChunks();
  }

  redo() {
    this.history.redo();
    this.redrawChunks();
  }

  isMovingChunk() {
    return this.selectedChunk !== null;
  }

  panXBy(dx: number) {
    this.screenOffset = this.screenOffset.add(new Point(-dx, 0));
    this.redrawChunks();
  }

  panYBy(dy: number) {
    this.screenOffset = this.screenOffset.add(new Point(0, -dy));
    this.redrawChunks();
  }

  moveSelectedChunk(dx: number, dy: number) {
    if (this.isDragging) {
      return;
    }
    const moveCommand = new MoveSelectedChunkCommand(this.chunkManager, this.selectedChunk, dx, dy);
    this.history.executeCommand(moveCommand);
    this.redrawChunks();
  }

  rotateSelectedChunk(rotation: number) {
    if (this.isDragging) {
      return;
    }
    const rotateComamnd = new RotateSelectedChunkCommand(this.chunkManager, this.selectedChunk!, rotation);
    this.history.executeCommand(rotateComamnd);
    this.redrawChunks();
  }

  splitChunk() {
    if (!this.selectedChunk || this.selectedChunk.frames[0].frameNumber === this.currentFrame || this.isDragging) {
      return;
    }
    const splitCommand = new SplitChunkCommand(this.chunkManager, this.selectedChunk!, this.currentFrame);
    this.history.executeCommand(splitCommand);

    this.selectedChunk = this.chunkManager.getChunkByFrame(this.currentFrame);
    this.redrawChunks();
  }

  startPanning(startPoint: Point) {
    this.isPanning = true;
    this.panStart = startPoint.copy();
  }

  panWith(point: Point) {
    const delta = point.subtract(this.panStart)
    this.screenOffset = this.screenOffset.add(delta);
    this.panStart = point;
    this.redrawChunks();
  }

  stopPanning() {
    this.isPanning = false;
  }

  startDraggingChunk(screenPoint: Point) {
    if (!this.selectedChunk) {
      return;
    }
    const point = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
    if (this.selectedChunk.containsPoint(point)) {
      this.isDragging = true;
      this.dragStart = point;
      this.dragChunkCommand = new DragChunkCommand(this.chunkManager, this.selectedChunk, point, point);
    }
  }

  dragWith(screenPoint: Point) {
    if (!this.isDragging || !this.selectedChunk || !this.dragChunkCommand) {
      return;
    }

    const point = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
    const delta = point.subtract(this.dragStart);

    const movedChunks = this.chunkManager.getChunksAfter(this.selectedChunk);

    movedChunks.forEach((chunk) => {
      chunk.moveBy(delta.x, delta.y, false);
    });

    this.selectedChunk.moveBy(delta.x, delta.y, true);

    this.dragStart = point;
    this.dragChunkCommand.setDragEnd(point);
    this.redrawChunks();
  }

  stopDraggingChunk() {
    if (this.dragChunkCommand) {
      // Undo "temporary" changes. Command execute will apply the final position change
      this.dragChunkCommand.undo();
      this.history.executeCommand(this.dragChunkCommand);
      this.dragChunkCommand = null;
    }
    this.isDragging = false;
  }

  selectFloor(floorIndex: number): number {
    if (floorIndex < 0 || floorIndex >= this.chunkManager.getFloorAmount()) {
      return this.currentFloor;
    }
    this.currentFloor = floorIndex;
    this.currentFrame = this.chunkManager.getAllChunksInFloor(this.currentFloor)[0].firstFrameNumber;
    this.videoController?.seekToFrame(this.currentFrame);
    this.deselectChunk();
    return this.currentFrame;
  }

  selectChunkDirection(direction: Direction) {
    if (this.selectedChunk === null) {
      this.selectChunk(this.chunkManager.getChunk(0));
      return [this.currentFloor, this.selectedChunk];
    }
    const allChunks = this.chunkManager.getAllChunks();
    let chunkIndex = this.chunkManager.getIndexForChunk(this.selectedChunk);
    if (direction === Direction.Next) {
      chunkIndex = (chunkIndex + 1) % allChunks.length;
    } else if (direction === Direction.Prev) {
      chunkIndex = (chunkIndex - 1 + allChunks.length) % allChunks.length;
    }
    const chunk = this.chunkManager.getChunk(chunkIndex);
    this.selectChunk(chunk)
    return [this.currentFloor, this.selectedChunk];
  }

  selectChunkByCurrentFrame() {
    const chunk = this.chunkManager.getChunkByFrame(this.currentFrame);
    const framePosition = this.arkitData.frames.find((frame) => frame.frameNumber === this.currentFrame)?.imageCameraPosition;
    const framePoint = new Point(framePosition![0], framePosition![1]);
    this.centerToPoint(framePoint);
    this.selectedChunk = chunk;
    this.currentFloor = this.selectedChunk.floor;
    this.redrawChunks();
    return [this.currentFloor, this.selectedChunk];
  }

  selectChunk(chunk: Chunk) {
    this.selectedChunk = chunk;
    this.currentFloor = this.selectedChunk.floor;
    this.videoController?.seekToFrame(this.selectedChunk.firstFrameNumber);
    this.currentFrame = this.selectedChunk.firstFrameNumber;
    this.focusSelectedChunk();
  }

  selectNextChunk() {
    return this.selectChunkDirection(Direction.Next);
  }

  deselectChunk() {
    this.selectedChunk = null;
    this.redrawChunks();
  }

  moveSelectedChunkToFloor(floorIndex: number, moveConsecutiveChunks: boolean, createFloor: boolean): [number, Chunk | null] {
    if (!this.selectedChunk) {
      return [this.currentFloor, this.selectedChunk];
    }
    const newFloor = floorIndex;
    const moveCommand = new ChangeChunkFloorCommand(this.chunkManager, this.selectedChunk, newFloor, moveConsecutiveChunks, createFloor);
    this.history.executeCommand(moveCommand);
    this.currentFloor = this.selectedChunk.floor;
    this.redrawChunks()
    return [this.currentFloor, this.selectedChunk]
  }

  moveSelectedChunkToUpperFloor(moveConsecutiveChunks: boolean, createFloor: boolean): [number, Chunk | null] {
    const newFloor = this.currentFloor + 1;
    if (newFloor >= this.chunkManager.getFloorAmount() && !createFloor) {
      return [this.currentFloor, this.selectedChunk];
    }
    return this.moveSelectedChunkToFloor(newFloor, moveConsecutiveChunks, createFloor);
  }

  moveSelectedChunkToLowerFloor(moveConsecutiveChunks: boolean, createFloor: boolean): [number, Chunk | null] {
    const newFloor = this.currentFloor - 1;
    if (newFloor < 0 && !createFloor) {
      return [this.currentFloor, this.selectedChunk];
    }
    return this.moveSelectedChunkToFloor(newFloor, moveConsecutiveChunks, createFloor);
  }

  swapSelectedChunkFloor(newFloor: number): [number, Chunk | null] {
    const swapCommand = new SwapFloorsCommand(this, this.currentFloor, newFloor);
    this.history.executeCommand(swapCommand);
    this.currentFloor = newFloor;
    this.currentFrame = this.chunkManager.getAllChunksInFloor(this.currentFloor)[0].firstFrameNumber;
    this.videoController?.seekToFrame(this.currentFrame);
    this.deselectChunk();
    return [this.currentFloor, this.selectedChunk];
  }

  swapSelectedChunkFloorWithUpperFloor(): [number, Chunk | null] {
    const newFloor = this.currentFloor + 1;
    if (newFloor >= this.chunkManager.getFloorAmount()) {
      return [this.currentFloor, this.selectedChunk];
    }
    return this.swapSelectedChunkFloor(newFloor);
  }

  swapSelectedChunkFloorWithBelowFloor(): [number, Chunk | null] {
    const newFloor = this.currentFloor - 1;
    if (newFloor < 0) {
      return [this.currentFloor, this.selectedChunk];
    }
    return this.swapSelectedChunkFloor(newFloor);
  }

  selectHelpLine(screenPoint: Point): boolean {
    const startPoint = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
    const thresholdDistance = 10; // Define "too close" distance, adjust as needed
    let closestLineIndex = null;
    let closestDistance = Infinity;

    if (!this.helpLines[this.currentFloor]) {
      return false;
    }

    // Find the closest line, if any
    this.helpLines[this.currentFloor].forEach((line, index) => {
        const distance = line.distanceTo(startPoint);
        if (distance < closestDistance) {
            closestDistance = distance;
            closestLineIndex = index;
        }
    });
    // If the closest line is within the threshold distance, select it
    if (closestDistance <= thresholdDistance) {
        this.selectedLineIndex = closestLineIndex;
        this.redrawChunks();
        return true;
    }
    this.selectedLineIndex = null;
    this.redrawChunks()
    return false;
  }

  startLineDrawing(screenPoint: Point) {
      const startPoint = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
      this.lineStartPoint = startPoint
      this.selectedLineIndex = null
  }

  private calculateEndPoint(screenPoint: Point, alignToAngles: boolean): Point {
    let endPoint = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
  
    if (alignToAngles) {
      const angle = Math.atan2(endPoint.y - this.lineStartPoint.y, endPoint.x - this.lineStartPoint.x);
      const roundedAngle = Math.round(angle / (Math.PI / 20)) * (Math.PI / 20); // 9 degrees in radians
      const distance = this.lineStartPoint.distanceTo(endPoint);
      endPoint = new Point(
        this.lineStartPoint.x + distance * Math.cos(roundedAngle),
        this.lineStartPoint.y + distance * Math.sin(roundedAngle)
      );
    }
  
    return endPoint;
  }

  setCurrentEndPoint(screenPoint: Point, alignToAngles: boolean) {
    if (!this.lineStartPoint) {
      return;
    }
    this.lineEndPoint = this.calculateEndPoint(screenPoint, alignToAngles);
    this.redrawChunks();
  }

  continueLineDrawing(screenPoint: Point, alignToAngles: boolean) {
    if (!this.lineStartPoint) {
      return;
    }
    const endPoint = this.calculateEndPoint(screenPoint, alignToAngles);
    this.addHelpLine(new Line(this.lineStartPoint, endPoint));
    this.lineStartPoint = endPoint.copy();
    this.redrawChunks();
  }

  cancelLineDrawing() {
    this.selectedLineIndex = null;
    this.lineStartPoint = null;
    this.redrawChunks();
  }

  stopLineDrawing(screenPoint: Point, alignToAngles: boolean) {
    if (!this.lineStartPoint) {
      return;
    }
    this.selectedLineIndex = null;
    const endPoint = this.calculateEndPoint(screenPoint, alignToAngles);
    const helpLine = new Line(this.lineStartPoint, endPoint);

    // Check that if end point is close to the start point, don't add the line
    if (helpLine.startPoint.distanceTo(helpLine.endPoint) < 10) {
      this.lineStartPoint = null;
      return;
    }

    this.addHelpLine(helpLine);
    this.lineStartPoint = null;
  }

  removeSelectedHelpLine() {
    if (this.selectedLineIndex === null || !this.helpLines[this.currentFloor]) {
      return;
    }
    const removeHelpLineCommand = new RemoveHelpLineCommand(this, this.selectedLineIndex, this.currentFloor);
    this.history.executeCommand(removeHelpLineCommand);
    this.selectedLineIndex = null;
    this.redrawChunks();
  }

  addHelpLine(helpLine: Line) {
    const addHelpLineComamand = new AddHelpLineCommand(this, helpLine, this.currentFloor);
    this.history.executeCommand(addHelpLineComamand);
    this.redrawChunks();
  }

  copySelectedLineToAllFloors() {
    if (this.selectedLineIndex === null || !this.helpLines[this.currentFloor]) {
      return;
    }
    const line = this.helpLines[this.currentFloor][this.selectedLineIndex];
    const copyCommand = new CopyHelpLinesToAllFloorsCommand(this, line, this.currentFloor);
    this.history.executeCommand(copyCommand);
  }
 
  selectPrevChunk() {
    return this.selectChunkDirection(Direction.Prev);
  }

  selectPathPoint(screenPoint: Point) {
    const zoomedPoint = screenToCanvas(
      screenPoint,
      this.screenOffset,
      this.zoom
    );

    let closestFrame = -1;
    let closestDistanceSquared = Number.MAX_VALUE;
    this.arkitData.frames.forEach((frame) => {
      const chunk = this.chunkManager.getChunkByFrame(frame.frameNumber);
      if (!chunk) {
        return;
      }

      if (chunk.floor !== this.currentFloor) {
        return;
      }

      const imageCameraPosition = new Point(frame.imageCameraPosition[0], frame.imageCameraPosition[1]);
      const rotatedPoint = imageCameraPosition.rotate(chunk.globalRotation);
      const cameraPosition = rotatedPoint.add(chunk.position);
      const delta = zoomedPoint.subtract(cameraPosition.multiply(this.zoom));

      const distanceSquared = delta.x * delta.x + delta.y * delta.y;  
      if (distanceSquared < closestDistanceSquared) {
        closestDistanceSquared = distanceSquared;
        closestFrame = frame.frameNumber;
      }
    }); 
    this.currentFrame = closestFrame;
    this.frameChanged();
    this.redrawChunks();
    this.videoController?.seekToFrame(this.currentFrame);
    return this.currentFrame;
  }

  zoomToCenter = (factor: number) => {
    const newZoom = Math.min(Math.max(0.30, this.zoom + factor), 4);
    const screenCenterPoint = new Point(this.canvas.width / 2, this.canvas.height / 2);
    this.zoomToPointAndCenter(newZoom, screenCenterPoint);
  };

  zoomToPoint(factor: number, screenPoint: Point) {
    const newZoom = Math.min(Math.max(0.30, this.zoom + factor), 4);
    const screenMiddle = new Point(this.canvas.width / 2, this.canvas.height / 2);
    const zoomDelta = newZoom - this.zoom;
    const screenDelta = screenPoint.subtract(screenMiddle).multiply(zoomDelta);
    this.zoomToPointAndCenter(newZoom, screenMiddle.add(screenDelta));
    
    this.zoom = newZoom; 
    this.redrawChunks();
  }

  zoomToPointAndCenter = (newZoom: number, screenPoint: Point) => {
    const point = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
    this.zoom = newZoom;
    this.centerToPoint(point);
    this.redrawChunks();
  };

  centerToPoint(point: Point) {
    this.screenOffset = new Point(
      this.canvas.width / 2 - point.x * this.zoom * this.zoom,
      this.canvas.height / 2 - point.y * this.zoom * this.zoom
    );
  }

  focusSelectedChunk() {
    if (!this.selectedChunk) {
      return;
    }
   
    const chunk = this.selectedChunk;

    const startPosition = chunk.pathStartPosition;
    this.centerToPoint(startPosition)

    this.redrawChunks();
  }

  redrawChunks() {
    // Clear the original canvas
    this.ctx?.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Create an off-screen canvas to draw the grid and chunks on
    const offlineCanvas = new OffscreenCanvas(this.canvas.width, this.canvas.height);
    const offlineCtx = offlineCanvas.getContext("2d");

    // Draw the grid on the unscaled canvas
    drawGrid(offlineCtx!, this.canvas.width, this.canvas.height, this.screenOffset, this.zoom * this.zoom);

    // Apply pan and zoom transformations
    offlineCtx?.save();
    offlineCtx?.translate(this.screenOffset.x, this.screenOffset.y);
    offlineCtx?.scale(this.zoom, this.zoom);

    let floorChunks = this.chunkManager.getAllChunksInFloor(this.currentFloor);

    const selectedChunkCurrentFloorIndex = floorChunks.indexOf(
      this.selectedChunk
    );
    if (selectedChunkCurrentFloorIndex !== -1) {
      // Remove the selected chunk from its current position
      let selectedChunk = floorChunks.splice(
        selectedChunkCurrentFloorIndex,
        1
      )[0];

      // Add the selected chunk back to the end of the array
      floorChunks.push(selectedChunk);
    }
  
    // First loop to draw all the images
    floorChunks.forEach((chunk) => {
      offlineCtx?.save();

      offlineCtx?.translate(
        (chunk.position.x + chunk.rotationPoint.x) * this.zoom,
        (chunk.position.y + chunk.rotationPoint.y) * this.zoom
      );

      // Rotate the context
      offlineCtx?.rotate((chunk.globalRotation * Math.PI) / 180);

      // Translate back
      offlineCtx?.translate(
        -chunk.rotationPoint.x * this.zoom,
        -chunk.rotationPoint.y * this.zoom
      );

      // Draw the image
      if (this.selectedChunk) {
        if (this.chunkManager.getIndexForChunk(chunk) < this.chunkManager.getIndexForChunk(this.selectedChunk)) {
          if (offlineCtx?.globalAlpha !== 1.0) {
            offlineCtx.globalAlpha = 1.0;
          }
        } else if (this.chunkManager.getIndexForChunk(chunk) === this.chunkManager.getIndexForChunk(this.selectedChunk)) {
          if (offlineCtx?.globalAlpha !== this.selectedChunkOpacity) {
            offlineCtx.globalAlpha = this.selectedChunkOpacity;
          }
        } else {
          if (offlineCtx?.globalAlpha !== 0.1) {
            offlineCtx.globalAlpha = 0.1;
          }
        }
      } else {
        if (offlineCtx?.globalAlpha !== 1.0) {
          offlineCtx.globalAlpha = 1.0;
        }
      }
      // Draw the off-screen canvas onto the main canvas
      offlineCtx?.drawImage(
        chunk.canvas,
        0,
        0,
        chunk.width * this.zoom,
        chunk.height * this.zoom
      );

      offlineCtx?.restore();
    });

    // Second loop to draw all the lines
    floorChunks.forEach((chunk) => {
      offlineCtx?.save();

      offlineCtx?.translate(chunk.position.x * this.zoom, chunk.position.y * this.zoom);

      offlineCtx?.translate(
        chunk.rotationPoint.x * this.zoom,
        chunk.rotationPoint.y * this.zoom
      );

      // Rotate the context
      offlineCtx?.rotate((chunk.globalRotation * Math.PI) / 180);

      // Translate back
      offlineCtx?.translate(
        -chunk.rotationPoint.x * this.zoom,
        -chunk.rotationPoint.y * this.zoom
      );

      drawLineOnChunk(
        offlineCtx!,
        this.zoom,
        chunk,
        getChunkColor(this.chunkManager.getIndexForChunk(chunk)),
        chunk === this.selectedChunk
      );
      offlineCtx?.restore();

    });

    if (this.lineStartPoint && this.lineEndPoint) {
      const line = new Line(this.lineStartPoint, this.lineEndPoint)
      drawHelplines(offlineCtx!, [line], this.selectedLineIndex, this.zoom);
    }
    drawHelplines(offlineCtx!, this.helpLines[this.currentFloor], this.selectedLineIndex, this.zoom);

    const frameChunk = this.chunkManager.getChunkByFrame(this.currentFrame);
    drawCameraFrustum(offlineCtx!, this.zoom, frameChunk!, this.currentFrame);
    offlineCtx?.restore();

    // Draw the off-screen canvas onto the main canvas
    this.ctx?.drawImage(offlineCanvas, 0, 0);

  }

  exportData() {
    const newArkitData = this.dataCalculator.rotateArkitData(
      this.arkitData,
      this.chunkManager.getAllChunks()
    );

    const newFixroundFixes = this.chunkManager.getAllChunks().map((chunk) => {
      return {
        frameNumber: chunk.firstFrameNumber,
        rotationDeg: Number(chunk.rotation.toFixed(3)),
        translationM: [chunk.shift.x / 100.0, 0, chunk.shift.y / 100.0].map(
          (f) => Number(f.toFixed(3))
        ),
        source: "sourceFixTool",
      };
    });

    const fixRounds = this.arkitData.fixRounds;
    const newFixRound = {
      fixRoundNum: fixRounds.length,
      type: "manualSourceFix",
      fixes: newFixroundFixes.filter(
        (fix) =>
          fix.rotationDeg !== 0 ||
          fix.translationM[0] !== 0 ||
          fix.translationM[1] !== 0
      ),
    };
    const newData = { ...newArkitData, fixRounds: [...fixRounds, newFixRound] };

    const newFloorGroups = createFloorGroups(newArkitData.frames);
    if (!_.isEqual(this.floorGroupings, newFloorGroups)) {
      newData.floor_grouping = newFloorGroups;
      const difference = floorGroupingDifference(this.floorGroupings, newFloorGroups, newArkitData.frames.length);
      
      // Step 1: Aggregate changes
      const aggregatedDifferences = {};
      Object.entries(difference).forEach(([key, value]) => {
        if (!aggregatedDifferences[value]) {
          aggregatedDifferences[value] = { startFrame: parseInt(key, 10), endFrame: parseInt(key, 10) };
        } else {
          aggregatedDifferences[value].endFrame = parseInt(key, 10);
        }
      });

      // Step 2: Create objects with grouped frames
      Object.entries(aggregatedDifferences).forEach(([value, { startFrame, endFrame }]) => {
        if (startFrame === endFrame) {
          // If the start and end frames are the same, it means only one frame has this difference
          newFixRound.fixes.push({ "frameNumber": startFrame, "floorDifference": parseInt(value, 10), source: "manualSourceFix" });
        } else {
          // For multiple frames with the same difference, include both the start frame and the last applied frame
          newFixRound.fixes.push({ "frameNumber": startFrame, "lastAppliedFrame": endFrame, "floorDifference": parseInt(value, 10), source: "manualSourceFix" });
        }
      });
    }

    if ( newFixRound.fixes.length === 0) {
      return this.arkitData;
    }
    return newData;
  }
}
export default ImageChunkMover;
