// drawUtils.js

export function getChunkColor(chunkNumber) {
  const colors = [
    "#994c7c",
    "#62be4a",
    "#d69831",
    "#616bca",
    "#777931",
    "#d65135",
    "#aab740",
    "#cd8acc",
    "#41c0c7",
    "#a46331",
    "#9c5bce",
    "#388661",
    "#678ecc",
    "#d1a768",
    "#ae464c",
    "#e58487",
    "#c94cb0",
    "#65c085",
    "#4e8c37",
    "#da447c",
  ];
  return colors[chunkNumber % colors.length];
}
