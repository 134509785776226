import _ from 'lodash';

import ChunkEditor from '../chunkEditor';
import { Line } from "../types";
import { copyHelpLines } from './commandUtils';

export class AddHelpLineCommand {
    private imageChunkMover: ChunkEditor;
    private line: Line;
    private floor: number;
    private originalHelpLines = {};

    constructor(imageChunkMover: ChunkEditor, line: Line, floor: number) {
        this.imageChunkMover = imageChunkMover
        this.line = line
        this.floor = floor
        this.originalHelpLines = copyHelpLines(this.imageChunkMover.helpLines)
    }
 
    execute() {
        if (this.imageChunkMover.helpLines[this.floor] === undefined) {
            this.imageChunkMover.helpLines[this.floor] = []
        }
        this.imageChunkMover.helpLines[this.floor].push(this.line)
        this.imageChunkMover.selectedLineIndex = null
    }

    undo() {
        this.imageChunkMover.helpLines = copyHelpLines(this.originalHelpLines)
        this.imageChunkMover.selectedLineIndex = null
    }
}