import JSZip from "jszip";

export class FileUploader {
  constructor(doc, callback) {
    this.doc = doc;
    this.callback = callback;
    this.fileUpload = this.doc.getElementById("file-upload");
    this.uploadButton = this.doc.getElementById("upload-button");
    this.dropArea = this.doc.getElementById("drop-area");

    this.setupFileUpload();
  }

  setupFileUpload() {
    this.uploadButton.addEventListener("click", () => {
      this.fileUpload.click();
    });

    this.fileUpload.addEventListener("change", this.handleFiles.bind(this));

    this.dropArea.addEventListener("dragover", (e) => {
      e.preventDefault();
    });

    this.dropArea.addEventListener("drop", (e) => {
      e.preventDefault();
      this.handleFiles(e.dataTransfer.files);
    });
  }

  handleFiles(files) {
    // If an event object was passed, get the files from the event.
    // Otherwise, use the files argument directly.
    files = files.target ? files.target.files : files;

    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        JSZip.loadAsync(e.target.result).then((zip) => {
          this.callback(zip);
          this.dropArea.style.display = "none";
        });
      };
      reader.readAsArrayBuffer(file);
    }
  }
}
