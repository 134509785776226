import { Point, Line } from "./types";
import  Chunk from "./chunk";

export const drawGrid = (() => {
  const cache = new Map<string, OffscreenCanvas>();
  const MAX_CACHE_ENTRIES = 50; // Limit cache size
  const GRID_PRECISION = 2; // Round values to reduce unique combinations

  // Helper to round values for cache key
  const roundForCache = (value: number): number => {
    return Number(value.toFixed(GRID_PRECISION));
  };

  return (ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D, width: number, height: number, offset: Point, zoom: number, baseGridSize: number = 50) => {
    // Round values for cache key to reduce unique combinations
    const roundedZoom = roundForCache(zoom);
    const roundedOffsetX = roundForCache(offset.x % (baseGridSize * zoom));
    const roundedOffsetY = roundForCache(offset.y % (baseGridSize * zoom));
    
    const cacheKey = `${width}+${height}+${roundedOffsetX}+${roundedOffsetY}+${roundedZoom}`;
    let cachedCanvas = cache.get(cacheKey);

    if (!cachedCanvas) {
      // If cache is too large, remove oldest entries
      if (cache.size >= MAX_CACHE_ENTRIES) {
        const keysIterator = cache.keys();
        const oldestKey = keysIterator.next().value;
        cache.delete(oldestKey);
      }

      // Create an off-screen canvas to cache the grid drawing
      cachedCanvas = new OffscreenCanvas(width, height);
      cachedCanvas.width = width;
      cachedCanvas.height = height;
      const offscreenCtx = cachedCanvas.getContext('2d')!;

      const zoomedGridSize = baseGridSize * zoom;
      const numHorizontalLines = Math.ceil(width / zoomedGridSize);
      const numVerticalLines = Math.ceil(height / zoomedGridSize);

      const gridOffsetX = offset.x % zoomedGridSize;
      const gridOffsetY = offset.y % zoomedGridSize;

      offscreenCtx.save();

      offscreenCtx.strokeStyle = "#1f1faf";
      offscreenCtx.lineWidth = 0.5;

      // Draw the horizontal lines
      for (let i = 0; i <= numVerticalLines; i++) {
        offscreenCtx.beginPath();
        offscreenCtx.moveTo(0, i * zoomedGridSize + gridOffsetY);
        offscreenCtx.lineTo(width, i * zoomedGridSize + gridOffsetY);
        offscreenCtx.stroke();
      }

      // Draw the vertical lines
      for (let i = 0; i <= numHorizontalLines; i++) {
        offscreenCtx.beginPath();
        offscreenCtx.moveTo(i * zoomedGridSize + gridOffsetX, 0);
        offscreenCtx.lineTo(i * zoomedGridSize + gridOffsetX, height);
        offscreenCtx.stroke();
      }
      offscreenCtx.restore();

      // Cache the off-screen canvas
      cache.set(cacheKey, cachedCanvas);
    }

    // Draw the cached canvas onto the main canvas
    ctx.drawImage(cachedCanvas, 0, 0);
  };
})();

export const drawLineOnChunk = (
  ctx: CanvasRenderingContext2D,
  zoom: number,
  chunk: Chunk,
  color: string,
  drawRectangle: boolean,
) => {
  ctx.save();
  
  // Set the line width
  ctx.lineWidth = 2;

  if (drawRectangle) {
    // Draw a rectangle on the chunk using the chunkBoundaries
    const width = chunk.chunkBoundaries.size.width * zoom;
    const height = chunk.chunkBoundaries.size.height * zoom;
    ctx.strokeStyle = color;
    ctx.strokeRect(
      chunk.chunkBoundaries.startPoint.x * zoom,
      chunk.chunkBoundaries.startPoint.y * zoom,
      width,
      height
    );
  }

  const frameData = chunk.frames;

  ctx.beginPath();
  ctx.moveTo(
    frameData[0].imageCameraPosition[0] * zoom,
    frameData[0].imageCameraPosition[1] * zoom
  );
  
  for (let j = 1; j < frameData.length; j += 1) {
    ctx.lineTo(
      frameData[j].imageCameraPosition[0] * zoom,
      frameData[j].imageCameraPosition[1] * zoom
    );
  }
  ctx.strokeStyle = color;
  ctx.stroke();

  // Draw a colored circle at the first point
  ctx.beginPath();
  ctx.arc(
    frameData[0].imageCameraPosition[0] * zoom,
    frameData[0].imageCameraPosition[1] * zoom,
    5,
    0,
    2 * Math.PI,
    false
  );
  ctx.fillStyle = color;
  ctx.fill();

    // Apply transformations for rotation
    ctx.translate(
      chunk.rotationPoint.x * zoom,
      chunk.rotationPoint.y * zoom
    );
    ctx.rotate(chunk.globalRotation * (Math.PI / 180));
    ctx.translate(
      -chunk.rotationPoint.x * zoom,
      -chunk.rotationPoint.y * zoom
    );

  ctx.restore();
};

export const drawHelplines = (ctx: CanvasRenderingContext2D, helpLines: Line[], highightIndex: number | null, zoom: number) => {
  if (!helpLines) {
    return;
  }

  ctx.save();
  helpLines.forEach((line, index) => {
    if (index === highightIndex) {
      ctx.strokeStyle = "red";
      ctx.lineWidth = 7;

    } else {
      ctx.strokeStyle = "green";
      ctx.lineWidth = 5;

    }
    ctx.beginPath();
    ctx.moveTo(line.startPoint.x * zoom, line.startPoint.y * zoom);
    ctx.lineTo(line.endPoint.x * zoom, line.endPoint.y * zoom);
    ctx.stroke();
  });
  ctx.restore();
}
  
export const drawCameraFrustum = (ctx: CanvasRenderingContext2D, zoom: number, chunk: Chunk, frameNumber: number) => {
    const frameData = chunk.frames[frameNumber - chunk.firstFrameNumber];
    if (!frameData) {
      return;
    }
    const imageCameraPosition =
     frameData.imageCameraPosition;

    const cameraAngleDeg =
      chunk.frames[frameNumber - chunk.firstFrameNumber].imageCameraRotation;
    const [x, y] = imageCameraPosition;

    ctx.save();

    ctx.translate(chunk.position.x * zoom, chunk.position.y * zoom);

    ctx.translate(
      chunk.rotationPoint.x * zoom,
      chunk.rotationPoint.y * zoom
    );

    // Rotate the canvas by the chunk's rotation
    ctx.rotate(chunk.globalRotation * (Math.PI / 180));

    // Translate back
    ctx.translate(
      -chunk.rotationPoint.x * zoom,
      -chunk.rotationPoint.y * zoom
    );  

    // Set the line width
    ctx.lineWidth = 2;

    // draw dot as current time indicator
    ctx.beginPath();
    ctx.arc(x * zoom, y * zoom, 5, 0, 2 * Math.PI, false);
    ctx.fillStyle = "black";
    ctx.fill();

    // draw a line from the dot to the camera direction

    const cameraRotation = cameraAngleDeg * (Math.PI / 180);

    ctx.beginPath();
    ctx.moveTo(x * zoom, y * zoom);

    ctx?.lineTo(
      x * zoom + 200 * Math.cos(cameraRotation),
      y * zoom + 200 * Math.sin(cameraRotation)
    );
    ctx.stroke();

    // draw frustum
  
    // Calculate the direction of the two lines
    const fov = Math.PI / 3; // 60 degrees in radians
    const line1Direction = cameraRotation - fov / 2;
    const line2Direction = cameraRotation + fov / 2;

    // Draw the two lines
    ctx.beginPath();
    ctx.moveTo(x * zoom, y * zoom);
    
    ctx.lineTo(
      x * zoom + 200 * Math.cos(line1Direction),
      y * zoom + 200 * Math.sin(line1Direction)
    );
    ctx.strokeStyle = "blue";
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x * zoom, y * zoom);
    ctx.lineTo(
      x * zoom + 200 * Math.cos(line2Direction),
      y * zoom + 200 * Math.sin(line2Direction)
    );
    ctx.strokeStyle = "red";
    ctx.stroke();

    ctx.restore();
  }