import { InitializeFloorEditingCommand } from "./commands/initializeFloorEditingCommand";
import { CancelFloorEditingCommand } from "./commands/cancelFloorEditingCommand";
import { AddFloorChangeCommand } from "./commands/addFloorChangeCommand";
import { FinishFloorEditingCommand } from "./commands/finishFloorEditingCommand";

import ChunkManager from "./chunks/chunkManager";
import { Frame } from "./interfaces";


export class FloorEditor {
  isEditingStarted: boolean;

  private arkitData: any;
  private chunkManager: ChunkManager;
  private originalFloors: number[];

  constructor(chunkManager: ChunkManager, arkitData: any) {
    this.chunkManager = chunkManager;
    this.arkitData = arkitData;
    this.originalFloors = arkitData.frames.map((frame: any) => frame.floorNumber);
    this.isEditingStarted = false;
  }

  resetFloors(): InitializeFloorEditingCommand {
    const startCommand = new InitializeFloorEditingCommand(this.arkitData, this);
    return startCommand;
  }

  stopEditing(): CancelFloorEditingCommand | FinishFloorEditingCommand | null {
    if (!this.isEditingStarted) {
      return null;
    }
    if (!this.isEditingOngoing()) {
      const finishCommand = new FinishFloorEditingCommand(this.chunkManager, this.arkitData, this);
      return finishCommand;
    }
    const cancelCommand = new CancelFloorEditingCommand(this.arkitData.frames, this.originalFloors, this);
    return cancelCommand;
  }

  addFloorChange(frameNumber: number, floorIndex: number): AddFloorChangeCommand | null {
    if (!this.isEditingOngoing()) {
      return null;
    }
    const addCommand = new AddFloorChangeCommand(this.arkitData.frames, floorIndex, frameNumber);
    return addCommand;
  }

  isEditingOngoing(): boolean {
    return this.arkitData.frames.some((frame: any) => frame.floorNumber === -1);
   }
}