import { Point } from "./types";

export const isVersionOrLater = (version: string, minimumVersion: string): boolean => {
  const vParts = version.split('.').map(Number);
  const minParts = minimumVersion.split('.').map(Number);

  for (let i = 0; i < Math.max(vParts.length, minParts.length); i++) {
    const vPart = vParts[i] || 0;  // Use 0 if part doesn't exist
    const minPart = minParts[i] || 0;

    if (vPart > minPart) return true;
    if (vPart < minPart) return false;
  }
  return true;  // Equal versions means it is "or later"
}

export const getMousePosition = (canvas: HTMLCanvasElement, event: MouseEvent): Point => {
  const rect = canvas.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;
  return new Point(x, y);
};

export const throttle = <T extends (...args: any[]) => any>(
  func: T,
  limit: number,
): ((...args: Parameters<T>) => void) => {
  let inThrottle: boolean;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};
