import JSZip from "jszip";

import { IndexData } from "./interfaces";

const transformChunkData = (chunkDataJson): any[] => {
  const chunkDataArray = [];
  Object.entries(chunkDataJson.dataChunks).sort((a, b) => parseInt(a[0], 10) - parseInt(b[0])).forEach(([index, chunk], i, arr) => {
      // Check if chunk indexes are consecutive
      if (i > 0 && index - parseInt(arr[i - 1][0], 10) !== 1) {
        console.warn(
          `Chunk indexes are not consecutive: ${arr[i - 1][0]} and ${index}`
        );
        const emptyChunkData = {
          imageFile: "",
          boundingBox: { x: 0, y: 0, width: 0, height: 0 },
        };
        chunkDataArray.push(emptyChunkData);
      }

      const parsedChunkData = {
        ...chunk,
        boundingBox: {
          x: chunk.boundingBox.minx,
          y: chunk.boundingBox.miny,
          width: chunk.boundingBox.maxx - chunk.boundingBox.minx,
          height: chunk.boundingBox.maxy - chunk.boundingBox.miny,
        },
      };

      chunkDataArray.push(parsedChunkData);
  });
  return chunkDataArray;
}

const parseChunkData = async (chunkDataBlob: Blob): any[] => {
  const chunkDataFiles = await JSZip.loadAsync(chunkDataBlob);
  const chunkDataJsonText = await chunkDataFiles
    .file("chunkData.json")
    .async("text");
  const chunkDataJson = JSON.parse(chunkDataJsonText);

  const dataVersion = chunkDataJson.dataVersion;

  // Transform chunkDataJson to chunkDataArray
  const chunkDataArray = transformChunkData(chunkDataJson);

  const totalWidth = chunkDataJson.imageSize.width;
  const totalHeight = chunkDataJson.imageSize.height;
  const loadPromises = chunkDataArray.map(
    async (chunkData, id) => {

      const imageFile = chunkData.imageFile;
      const indexMapFile = chunkData.indexFile;
      const { x, y, width, height } = chunkData.boundingBox;

      let imageContent = null;
      if (imageFile !== "" && imageFile !== undefined) {
        imageContent = await chunkDataFiles.files[imageFile].async("blob")
      } else {
        imageContent = generateImage(x, y, width, height, totalWidth, totalHeight)
      }

      let indexMapContent = null;
      if (indexMapFile !== "" && indexMapFile !== undefined) {
        indexMapContent = await chunkDataFiles.files[indexMapFile].async("blob")
      } else {
        indexMapContent = generateIndexMap(totalWidth, totalHeight)
      }
      return [imageContent, indexMapContent];
    }
  );
  return [chunkDataArray, loadPromises]
}

const parseIndexMaps = (indexMaps: Blob[]): Promise<IndexData[]> => {
  const promises = indexMaps.map((blob, i) => {
    return blob.arrayBuffer().then(arrayBuffer => {
      const dataView = new DataView(arrayBuffer);
      const width = dataView.getUint16(0, true);
      const height = dataView.getUint16(2, true);
      const frameIndices: number[] = [];
      for (let i = 4; i < dataView.byteLength; i += 2) {
        frameIndices.push(dataView.getUint16(i, true));
      }
      return { width, height, frameIndices };
    });
  });

  return Promise.all(promises);
};

const generateImage = (
  imageStartX: number, 
  imageStartY: number, 
  imageEndX: number, 
  imageEndY: number, 
  width: number, 
  height: number
): Blob => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Failed to get canvas context');
  }

  const squareSize = 5;
  const totalRows = Math.ceil((imageEndY - imageStartY) / squareSize);
  const totalCols = Math.ceil((imageEndX - imageStartX) / squareSize);

  for (let row = 0; row < totalRows; row++) {
    for (let col = 0; col < totalCols; col++) {
      if ((row + col) % 2 === 0) {
        context.fillStyle = 'red';
        context.fillRect(
          imageStartX + col * squareSize, 
          imageStartY + row * squareSize, 
          squareSize, 
          squareSize
        );
      }
    }
  }

  const dataUrl = canvas.toDataURL();
  const byteString = atob(dataUrl.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  return new Blob([int8Array]);
}

const generateIndexMap = (width: number, height: number): Blob => {
  const buffer = new ArrayBuffer(4 + width * height * 2);
  const dataView = new DataView(buffer);
  dataView.setUint16(0, width, true);
  dataView.setUint16(2, height, true);
  for (let i = 0; i < width * height; i++) {
    dataView.setUint16(4 + i * 2, 0, true);
  }
  return new Blob([buffer]);
}

const parseData = async (chunkDataBlob: Blob) => {
  try {
    const [chunkDataArray, loadPromises, dataVersion] = await parseChunkData(chunkDataBlob);

    let results;
    try {
      results = await Promise.all(loadPromises);
    } catch (error) {
      console.error("Error during Promise.all:", error);
      throw error;
    }

    const parsedIndexMaps = await parseIndexMaps(results.slice(0, chunkDataArray.length).map((result) => result[1]));
    const data = {
      images: results.slice(0, chunkDataArray.length).map((result) => result[0]),
      indexMaps: parsedIndexMaps,
      chunkData: chunkDataArray, // use transformed chunkData
      dataVersion: dataVersion
    };
    return data;
  } catch (error) {
    console.error("Error in parseData:", error);
  }
};


export default parseData;
