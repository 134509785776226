import _ from 'lodash';

import { getChunkColor } from "../drawUtils";
import Chunk from "./chunk";
import ChunkManager from "./chunkManager";
import { screenToCanvas } from "../calculations";
import { Point, Direction, Rectangle, Line, Size } from '../types'; // Assuming Point class is in Point.ts
import { drawGrid, drawLineOnChunk, drawCameraFrustum, drawHelplines } from "../lineDrawing";

import { MoveSelectedChunkCommand } from "../commands/moveSelectedChunkCommand";
import { RotateSelectedChunkCommand } from "../commands/rotateSelectedChunkCommand";
import { SplitChunkCommand } from "../commands/splitChunkCommand";
import { DragChunkCommand } from "../commands/dragSelectedChunkCommand";
import { ChangeChunkFloorCommand } from "../commands/changeChunkFloorCommand"; 
import { SwapFloorsCommand } from "../commands/swapFloorsCommand";
import { AddHelpLineCommand } from '../commands/addHelpLineCommand';
import { RemoveHelpLineCommand } from '../commands/removeHelpLineCommand';
import { CopyHelpLinesToAllFloorsCommand } from '../commands/copyHelpLinesToAllFloorsCommand';

const MIN_ZOOM = 0.3;
const MAX_ZOOM = 2;

class ChunkEditor {
  chunkManager: ChunkManager;
  selectedChunk: Chunk | null;
  isPanning: boolean;
  isDragging: boolean;
  zoom: number;
  screenOffset: Point;
  panStart: Point;
  dragStart: Point;
  arkitData: any;
  currentFloor: number;
  selectedChunkOpacity: number;
  currentFrame: number;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D | null;
  helpLines: Record<number, Line[]>;
  lineStartPoint: Point | null;
  lineEndPoint: Point | null;
  dragChunkCommand: DragChunkCommand | null;
  selectedLineIndex: number | null;
  offlineCanvas: OffscreenCanvas | null;
  offlineCtx: OffscreenCanvasRenderingContext2D | null;
  opacityIndex: number  = 0;
  readonly opacityValues = [0.5, 0.05, 1];

  private static readonly ZOOM_PRECISION = 2;  // For 2 decimal places
  private isRedrawScheduled = false;
  private pendingRedrawArgs: any = null;

  constructor(chunkManager: ChunkManager, canvas: HTMLCanvasElement, arkitData: any) { 
    this.chunkManager = chunkManager;
    this.selectedChunk = null;
    this.isPanning = false;
    this.isDragging = false;
    this.zoom = 1;
    this.screenOffset = Point.zero();
    this.panStart = Point.zero()
    this.dragStart = Point.zero();
    this.currentFloor = 0;
    this.selectedChunkOpacity = 0.5;
    this.currentFrame = 0;
    this.canvas = canvas;
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.ctx = this.canvas.getContext("2d");

    this.arkitData = arkitData;    
    this.currentFloor = this.arkitData.frames[0].floorNumber;

    this.helpLines = {};
    this.lineStartPoint = null;
    this.lineEndPoint = null;
    this.dragChunkCommand = null;
    this.selectedLineIndex = null;
    this.offlineCanvas = new OffscreenCanvas(this.canvas.width, this.canvas.height);
    this.offlineCtx = this.offlineCanvas.getContext("2d");
  }

  async init() {
    const firstCameraPosition = new Point(
      this.arkitData.frames[0].imageCameraPosition[0],
      this.arkitData.frames[0].imageCameraPosition[1]
    );
    this.centerToPoint(firstCameraPosition);

    this.createCachedLines();
    this.forceRedraw();
  }

  resized() {
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.ctx = this.canvas.getContext("2d");
    this.offlineCanvas = new OffscreenCanvas(this.canvas.width, this.canvas.height);
    this.offlineCtx = this.offlineCanvas.getContext("2d");
    this.forceRedraw();
  }

  frameChanged(frameNumber: number) {
    this.currentFrame = frameNumber;
    if (this.selectedChunk) {
      const frameChunk = this.chunkManager.getChunkByFrame(this.currentFrame);
      if (this.selectedChunk !== frameChunk) {
        this.selectedChunk = frameChunk;
        this.currentFloor = frameChunk.floor
      }
    } else {
      const frameChunk = this.chunkManager.getChunkByFrame(this.currentFrame);
      this.currentFloor = frameChunk?.floor ?? this.currentFloor;
    }
    this.scheduleRedraw();
  }
  
  isMovingChunk() {
    return this.selectedChunk !== null;
  }

  panXBy(dx: number) {
    this.screenOffset = this.screenOffset.add(new Point(-dx, 0));
    this.redrawChunks();
  }

  panYBy(dy: number) {
    this.screenOffset = this.screenOffset.add(new Point(0, -dy));
    this.redrawChunks();
  }

  toggleChunkOpacity() {
    if (!this.selectedChunk) {
      return;
    }
    this.opacityIndex = (this.opacityIndex + 1) % this.opacityValues.length;
    this.selectedChunkOpacity = this.opacityValues[this.opacityIndex];
    this.redrawChunks();
  }

  moveSelectedChunk(dx: number, dy: number): MoveSelectedChunkCommand | null {
    if (this.isDragging) {
      return null;
    }
    const moveCommand = new MoveSelectedChunkCommand(this.chunkManager, this.selectedChunk, dx, dy);
    this.scheduleRedraw();
    return moveCommand;
  }

  rotateSelectedChunk(rotation: number): RotateSelectedChunkCommand | null {
    if (this.isDragging) {
      return null;
    }
    const rotateComamnd = new RotateSelectedChunkCommand(this.chunkManager, this.selectedChunk!, rotation);
    this.scheduleRedraw();
    return rotateComamnd;
  }

  splitChunk(): SplitChunkCommand | null {
    if (!this.selectedChunk || this.selectedChunk.frames[0].frameNumber === this.currentFrame || this.isDragging) {
      return null;
    }
    const splitCommand = new SplitChunkCommand(this.chunkManager, this.selectedChunk!, this.currentFrame);
    return splitCommand;
  }

  startPanning(startPoint: Point) {
    this.isPanning = true;
    this.panStart = startPoint.copy();
  }

  panWith(point: Point) {
    const delta = point.subtract(this.panStart)
    this.screenOffset = this.screenOffset.add(delta);
    this.panStart = point;
    this.scheduleRedraw();
  }

  stopPanning() {
    this.isPanning = false;
  }

  startDraggingChunk(screenPoint: Point) {
    if (!this.selectedChunk) {
      return;
    }
    const point = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
    if (this.selectedChunk.containsPoint(point)) {
      this.isDragging = true;
      this.dragStart = point;
      this.dragChunkCommand = new DragChunkCommand(this.chunkManager, this.selectedChunk, point, point);
    }
  }

  dragWith(screenPoint: Point) {
    if (!this.isDragging || !this.selectedChunk || !this.dragChunkCommand) {
      return;
    }

    const point = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
    const delta = point.subtract(this.dragStart);

    const movedChunks = this.chunkManager.getChunksAfter(this.selectedChunk);

    movedChunks.forEach((chunk) => {
      chunk.moveBy(delta.x, delta.y, false);
    });

    this.selectedChunk.moveBy(delta.x, delta.y, true);

    this.dragStart = point;
    this.dragChunkCommand.setDragEnd(point);
    this.scheduleRedraw();
  }

  stopDraggingChunk(): DragChunkCommand | null {
    const command = this.dragChunkCommand;
    if (this.dragChunkCommand) {
      // Undo "temporary" changes. Command execute will apply the final position change
      this.dragChunkCommand.undo();
      this.dragChunkCommand = null;
    }
    this.isDragging = false;
    return command;
  }

  selectFloor(floorIndex: number): number {
    if (floorIndex < 0 || floorIndex >= this.chunkManager.getFloorAmount()) {
      return this.currentFloor;
    }
    this.currentFloor = floorIndex;
    this.currentFrame = this.chunkManager.getAllChunksInFloor(this.currentFloor)[0].firstFrameNumber;
    this.deselectChunk();
    return this.currentFrame;
  }

  selectChunkDirection(direction: Direction): [number, Chunk | null] {
    if (this.selectedChunk === null) {
      let chunk = this.chunkManager.getChunkByFrame(this.currentFrame) || this.chunkManager.getChunk(0);
      this.selectChunk(chunk);
      return [this.currentFloor, this.selectedChunk];
    }
    const allChunks = this.chunkManager.getAllChunks();
    let chunkIndex = this.chunkManager.getIndexForChunk(this.selectedChunk);
    if (direction === Direction.Next) {
      chunkIndex = (chunkIndex + 1) % allChunks.length;
    } else if (direction === Direction.Prev) {
      chunkIndex = (chunkIndex - 1 + allChunks.length) % allChunks.length;
    }
    const chunk = this.chunkManager.getChunk(chunkIndex);
    this.selectChunk(chunk)
    return [this.currentFloor, this.selectedChunk];
  }

  selectChunkByCurrentFrame(): [number, Chunk | null] {
    const chunk = this.chunkManager.getChunkByFrame(this.currentFrame);
    const framePosition = this.arkitData.frames.find(
        (frame) => frame.frameNumber === this.currentFrame
    )?.imageCameraPosition;
    const framePoint = new Point(framePosition![0], framePosition![1]);
    this.centerToPoint(framePoint);
    this.selectedChunk = chunk;
    this.currentFloor = this.selectedChunk.floor;
    this.forceRedraw();
    return [this.currentFloor, this.selectedChunk];
  }

  selectChunk(chunk: Chunk) {
    this.selectedChunk = chunk;
    this.currentFloor = this.selectedChunk.floor;
    this.currentFrame = this.selectedChunk.firstFrameNumber;
    this.focusSelectedChunk();
  }

  selectNextChunk(): [number, Chunk | null] {
    return this.selectChunkDirection(Direction.Next);
  }

  deselectChunk() {
    this.selectedChunk = null;
  }

  moveSelectedChunkToFloor(floorIndex: number, moveConsecutiveChunks: boolean, createFloor: boolean): ChangeChunkFloorCommand | null {
    if (!this.selectedChunk) {
      return null;
    }
    const newFloor = floorIndex;
    const moveCommand = new ChangeChunkFloorCommand(this.chunkManager, this.selectedChunk, newFloor, moveConsecutiveChunks, createFloor);
    this.currentFloor = this.selectedChunk.floor;
    this.scheduleRedraw();
    return moveCommand;
  }

  moveSelectedChunkToUpperFloor(moveConsecutiveChunks: boolean, createFloor: boolean): ChangeChunkFloorCommand | null {
    const newFloor = this.currentFloor + 1;
    if (newFloor >= this.chunkManager.getFloorAmount() && !createFloor) {
      return null;
    }
    return this.moveSelectedChunkToFloor(newFloor, moveConsecutiveChunks, createFloor);
  }

  moveSelectedChunkToLowerFloor(moveConsecutiveChunks: boolean, createFloor: boolean): ChangeChunkFloorCommand | null {
    const newFloor = this.currentFloor - 1;
    if (newFloor < 0 && !createFloor) {
      return null;
    }
    return this.moveSelectedChunkToFloor(newFloor, moveConsecutiveChunks, createFloor);
  }

  swapSelectedChunkFloor(newFloor: number): SwapFloorsCommand {
    const swapCommand = new SwapFloorsCommand(this, this.currentFloor, newFloor);
    this.currentFloor = newFloor;
    this.currentFrame = this.chunkManager.getAllChunksInFloor(this.currentFloor)[0].firstFrameNumber;
    this.deselectChunk();
    return swapCommand;
  }

  swapSelectedChunkFloorWithUpperFloor(): SwapFloorsCommand | null {
    const newFloor = this.currentFloor + 1;
    if (newFloor >= this.chunkManager.getFloorAmount()) {
      return null;
    }
    return this.swapSelectedChunkFloor(newFloor);
  }

  swapSelectedChunkFloorWithBelowFloor(): SwapFloorsCommand | null {
    const newFloor = this.currentFloor - 1;
    if (newFloor < 0) {
      return null;
    }
    return this.swapSelectedChunkFloor(newFloor);
  }

  selectHelpLine(screenPoint: Point): boolean {
    const startPoint = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
    const thresholdDistance = 10; // Define "too close" distance, adjust as needed
    let closestLineIndex = null;
    let closestDistance = Infinity;

    if (!this.helpLines[this.currentFloor]) {
      return false;
    }

    // Find the closest line, if any
    this.helpLines[this.currentFloor].forEach((line, index) => {
      if (!line) {
        return;
      }
      const distance = line.distanceTo(startPoint);
      if (distance < closestDistance) {
          closestDistance = distance;
          closestLineIndex = index;
      }
    });
    // If the closest line is within the threshold distance, select it
    if (closestDistance <= thresholdDistance) {
        this.selectedLineIndex = closestLineIndex;
        this.redrawChunks();
        return true;
    }
    this.selectedLineIndex = null;
    this.redrawChunks()
    return false;
  }

  startLineDrawing(screenPoint: Point | null) {
    if (!screenPoint) {
      return;
    }
    const startPoint = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
    this.lineStartPoint = startPoint
    this.selectedLineIndex = null
  }

  private calculateEndPoint(screenPoint: Point, alignToAngles: boolean): Point {
    let endPoint = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
  
    if (alignToAngles) {
      const angle = Math.atan2(endPoint.y - this.lineStartPoint.y, endPoint.x - this.lineStartPoint.x);
      const roundedAngle = Point.round(Math.round(angle / (Math.PI / 20)) * (Math.PI / 20));
      const distance = this.lineStartPoint.distanceTo(endPoint);
      
      return new Point(
        this.lineStartPoint.x + distance * Math.cos(roundedAngle),
        this.lineStartPoint.y + distance * Math.sin(roundedAngle)
      );
    }
  
    return endPoint;
  }

  setCurrentEndPoint(screenPoint: Point, alignToAngles: boolean) {
    if (!this.lineStartPoint) {
      return;
    }
    this.lineEndPoint = this.calculateEndPoint(screenPoint, alignToAngles);
    this.redrawChunks();
  }

  isLineDrawing() {
    return this.lineStartPoint !== null;
  }

  continueLineDrawing(screenPoint: Point | null, alignToAngles: boolean): AddHelpLineCommand | null {
    if (!this.lineStartPoint || !screenPoint) {
      return null;
    }
    const endPoint = this.calculateEndPoint(screenPoint, alignToAngles);
    const addHelpLineCommand = this.addHelpLine(new Line(this.lineStartPoint, endPoint));
    this.lineStartPoint = endPoint.copy();
    this.redrawChunks();
    return addHelpLineCommand;
  }

  cancelLineDrawing() {
    this.selectedLineIndex = null;
    this.lineStartPoint = null;
    this.redrawChunks();
  }

  stopLineDrawing(screenPoint: Point, alignToAngles: boolean): AddHelpLineCommand | null {
    if (!this.lineStartPoint) {
      return null;
    }
    this.selectedLineIndex = null;
    const endPoint = this.calculateEndPoint(screenPoint, alignToAngles);
    const helpLine = new Line(this.lineStartPoint, endPoint);

    // Check that if end point is close to the start point, don't add the line
    if (helpLine.startPoint.distanceTo(helpLine.endPoint) < 10) {
      this.lineStartPoint = null;
      return null;
    }

    const addHelpLineCommand = this.addHelpLine(helpLine);
    this.lineStartPoint = null;
    return addHelpLineCommand;
  }

  removeSelectedHelpLine(): RemoveHelpLineCommand | null {
    if (this.selectedLineIndex === null || !this.helpLines[this.currentFloor]) {
      return null;
    }
    const removeHelpLineCommand = new RemoveHelpLineCommand(this, this.selectedLineIndex, this.currentFloor);
    this.selectedLineIndex = null;
    this.scheduleRedraw();
    return removeHelpLineCommand;
  }

  addHelpLine(helpLine: Line): AddHelpLineCommand {
    const addHelpLineComamand = new AddHelpLineCommand(this, helpLine, this.currentFloor);
    this.scheduleRedraw();
    return addHelpLineComamand;
  }

  copySelectedLineToAllFloors(): CopyHelpLinesToAllFloorsCommand | null {
    if (this.selectedLineIndex === null || !this.helpLines[this.currentFloor]) {
      return null;
    }
    const line = this.helpLines[this.currentFloor][this.selectedLineIndex];
    const copyCommand = new CopyHelpLinesToAllFloorsCommand(this, line, this.currentFloor);
    this.scheduleRedraw();
    return copyCommand;
  }
 
  selectPrevChunk(): [number, Chunk | null] {
    return this.selectChunkDirection(Direction.Prev);
  }

  selectPathPoint(screenPoint: Point): number {
    const zoomedPoint = screenToCanvas(
      screenPoint,
      this.screenOffset,
      this.zoom
    );

    let closestFrame = -1;
    let closestDistanceSquared = Number.MAX_VALUE;
    this.arkitData.frames.forEach((frame) => {
      const chunk = this.chunkManager.getChunkByFrame(frame.frameNumber);
      if (!chunk) {
        return;
      }

      if (chunk.floor !== this.currentFloor) {
        return;
      }

      const imageCameraPosition = new Point(frame.imageCameraPosition[0], frame.imageCameraPosition[1]);
      const rotatedPoint = imageCameraPosition.rotate(chunk.globalRotation);
      const cameraPosition = rotatedPoint.add(chunk.position);
      const delta = zoomedPoint.subtract(cameraPosition.multiply(this.zoom));

      const distanceSquared = delta.x * delta.x + delta.y * delta.y;  
      if (distanceSquared < closestDistanceSquared) {
        closestDistanceSquared = distanceSquared;
        closestFrame = frame.frameNumber;
      }
    }); 
    return closestFrame;
  }


  zoomToCenter = (factor: number) => {
    const newZoom = Math.min(Math.max(MIN_ZOOM, this.zoom + factor), MAX_ZOOM);
    const screenCenterPoint = new Point(this.canvas.width / 2, this.canvas.height / 2);
    this.zoomToPointAndCenter(newZoom, screenCenterPoint);
  };

  zoomToPoint(factor: number, screenPoint: Point) {
    const newZoom = ChunkEditor.roundZoom(
      Math.min(Math.max(MIN_ZOOM, this.zoom + factor), MAX_ZOOM)
    );
    const screenMiddle = new Point(this.canvas.width / 2, this.canvas.height / 2);
    const zoomDelta = newZoom - this.zoom;
    const screenDelta = screenPoint.subtract(screenMiddle).multiply(zoomDelta);
    this.zoomToPointAndCenter(newZoom, screenMiddle.add(screenDelta));
    
    this.zoom = newZoom;  // Using rounded value
    this.redrawChunks();
  }

  zoomToPointAndCenter = (newZoom: number, screenPoint: Point) => {
    const point = screenToCanvas(screenPoint, this.screenOffset, this.zoom * this.zoom);
    this.zoom = ChunkEditor.roundZoom(newZoom);
    this.centerToPoint(point);
    this.redrawChunks();
  };

  centerToPoint(point: Point) {
    const zoomSquared = ChunkEditor.roundZoom(this.zoom * this.zoom);
    this.screenOffset = new Point(
      this.canvas.width / 2 - point.x * zoomSquared,
      this.canvas.height / 2 - point.y * zoomSquared
    );
  }

  focusSelectedChunk() {
    if (!this.selectedChunk) {
      return;
    }
   
    const chunk = this.selectedChunk;

    const startPosition = chunk.pathStartPosition;
    this.centerToPoint(startPosition)

    this.redrawChunks();
  }

  createCachedLines() {
    const offlineCanvas = new OffscreenCanvas(this.canvas.width, this.canvas.height);
    const offlineCtx = offlineCanvas.getContext("2d");
    for (let zoom = MIN_ZOOM; zoom <= MAX_ZOOM; zoom += 0.5) {
      const newZoom = ChunkEditor.roundZoom(zoom);
      const zoomSquared = ChunkEditor.roundZoom(newZoom * newZoom);
      drawGrid(offlineCtx!, this.canvas.width, this.canvas.height, this.screenOffset, zoomSquared);
    }
    this.ctx?.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  redrawChunks() {
    // Clear the original canvas
    this.ctx?.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Create an off-screen canvas to draw the grid and chunks on
    this.offlineCtx?.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Draw the grid on the unscaled canvas
    const zoomSquared = ChunkEditor.roundZoom(this.zoom * this.zoom);
    drawGrid(this.offlineCtx!, this.canvas.width, this.canvas.height, this.screenOffset, zoomSquared);

    // Apply pan and zoom transformations
    this.offlineCtx?.save();
    this.offlineCtx?.translate(this.screenOffset.x, this.screenOffset.y);
    this.offlineCtx?.scale(this.zoom, this.zoom);

    let floorChunks = this.chunkManager.getAllChunksInFloor(this.currentFloor);

    const selectedChunkCurrentFloorIndex = floorChunks.indexOf(
      this.selectedChunk
    );
    if (selectedChunkCurrentFloorIndex !== -1) {
      // Remove the selected chunk from its current position
      let selectedChunk = floorChunks.splice(
        selectedChunkCurrentFloorIndex,
        1
      )[0];

      // Add the selected chunk back to the end of the array
      floorChunks.push(selectedChunk);
    }
  
    // First loop to draw all the images
    floorChunks.forEach((chunk) => {
      this.offlineCtx?.save();
      this.offlineCtx?.translate(
        (chunk.position.x + chunk.rotationPoint.x) * this.zoom,
        (chunk.position.y + chunk.rotationPoint.y) * this.zoom
      );

      // Rotate the context
      this.offlineCtx?.rotate((chunk.globalRotation * Math.PI) / 180);

      // Translate back
      this.offlineCtx?.translate(
        -chunk.rotationPoint.x * this.zoom,
        -chunk.rotationPoint.y * this.zoom
      );

      // Draw the image
      if (this.selectedChunk) {
        if (this.chunkManager.getIndexForChunk(chunk) < this.chunkManager.getIndexForChunk(this.selectedChunk)) {
          if (this.offlineCtx?.globalAlpha !== 1.0) {
            this.offlineCtx.globalAlpha = 1.0;
          }
        } else if (this.chunkManager.getIndexForChunk(chunk) === this.chunkManager.getIndexForChunk(this.selectedChunk)) {
          if (this.offlineCtx?.globalAlpha !== this.selectedChunkOpacity) {
            this.offlineCtx.globalAlpha = this.selectedChunkOpacity;
          }
        } else {
          if (this.offlineCtx?.globalAlpha !== 0.1) {
            this.offlineCtx.globalAlpha = 0.1;
          }
        }
      } else {
        if (this.offlineCtx?.globalAlpha !== 1.0) {
          this.offlineCtx.globalAlpha = 1.0;
        }
      }
      // Draw the off-screen canvas onto the main canvas
      this.offlineCtx?.drawImage(
        chunk.canvas,
        chunk.chunkBoundaries.startPoint.x * this.zoom,
        chunk.chunkBoundaries.startPoint.y * this.zoom,
        chunk.chunkBoundaries.size.width * this.zoom,
        chunk.chunkBoundaries.size.height * this.zoom
      );
      this.offlineCtx?.restore();
    });

    // Second loop to draw all the lines
    floorChunks.forEach((chunk) => {
      this.offlineCtx?.save();

      this.offlineCtx?.translate(chunk.position.x * this.zoom, chunk.position.y * this.zoom);

      this.offlineCtx?.translate(
        chunk.rotationPoint.x * this.zoom,
        chunk.rotationPoint.y * this.zoom
      );

      // Rotate the context
      this.offlineCtx?.rotate((chunk.globalRotation * Math.PI) / 180);

      // Translate back
      this.offlineCtx?.translate(
        -chunk.rotationPoint.x * this.zoom,
        -chunk.rotationPoint.y * this.zoom
      );
      drawLineOnChunk(
        this.offlineCtx!,
        this.zoom,
        chunk,
        getChunkColor(this.chunkManager.getIndexForChunk(chunk)),
        chunk === this.selectedChunk,
      );
      this.offlineCtx?.restore();
    });

    if (this.lineStartPoint && this.lineEndPoint) {
      const line = new Line(this.lineStartPoint, this.lineEndPoint)
      drawHelplines(this.offlineCtx!, [line], this.selectedLineIndex, this.zoom);
    }
    drawHelplines(this.offlineCtx!, this.helpLines[this.currentFloor], this.selectedLineIndex, this.zoom);

    const frameChunk = this.chunkManager.getChunkByFrame(this.currentFrame);
    if (frameChunk) {
      drawCameraFrustum(this.offlineCtx!, this.zoom, frameChunk!, this.currentFrame);
    }

    this.offlineCtx?.restore();
    // Draw the off-screen canvas onto the main canvas
    this.ctx?.drawImage(this.offlineCanvas, 0, 0);
  }

  private static roundZoom(value: number): number {
    return Number(value.toFixed(ChunkEditor.ZOOM_PRECISION));
  }

  private scheduleRedraw() {
    if (!this.isRedrawScheduled) {
      this.isRedrawScheduled = true;
      requestAnimationFrame(() => {
        this.redrawChunks();
        this.isRedrawScheduled = false;
        
        // Check if another redraw was requested during this frame
        if (this.pendingRedrawArgs) {
          const args = this.pendingRedrawArgs;
          this.pendingRedrawArgs = null;
          this.scheduleRedraw();
        }
      });
    } else {
      // If a redraw is already scheduled, store the latest arguments
      this.pendingRedrawArgs = {};
    }
  }

  private forceRedraw() {
    this.isRedrawScheduled = false;
    this.pendingRedrawArgs = null;
    this.redrawChunks();
  }
}
export default ChunkEditor;
