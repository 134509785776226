import { Command } from "./command";
import ChunkManager from "../chunks/chunkManager";
import Chunk from "../chunks/chunk";
import { FloorEditor } from "../floorEditor";

export class FinishFloorEditingCommand implements Command {
  private originalChunks: Chunk[];
  private originalFloors: number[];

  constructor(private chunkManager: ChunkManager, private arkitData: any, private floorEditor: FloorEditor) {
    // Store a deep copy of the original chunks state
    this.originalChunks = this.chunkManager.getAllChunks().map(chunk => chunk.clone());
    this.originalFloors = arkitData.frames.map((frame: any) => frame.floorNumber);
    this.floorEditor = floorEditor;
  }

  private ensureConsecutiveFloors() {
    // Get all unique floor numbers and sort them
    const uniqueFloors = Array.from(new Set<number>(
      this.arkitData.frames.map((frame: any) => frame.floorNumber)
    )).sort((a, b) => a - b);

    // Create a mapping of old floor numbers to new consecutive ones
    const floorMapping = new Map<number, number>();
    uniqueFloors.forEach((floor, index) => {
      floorMapping.set(floor, index);
    });

    // Update all frames with new consecutive floor numbers
    this.arkitData.frames.forEach((frame: any) => {
      frame.floorNumber = floorMapping.get(frame.floorNumber) || 0;
    });
    
  }

  execute() {
    this.floorEditor.isEditingStarted = false;
    
    // Ensure floor numbers are consecutive
    this.ensureConsecutiveFloors();
    
    let currentChunk = this.chunkManager.getChunkByFrame(0)!;
    let currentFloor = currentChunk.floor;
    for (let i = 0; i < this.arkitData.frames.length; i++) {
      const arFloorNumber = this.arkitData.frames[i].floorNumber
      const frameNumber = this.arkitData.frames[i].frameNumber
      
      if (arFloorNumber !== currentFloor && frameNumber !== currentChunk.firstFrameNumber) {
        currentChunk = this.chunkManager.splitChunk(currentChunk, frameNumber);
        currentChunk.floor = arFloorNumber;
        currentFloor = arFloorNumber;
      } else if (arFloorNumber !== currentFloor) {
        currentChunk.floor = arFloorNumber;
        currentFloor = arFloorNumber;
      }

      if (frameNumber > currentChunk.lastFrameNumber) {
        currentChunk = this.chunkManager.getChunkByFrame(frameNumber)!;
        currentFloor = currentChunk.floor;
        if (arFloorNumber !== currentFloor) {
          currentChunk.floor = arFloorNumber;
          currentFloor = arFloorNumber;
        }
      }
    }
  }

  undo() {
    // Restore the original chunks state completely
    this.floorEditor.isEditingStarted = true;
    this.chunkManager.restoreFromChunks(this.originalChunks);
    this.arkitData.frames.forEach((frame: any, index: number) => {
      frame.floorNumber = this.originalFloors[index];
    });

  }
} 