// MoveSelectedChunkCommand.ts
import { Command } from './command';
import { ChunkManager } from './chunkManager';
import { Chunk } from './chunk';

export class MoveSelectedChunkCommand implements Command {
  private chunkManager: ChunkManager;
  private selectedChunk: Chunk | null;
  private dx: number;
  private dy: number;
  private movedChunks: Chunk[] = [];

  constructor(chunkManager: ChunkManager, selectedChunk: Chunk, dx: number, dy: number) {
    this.chunkManager = chunkManager;
    this.selectedChunk = selectedChunk;
    this.dx = dx;
    this.dy = dy;
  }

  execute() {
    if (this.selectedChunk) {
      this.selectedChunk.moveBy(this.dx, this.dy, true);
      this.movedChunks = this.chunkManager.getChunksAfter(this.selectedChunk);

      this.movedChunks.forEach((chunk) => {
        chunk.moveBy(this.dx, this.dy, false);
      });
    }
  }

  undo() {
    if (this.selectedChunk) {
      this.selectedChunk.moveBy(-this.dx, -this.dy, true);

      this.movedChunks.forEach((chunk) => {
        chunk.moveBy(-this.dx, -this.dy, false);
      });
    }
  }
}