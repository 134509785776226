const createBlobObjectURL = (data: any, mimeType: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const blob = new Blob([data], { type: mimeType });
      const objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
      resolve(objectUrl);
    } catch (error) {
      reject(error);
    }
  });
};

export default createBlobObjectURL;