export interface FloorGroupEntry {
    index: number
    floor: number
}

export function createFloorGroups(frames: any[]): FloorGroupEntry[] {
    if (frames.length === 0) {
        return []
    }
    let currentFloor = frames[0].floorNumber
    const floorGroups: FloorGroupEntry[] = [{ index: 0, floor: currentFloor }]
    frames.forEach((frame) => {
        if (frame.floorNumber !== currentFloor) {
            currentFloor = frame.floorNumber
            floorGroups.push({index: frame.frameNumber, floor: currentFloor })
        }
    });
    return floorGroups;
};

export function floorGroupingDifference(oldGroups: FloorGroupEntry[], newGroups: FloorGroupEntry[], frameAmount: number): Record<number, number> {
    const diff: Record<number, number> = {};
    let oldIndex = 0;
    let newIndex = 0;

    for (let i = 0; i < frameAmount; i++) {
        while (oldIndex < oldGroups.length - 1 && i >= oldGroups[oldIndex + 1].index) {
            oldIndex++;
        }
        while (newIndex < newGroups.length - 1 && i >= newGroups[newIndex + 1].index) {
            newIndex++;
        }

        const oldFloor = oldGroups[oldIndex].floor;
        const newFloor = newGroups[newIndex].floor;

        const difference = newFloor - oldFloor;
        if (difference !== 0) {
            diff[i] = difference;
        }
    }

    return diff;
}