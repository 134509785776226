import { ChunkBoundaries } from "./interfaces";

export class Rectangle {
  startPoint: Point;
  size: Size

  static fromArray(boundaries: ChunkBoundaries): Rectangle {
      return new Rectangle(new Point(boundaries.x, boundaries.y), boundaries.width, boundaries.height);
  }

  constructor(startPoint: Point, width: number, height: number) {
      this.startPoint = startPoint.copy();
      this.size = new Size(width, height);
  }
}

export class Size {
  constructor(public width: number, public height: number) {
      this.width = width;
      this.height = height;
  }
}

export class Point {
  private static readonly PRECISION = 5;
  private _x: number;
  private _y: number;

  static fromArray(arr: number[]): Point {
    if (arr.length !== 2) {
      throw new Error('Array must contain exactly two elements');
    }
    return new Point(arr[0], arr[1]);
  }
  
  static zero(): Point {
    return new Point(0, 0);
  }

  constructor(x: number, y: number) {
    this._x = Point.round(x);
    this._y = Point.round(y);
  }

  get x(): number {
    return this._x;
  }

  get y(): number {
    return this._y;
  }

  copy(): Point {
    return new Point(this.x, this.y);
  }

  add(other: Point): Point {
    return new Point(
      Point.round(this.x + other.x),
      Point.round(this.y + other.y)
    );
  }

  subtract(other: Point): Point {
    return new Point(
      Point.round(this.x - other.x),
      Point.round(this.y - other.y)
    );
  }

  divide(value: number): Point {
    return new Point(
      Point.round(this.x / value),
      Point.round(this.y / value)
    );
  }

  multiply(value: number): Point {
    return new Point(
      Point.round(this.x * value),
      Point.round(this.y * value)
    );
  }

  rotate(angle: number): Point {
    const rad = angle * (Math.PI / 180);
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);
    return new Point(
      Point.round(this.x * cos - this.y * sin),
      Point.round(this.x * sin + this.y * cos)
    );
  }

  distanceTo(other: Point): number {
    const dx = Point.round(this.x - other.x);
    const dy = Point.round(this.y - other.y);
    return Point.round(Math.sqrt(dx * dx + dy * dy));
  }

  toString(): string {
    return `(${this.x.toFixed(Point.PRECISION)}, ${this.y.toFixed(Point.PRECISION)})`;
  }

  static round(value: number): number {
    return Number(value.toFixed(Point.PRECISION));
  }
}

export class Line {
  constructor(public startPoint: Point, public endPoint: Point) {
    this.startPoint = startPoint;
    this.endPoint = endPoint;
  }

  distanceTo(point: Point): number {
    const a = this.startPoint.distanceTo(point);
    const b = this.endPoint.distanceTo(point);
    const c = this.startPoint.distanceTo(this.endPoint);

    if (a >= b + c) {
      return b;
    }

    if (b >= a + c) {
      return a;
    }

    const p = (a + b + c) / 2;
    const area = Math.sqrt(p * (p - a) * (p - b) * (p - c));
    return 2 * area / c;
  }

  toString(): string {
    return `${this.startPoint.toString()} -> ${this.endPoint.toString()}`;
  }

  copy(): Line {
    return new Line(this.startPoint.copy(), this.endPoint.copy());
  }
}

export enum Direction {
  Prev = "prev",
  Next = "next",
};
  