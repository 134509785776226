// RotateSelectedChunkCommand.ts
import { Command } from './command';
import { ChunkManager } from './chunkManager';
import { Chunk } from './chunk';
import { Point } from './point';

export class RotateSelectedChunkCommand implements Command {
  private chunkManager: ChunkManager;
  private selectedChunk: Chunk | null;
  private rotation: number;
  private rotationPoint: Point | null;
  private rotatedChunks: Chunk[] = [];

  constructor(chunkManager: ChunkManager, selectedChunk: Chunk, rotation: number) {
    this.chunkManager = chunkManager;
    this.selectedChunk = selectedChunk
    this.rotation = rotation;
    this.rotationPoint = this.selectedChunk?.pathStartPosition;
  }

  execute() {
    if (this.selectedChunk && this.rotationPoint) {
      this.rotatedChunks = this.chunkManager.getChunksAfter(this.selectedChunk);

      this.rotatedChunks.forEach((chunk) => {
        chunk.rotateAboutPoint(this.rotationPoint, this.rotation, false);
      });

      this.selectedChunk.rotateAboutPoint(this.rotationPoint, this.rotation, true);
    }
  }

  undo() {
    if (this.selectedChunk && this.rotationPoint) {
      this.rotatedChunks.forEach((chunk) => {
        chunk.rotateAboutPoint(this.rotationPoint, -this.rotation, false);
      });

      this.selectedChunk.rotateAboutPoint(this.rotationPoint, -this.rotation, true);
    }
  }
}