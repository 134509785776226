import { Command } from "./command";
import { FloorEditor } from "../floorEditor";
export class InitializeFloorEditingCommand implements Command {
  private originalFloors: number[] = [];
  private arkitData: any;
  private floorEditor: FloorEditor;

  constructor(arkitData: any, floorEditor: FloorEditor) {
    this.arkitData = arkitData;
    this.originalFloors = this.arkitData.frames.map((frame: any) => frame.floorNumber);
    this.floorEditor = floorEditor;
  }

  execute() {
    this.arkitData.frames.forEach((frame: any) => {
      frame.floorNumber = -1;
    });
    this.floorEditor.isEditingStarted = true;
  }

  undo() {
    this.arkitData.frames.forEach((frame: any, index: number) => {
      frame.floorNumber = this.originalFloors[index];
    });
    this.floorEditor.isEditingStarted = false;
  }
}
