import { Point } from "./types";

const screenToCanvas = (screenPoint:Point, screenOffset:Point, zoom:number): Point => {
  const zoomed = screenPoint.subtract(screenOffset).divide(zoom);
  return zoomed
};

const canvasToScreen = (point: Point, offset: Point, zoom: number): Point => {
  const unzoomed = point.multiply(zoom).add(offset);
  return unzoomed;
};

export { screenToCanvas, canvasToScreen };
