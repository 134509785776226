import { Command } from "./command";
import { Frame } from "../interfaces";
import { FloorEditor } from "../floorEditor";
export class CancelFloorEditingCommand implements Command {
  private frames: Frame[];
  private originalFloors: number[];
  private currentFloors: number[];
  private floorEditor: FloorEditor;
  constructor(frames: Frame[], originalFloors: number[], floorEditor: FloorEditor) {
    this.frames = frames;
    this.originalFloors = originalFloors;
    this.currentFloors = frames.map((frame: Frame) => frame.floorNumber);
    this.floorEditor = floorEditor;
  }

  execute() {
    this.frames.forEach((frame: Frame, index: number) => {
      frame.floorNumber = this.originalFloors[index];
    });
    this.floorEditor.isEditingStarted = false;
  }

  undo() {
    this.frames.forEach((frame: Frame, index: number) => {
      frame.floorNumber = this.currentFloors[index];
    });
    this.floorEditor.isEditingStarted = true;
  }
}