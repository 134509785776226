import Chunk from "./chunk";

class ChunkManager {
  private chunks: Chunk[]

  constructor() {
    this.chunks = [];
  }

  getFloorAmount(): number {
    return this.chunks.reduce((maxFloor, chunk) => {
      return Math.max(maxFloor, chunk.floor);
    }, 0) + 1;
  }

  getChunkAtIndex(index: number) {
    return this.chunks[index];
  }

  addChunk(chunk: Chunk) {
    this.chunks.push(chunk);
  }

  removeChunk(chunk: Chunk) {
    const index = this.chunks.indexOf(chunk);
    if (index === -1) {
      return;
    }
    this.chunks.splice(index, 1);
  }

  getChunkByFrame(frameNumber: number): Chunk | null {
    // Sort chunks by firstFrameNumber to ensure proper ordering
    // (This could be optimized if chunks are always added in order)
    const sortedChunks = [...this.chunks].sort((a, b) => a.firstFrameNumber - b.firstFrameNumber);
    
    for (let i = 0; i < sortedChunks.length - 1; i++) {
      if (
        frameNumber >= sortedChunks[i].firstFrameNumber &&
        frameNumber < sortedChunks[i + 1].firstFrameNumber
      ) {
        return sortedChunks[i];
      }
    }
    
    // If frameNumber is greater than the last firstFrameNumber, return the last chunk
    if (this.chunks.length > 0 && frameNumber >= sortedChunks[sortedChunks.length - 1].firstFrameNumber) {
      return sortedChunks[sortedChunks.length - 1];
    }
    
    // If no chunk is found, return null
    return null;
  }

  getAllChunks(): Chunk[] {
    return this.chunks;
  }

  getChunk(index: number): Chunk {
    return this.chunks[index];
  }

  getIndexForChunk(chunk: Chunk | null): number | null {
    if (!chunk) {
      return null;
    }
    return this.chunks.indexOf(chunk);
  }

  getChunksAfter(chunk: Chunk | null): Chunk[] {
    if (!chunk) {
      return this.chunks;
    }
    const index = this.chunks.indexOf(chunk);
    return this.chunks.slice(index + 1);
  }

  getLastFrame(): number {
    return this.chunks[this.chunks.length - 1].lastFrameNumber;
  }

  getChunksAfterInSameFloor(chunk: Chunk): Chunk[] {
    const index = this.chunks.indexOf(chunk);
    const floor = chunk.floor;
    const chunks = this.chunks.slice(index + 1);
    return chunks.filter((chunk) => chunk.floor === floor);
  }

  getAllChunksInFloor(floorNumber: number): Chunk[] {
    return this.chunks.filter((chunk) => chunk.floor === floorNumber);
  }

  splitChunk(chunk: Chunk, frameNumber: number): Chunk {
    const index: number = this.getIndexForChunk(chunk);
    const newChunk: Chunk = chunk.split(frameNumber);

    this.chunks.splice(index + 1, 0, newChunk);
    
    return newChunk;
  }

  restoreFromChunks(chunks: Chunk[]) {
    // Clear the current chunks
    this.chunks = [];
    
    // Add each chunk from the provided array
    chunks.forEach(chunk => {
      this.chunks.push(chunk.clone()); // Make a copy to avoid reference issues
    });
  }

  resetFloors() {
    this.chunks.forEach((chunk) => {
      chunk.floor = -1;
    });
  }

  getFrames(): any[] {
    return this.chunks.flatMap((chunk) => chunk.getFrames());
  }
}

export default ChunkManager;
