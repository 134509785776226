import Cookie from 'js-cookie';
import _ from 'lodash';

import ChunkEditor from './chunks/chunkEditor';
import ChunkManager from './chunks/chunkManager';
import Chunk from './chunks/chunk';
import VideoManager from './videoManager';
import { FileUploader } from './fileUploader';
import API from './networking/api';
import parseData from './dataParser';
import { Point, Rectangle } from './types.js';
import BlobObjectURL from './networking/blobObjectURL.js';
import { isLineDrawingKey, isMoveKey, KeyboardShortcut, isChunkSelectionKey, isFloorEditingKey, isDigitKey, isDigitKeyCode, getKeyboardShortcutDescriptions, isChunkOpacityKey } from './keyboard.js';
import { isVersionOrLater, getMousePosition, throttle } from "./utils";
import { createFloorGroups, floorGroupingDifference } from "./floorGrouping";
import { rotateArkitData } from "./dataCalculator";
import { showLoadingModal,
   hideLoadingModal, 
   hideCompleteModal, 
   showCompleteModal,
   updateInfobox, 
   toggleVideoVisibility, 
   updateDebugPanel,
   updateVideoPlayButton,
   updateSpeedMultiplier,
   showKeyboardShortcuts,
   updateVideoFollowCameraButton } from './ui.js';
import { FloorEditor } from './floorEditor';
import { CommandHistory } from './commands/commandHistory';
import { FloorGroupEntry } from "./floorGrouping";

enum EditorMode {
  DEFAULT = 0,
  CHUNK_EDITING = 1,
  LINE_DRAWING = 2,
  FLOOR_EDITING = 3
}

const EDITOR_MODE_CONFIG = {
  [EditorMode.DEFAULT]: {
    label: "",
    color: "#1E4357"
  },
  [EditorMode.CHUNK_EDITING]: {
    label: "Edit mode",
    color: "#CD5C5C"
  },
  [EditorMode.LINE_DRAWING]: {
    label: "Line drawing mode",
    color: "#81C784"
  },
  [EditorMode.FLOOR_EDITING]: {
    label: "Floor editing",
    color: "#EFC050"
  }
} as const;

export default class FixTool {
  private document: Document;
  private window: Window & typeof globalThis;
  private api: API;
  private chunkManager: ChunkManager;
  private userId: string | null = null;
  private ticketId: string | null = null;
  private videoManager: VideoManager | null = null;
  private arkitData: any;
  private chunkEditor!: ChunkEditor;
  private initialized = false;
  private currentFrame: number = 0;
  private cameraFollowing: boolean = false;
  private canvas: HTMLCanvasElement | null = null;
  private mousePoint: Point | null = null;
  private floorEditor: FloorEditor | null = null;
  private commandHistory: CommandHistory = new CommandHistory();
  private floorGroupings: FloorGroupEntry[] = [];

  currentMode: EditorMode = EditorMode.DEFAULT;
  rightMouseDown: boolean = false;
  leftMouseDown: boolean = false;


  constructor(window: Window & typeof globalThis) {
    this.window = window;
    this.document = this.window.document;
    this.api = API.productionAPI;
    this.chunkManager = new ChunkManager();
    this.canvas = null;
  }

  async init() {
    this.userId = await this.login();
    const [arkitData, videoObjectUrl, chunkDataFile] = await this.getFixCaseData();    
    const videoElement = this.document.getElementById('video') as HTMLVideoElement;
    this.canvas = this.document.getElementById('canvas') as HTMLCanvasElement;
    this.videoManager = new VideoManager(videoElement, videoObjectUrl, arkitData);
    this.arkitData = arkitData;
    const { images, chunkData, indexMaps, dataVersion } = await parseData(chunkDataFile)!;

    // Backwards compatibility for older data version
    if (!isVersionOrLater(dataVersion, "2.0")) {
      chunkData.forEach((chunkData: any, i: number) => {
        const oldIndexMap =   indexMaps[i];
        const newIndexMap = {
          width: chunkData.boundingBox.width,
          height: chunkData.boundingBox.height,
          frameIndices: new Array(chunkData.boundingBox.width * chunkData.boundingBox.height, 0)
        };
        // Copy the relevant portion of the old index map to the new one
        for (let y = 0; y < chunkData.boundingBox.height; y++) {
          for (let x = 0; x < chunkData.boundingBox.width; x++) {
            const oldX = x + chunkData.boundingBox.x;
            const oldY = y + chunkData.boundingBox.y;
            const oldIndex = oldY * oldIndexMap.width + oldX;
            const newIndex = y * newIndexMap.width + x;
            newIndexMap.frameIndices[newIndex] = oldIndexMap.frameIndices[oldIndex];
          }
        }
        indexMaps[i] = newIndexMap;
      })
    }

    await this.loadChunkImages(images, chunkData, indexMaps, this.arkitData, dataVersion);

    this.chunkEditor = new ChunkEditor(this.chunkManager, this.canvas, arkitData);
    this.floorEditor = new FloorEditor(this.chunkManager, this.arkitData, );
    await this.chunkEditor.init();
    this.initialized = true; 
    this.attachEventListeners();
    this.changeModeTo(EditorMode.DEFAULT);
    updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), null, this.chunkEditor?.zoom);
  
    // tODO
    //this.updateDebugPanel(this.chunkManager.getAllChunks(), this.chunkEditor?.selectedChunk);
    this.updateVideo(0);

    const videoContainer = this.document.getElementById('video-container');
    if (videoContainer) {
      videoContainer.style.display = 'block';
    }
  }

  private ensureInitialized() {
    if (!this.initialized) {
      throw new Error('FixTool must be initialized before using this method');
    }
  }

  changeModeTo(mode: EditorMode) {
    this.ensureInitialized();
    const config = EDITOR_MODE_CONFIG[mode];
    const modeIndicator = this.document.getElementById("mode-indicator");
    if (modeIndicator) {
      modeIndicator.textContent = config.label;
    }

    const topBar = this.document.getElementById("ui");
    if (topBar) {
      topBar.style.setProperty('background-color', config.color);
    }

    if (mode === EditorMode.DEFAULT) {
      if (this.canvas) {
        this.canvas.style.cursor = 'default';
      }
      this.chunkEditor.cancelLineDrawing();
      this.chunkEditor.deselectChunk();

      const command = this.floorEditor?.stopEditing();
      if (command) {
        this.commandHistory.execute(command);
        const chunk = this.chunkEditor.chunkManager.getChunkByFrame(this.chunkEditor.currentFrame);
        if (chunk) {
          this.chunkEditor.currentFloor = chunk.floor;
          this.centerToCamera();
        }      
      }
    }

    if (mode !== EditorMode.LINE_DRAWING) {
      this.chunkEditor.cancelLineDrawing();
    }

    this.currentMode = mode;
    //  updateDebugPanel(this.chunkManager.getAllChunks(), this.chunkEditor?.selectedChunk);
    updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
    this.updateVideo(this.chunkEditor?.currentFrame);
    this.chunkEditor.redrawChunks();
  } 

  attachEventListeners = () => {
    this.window.addEventListener('resize', throttle(() => {
        this.chunkEditor?.resized();
    }, 50));

    this.attachKeyboardListeners();

    this.attachWheelListener();
    this.attachMouseMoveListener();
    this.attachMouseUpListener();
    this.attachContextMenuListener();
    this.attachMouseDownListener();

    this.attachButtonListeners();
  }

  attachKeyboardListeners = () => {
    this.document.addEventListener('keydown', (event) => {
      const shiftPressed = event.shiftKey;
      const ctrlPressed = event.ctrlKey;
      const metaPressed = event.metaKey;
      const key = event.key.toLowerCase()
 
      // Handle common actions
      this.handleCancel(event);
      this.handleMovementKeys(event, shiftPressed);
      this.handleFloorChangeKeys(event, shiftPressed);
      this.handleRotationKeys(event, shiftPressed);
      this.handleZoomKeys(event);
      this.handleUndoRedoKeys(event, shiftPressed, ctrlPressed, metaPressed);
      this.handleChunkSelectionKeys(event, shiftPressed, ctrlPressed, metaPressed);
      this.handleChunkOpacityKeys(event, shiftPressed, ctrlPressed, metaPressed);
      this.handleChunkManipulationKeys(event, shiftPressed, ctrlPressed, metaPressed);
      this.handeHelpLineKeys(event)
      this.handleVideoControlKeys(event, key, shiftPressed, ctrlPressed);
      this.handleFloorEditingKeys(event, shiftPressed, ctrlPressed, metaPressed); 
      this.handleHelpKey(event);     

      // Update UI
      const selectedChunk = this.chunkEditor?.selectedChunk;
      const selectedChunkIndex = this.chunkManager?.getIndexForChunk(selectedChunk);
      updateDebugPanel(this.chunkManager.getAllChunks(), selectedChunkIndex)
    });
  }

  private handleHelpKey(event: KeyboardEvent) {
    if (event.key === KeyboardShortcut.SHOW_HELP.key) {
      showKeyboardShortcuts();
    }
  }

  private handleMovementKeys(event: KeyboardEvent, shiftPressed: boolean) {
    if (!isMoveKey(event.key)) return;
    
    const getMoveAmount = (key: string, positiveKey: string, negativeKey: string, amount: number): number => {
      if (key === positiveKey) return amount;
      if (key === negativeKey) return -amount;
      return 0;
    }

    event.preventDefault();
    if (this.chunkEditor?.isMovingChunk()) {
      const moveAmount = shiftPressed ? 10 : 1;
      const dx = getMoveAmount(event.key, KeyboardShortcut.MOVE_RIGHT.key, KeyboardShortcut.MOVE_LEFT.key, moveAmount);
      const dy = getMoveAmount(event.key, KeyboardShortcut.MOVE_DOWN.key, KeyboardShortcut.MOVE_UP.key, moveAmount);
      const moveCommand = this.chunkEditor?.moveSelectedChunk(dx, dy);
      if (moveCommand) {
        this.commandHistory.execute(moveCommand);
      }
    } else {
      // Pan canvas
      const panAmount = 10;
      if (event.key === KeyboardShortcut.MOVE_RIGHT.key) this.chunkEditor?.panXBy(-panAmount);
      if (event.key === KeyboardShortcut.MOVE_LEFT.key) this.chunkEditor?.panXBy(panAmount);
      if (event.key === KeyboardShortcut.MOVE_UP.key) this.chunkEditor?.panYBy(panAmount);
      if (event.key === KeyboardShortcut.MOVE_DOWN.key) this.chunkEditor?.panYBy(-panAmount);
    }
  }

  handleFloorChangeKeys(event: KeyboardEvent, shiftPressed: boolean) {
    if (!(shiftPressed && isDigitKeyCode(event.code))) {
      return;
    }
    const floorIndex = parseInt(event.code.slice(-1)) - 1;
    const currentFrame = this.chunkEditor?.selectFloor(floorIndex);
    this.updateVideo(currentFrame);
    updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
    this.chunkEditor.redrawChunks();
  }
    
  handleFloorEditingKeys(event: KeyboardEvent, shiftPressed: boolean, ctrlPressed: boolean, metaPressed: boolean) {
    if (!isFloorEditingKey(event.key)) {
      return;
    }
    if (this.currentMode === EditorMode.FLOOR_EDITING && event.key === KeyboardShortcut.TOGGLE_FLOOR_EDITING.key) {
      this.changeModeTo(EditorMode.DEFAULT);
      return;
    }
    if (event.key === KeyboardShortcut.TOGGLE_FLOOR_EDITING.key) {
      this.changeModeTo(EditorMode.FLOOR_EDITING);
    }

    if (this.currentMode === EditorMode.FLOOR_EDITING && event.key === KeyboardShortcut.RESET_FLOORS.key && !metaPressed && !shiftPressed && !ctrlPressed) {
      const resetCommand = this.floorEditor?.resetFloors();
      if (resetCommand) {
        this.commandHistory.execute(resetCommand);
      }
    }

    if (isDigitKey(event.key)) {
      const floorIndex = parseInt(event.key) - 1;
      const currentFrame = this.chunkEditor?.currentFrame;
      if (!currentFrame) {
        return;
      }

      const addFloorChangeCommand = this.floorEditor?.addFloorChange(currentFrame, floorIndex);
      if (addFloorChangeCommand) {
        this.commandHistory.execute(addFloorChangeCommand);
      }
    }

    updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
    this.chunkEditor.redrawChunks();
    const currentFrame = this.chunkEditor?.currentFrame;
    if (!currentFrame) {
      return;
    }
    this.updateVideo(currentFrame);

  }

  attachWheelListener = () => {
    // Throttled wheel event handler
    const throttledWheelHandler = throttle((event) => {
      event.preventDefault();
      const zoomStep = 0.05; // fixed zoom step
      const direction = event.deltaY > 0 ? -1 : 1; // zoom in for scroll up, out for scroll down
      const factor = direction * zoomStep;
      const screenPoint = getMousePosition(this.chunkEditor?.canvas, event);
      this.chunkEditor?.zoomToPoint(factor, screenPoint);
      updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
    }, 75); // Adjust this value as needed
  
    // Add event listener
    this.document.addEventListener(
      'wheel',
      (event) => {
        event.preventDefault();
        throttledWheelHandler(event);
      },
      { passive: false },
    );
  }

  attachMouseMoveListener = () => {
    this.document.addEventListener('mousemove', (event) => {
      this.mousePoint = getMousePosition(this.chunkEditor?.canvas, event);
      if (this.rightMouseDown) {
        this.chunkEditor?.panWith(this.mousePoint);
      }
  
      if (this.chunkEditor?.isLineDrawing()) {
        this.chunkEditor.setCurrentEndPoint(this.mousePoint, !event.shiftKey);
      }
  
      if (this.chunkEditor.isDragging && this.leftMouseDown) {
        this.chunkEditor.dragWith(this.mousePoint);
      }
    });
  }

  attachMouseUpListener = () => {
    this.document.addEventListener('mouseup', (event) => {
      if (event.button === 2) {
        // Right mouse button
        this.rightMouseDown = false;
        this.chunkEditor?.stopPanning();
      }
  
      if (event.button === 0) {
        this.leftMouseDown = false;
        const command = this.chunkEditor.stopDraggingChunk();
        if (command) {
          this.commandHistory.execute(command);
        }
      }
    });
  }

  attachContextMenuListener = () => {
    this.document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
  }

  attachMouseDownListener = () => {
    this.document.addEventListener('mousedown', (event) => {
      // Allow default behavior for select and its options
      if (
        event.target instanceof HTMLSelectElement ||
        event.target instanceof HTMLOptionElement
      ) {
        return;
      }
  
      // Prevent default for everything else
      event.preventDefault();
  
      if (event.button === 2) {
        this.rightMouseDown = true;
        const point = getMousePosition(this.chunkEditor?.canvas, event);
        this.chunkEditor?.startPanning(point);
        return;
      }
  
      if (event.button === 0 && event.altKey && event.shiftKey) {
        let screenPoint = getMousePosition(this.chunkEditor?.canvas, event);
        const currentFrame = this.chunkEditor?.selectPathPoint(screenPoint);
        this.chunkEditor.frameChanged(currentFrame);

        this.updateVideo(currentFrame);
        if (this.cameraFollowing) {
          this.centerToCamera();
        }
        return;
      }
  
      if (event.button === 0 && this.currentMode === EditorMode.LINE_DRAWING) {
        if (!this.chunkEditor.isLineDrawing()) {
          this.chunkEditor.startLineDrawing(this.mousePoint);
        } else {
          const command = this.chunkEditor.continueLineDrawing(this.mousePoint, !event.shiftKey);
          if (command) {
            this.commandHistory.execute(command);
          }
        }
      }
      
      if (
        event.button === 0 &&
        this.chunkEditor.selectedChunk &&
        !this.chunkEditor.isLineDrawing() &&
        !event.shiftKey &&
        !event.altKey
      ) {
        const screenPoint = getMousePosition(this.chunkEditor?.canvas, event);
        this.leftMouseDown = true;
        this.chunkEditor.startDraggingChunk(screenPoint);
        return;
      }
  
      if (event.button === 0 && !this.chunkEditor.selectedChunk && !this.chunkEditor.isLineDrawing()) {
        const screenPoint = getMousePosition(this.chunkEditor?.canvas, event);
        this.chunkEditor.selectHelpLine(screenPoint);
      }
    });
  
    let mouseDown = false;

    const elevationCanvas = this.document.getElementById('elevation-canvas');
    if (!elevationCanvas) {
      throw new Error("Elevation canvas not found");
    }

    const updateFrame = (event: MouseEvent) => {
      if (event.type === 'mousemove' && !mouseDown) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      const elevationCanvas = this.document.getElementById('elevation-canvas');
      if (!elevationCanvas) {
        throw new Error("Canvas not found");
      }
      const canvasWidth = elevationCanvas.offsetWidth;
  
      const x = event.offsetX;
      const frameNumber = Math.round(
        (x / canvasWidth) * this.chunkEditor?.arkitData.frames.length,
      );
      this.chunkEditor.currentFrame = frameNumber;
      this.videoManager?.seekToFrame(frameNumber);
      this.chunkEditor?.frameChanged(frameNumber);
      this.updateVideo(frameNumber);
    };
  
    elevationCanvas?.addEventListener('mousedown', (event) => {
      mouseDown = true;
      updateFrame(event);
    });
    
    // Add mouseleave event to stop dragging when the cursor leaves the canvas
    elevationCanvas?.addEventListener('mouseleave', () => {
      this.chunkEditor.currentFrame = this.chunkManager.getLastFrame();
      mouseDown = false;
    });
    
    elevationCanvas?.addEventListener('mousemove', updateFrame);
    elevationCanvas?.addEventListener('mouseup', () => (mouseDown = false));
  }


  async login(): Promise<string> {
    hideCompleteModal();
    const loginDialog = this.document.getElementById('login-dialog');
    if (!loginDialog) {
      throw new Error("Login dialog not found");
    }
    loginDialog.style.visibility = 'visible';

    // Try automatic login first
    try {
      let token: string | null = null;
      let tokenExpiration: string | null = null;
      let userId: string | null = null;

      if (Cookie.get('cc_temp_login_token')) {
        token = Cookie.get('cc_temp_login_token');
        if (!token) {
          throw new Error("Token not found");
        }
        userId = Cookie.get('cc_temp_user_id');
        if (!userId) {
          throw new Error("User ID not found");
        }
        tokenExpiration = (new Date().getTime() + 10 * 60 * 1000).toString(); // 10 minutes

        // Remove temporary cookie
        Cookie.remove('cc_temp_login_token');
        Cookie.remove('cc_temp_user_id');

        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
        localStorage.setItem('tokenExpiration', tokenExpiration.toString());
      } else {
        token = localStorage.getItem('token');
        tokenExpiration = localStorage.getItem('tokenExpiration');
        userId = localStorage.getItem('userId');
      }

      if (token && tokenExpiration && parseInt(tokenExpiration) > Date.now()) {
        const response = await this.api.getApiKey(userId, token);
        if (response.error) {
          throw new Error('Automatic login failed');
        }
        loginDialog.style.display = 'none';
        return Promise.resolve(userId);
      }
    } catch (error) {
      console.warn('Error during automatic login:', error);
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('tokenExpiration');
    }

    // If automatic login fails, wait for manual login
    return new Promise((resolve, reject) => {
      const usernameInput = this.document.getElementById('username');
      const passwordInput = this.document.getElementById('password');
      const eulaCheckbox = this.document.getElementById('eula');

      const handleLogin = async (event) => {
        event.preventDefault();

        const username = usernameInput.value;
        const password = passwordInput.value;

        try {
          const response = await this.api.login(username, password);
          if (response.error) {
            alert('Login failed');
            usernameInput.value = '';
            passwordInput.value = '';
            eulaCheckbox.checked = false;
            throw new Error('Login failed');
          }

          loginDialog.style.visibility = 'hidden';

          if (response.is_2fa_enable) {
            const sessionId = response.session_id;
            // TODO handle token
            if (!response.token) {
            }
            const userId = await handle2FA(sessionId, username);
            resolve(userId);
          } else {
            localStorage.setItem('token', response.token);
            localStorage.setItem('userId', response.user.id);
            const userId = response.user.id;
            localStorage.setItem('tokenExpiration', Date.now() + 86400000);
            const token = response.token;
            await this.api.getApiKey(userId, token);
            resolve(userId);
          }
        } catch (error) {
          reject(error);
        }
      };

      const handle2FA = (sessionId: string, username: string): Promise<string> => {
        return new Promise((resolve2FA, reject2FA) => {
          const twofaDialog = this.document.getElementById('twofa-dialog');
          if (!twofaDialog) {
            throw new Error("2FA dialog not found");
          }
          twofaDialog.style.visibility = 'visible';
          const inputs = this.document.querySelectorAll('.twofa-input');
          if (inputs.length === 0) {
            throw new Error("2FA inputs not found");
          }
          inputs[0].focus();
          const verifyButton = this.document.getElementById('2fa-button') as HTMLButtonElement;

          verifyButton.addEventListener('click', async (e) => {
            e.preventDefault();
            const code = Array.from(inputs)
              .map((input) => input.value)
              .join('');

            try {
              const response = await this.api.verify2FA(sessionId, code, username);
              if (response.error) {
                alert('2FA verification failed');
                twofaDialog.style.visibility = 'visible';
                throw new Error('2FA verification failed');
              }

              twofaDialog.style.visibility = 'hidden';
              localStorage.setItem('token', response.token);
              localStorage.setItem('userId', response.user.id);
              const userId = response.user.id;
              localStorage.setItem('tokenExpiration', Date.now() + 86400000);
              await this.api.getApiKey(userId, response.token);
              resolve2FA(userId);
            } catch (error) {
              alert('2FA verification failed');
              twofaDialog.style.visibility = 'hidden';
              loginDialog.style.visibility = 'visible';
              reject2FA(error);
            }
          });

          inputs.forEach((input, index) => {
            input.addEventListener('input', (e) => {
              const target = e.target as HTMLInputElement;
              if (!/^\d$/.test(target.value)) {
                target.value = '';
              }

              if (input.value.length === 1 && index < inputs.length - 1) {
                inputs[index + 1].focus();
              }

              if (index === inputs.length - 1) {
                verifyButton.disabled = false;
              }
            });

            input.addEventListener('keydown', (e) => {
              if (e.key === 'Backspace') {
                setTimeout(() => {
                  verifyButton.disabled = true;
                }, 0);
                if (input.value.length === 0 && index > 0) {
                  inputs[index - 1].focus();
                }
              }
            });
          });
        });
      };

      this.document.querySelector('form')?.addEventListener('submit', handleLogin.bind(this));
    });
  }

  getTicketData = (ticketId: string): Promise<[any, any, any]> => {
    return this.api
      .getTicket(ticketId)
      .then((response) => {
        const iterations = response.iterations;
  
        if (iterations.length > 1) {
          iterations.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
        }
  
        // Order events in each iteration from the most recent to oldest.
        // Most recent is the 1. element in array
        iterations.forEach((iteration) => {
          iteration.Events.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
        });
  
        const latestProcessEvent = iterations[0].Events[0];
  
        // if (
        //   ["waiting AT", "automating"].includes(latestProcessEvent.process_state) ||
        //  !["drawing", "needITHelp", "SFing"].includes(latestProcessEvent.process_state)) {
        //  alert("Cannot start source fix!\n\nTicket state need to be one of the following \n"
        //   + "drawing, needITHelp, SFing\n"+
        //   `Now the state is: ${latestProcessEvent.process_state}`);
        //   return Promise.reject("Wrong ticket state");
        // }
  
        // const processEventPromise =
        // latestProcessEvent.process_state === "drawing"
        //   ? api.createProcessEvent(
        //       "SFing",
        //       iterations[0].iteration_id,
        //       userId,
        //     )
        //   : Promise.resolve();
        const processEventPromise = Promise.resolve();
  
        return processEventPromise.then(
          () => {
            const sourceId = response.source_id.UUID;
            return this.api.getTicketSource(sourceId);
          },
          (error) => {
            window.alert(`Cannot upload file!.\n${error}`);
            return Promise.reject();
          },
        );
      })
      .then((response) => {
        return this.api.getTicketSourceKey(response.id);
      })
      .then((response) => {
        const video = response.find(
          (source) => source.source_type === 'videolowres',
        );
        const arkit = response.find(
          (source) => source.source_type === 'vbimdata',
        );
        const fixdata = response.find(
          (source) => source.source_type === 'chunkdata',
        );
  
        // Use Promise.all to fetch both video and arkit data
        return Promise.all([
          this.api.getTicketSourceKeyFile(
            video.source_id,
            video.id,
            video.source_type,
          ),
          this.api.getTicketSourceKeyFile(
            arkit.source_id,
            arkit.id,
            arkit.source_type,
          ),
          this.api.getTicketSourceKeyFile(
            fixdata.source_id,
            fixdata.id,
            fixdata.source_type,
          ),
        ]);
      })
      .then(async ([videoData, arkitData, fixdata]) => {
        // Return both video and arkit data here
        return [arkitData, videoData, fixdata];
      })
      .catch((error) => {
        alert(`Error during ticket fetching: ${error}`);
        throw error; // Re-throw the error to ensure the promise is rejected
      }); 
  };

  async loadChunkImages(images: any[], chunkData: any[], indexMaps: any[], arkitData: any, dataVersion: string) {
    try {
      await this.loadChunkImagesInBatches(images, chunkData, indexMaps, arkitData, dataVersion, 3); // Process 3 chunks at a time
      return Promise.resolve();
    } catch (error) {
      console.error("Error loading chunk images", error);
      return Promise.reject(error);
    }
  }

  private async loadChunkImagesInBatches(images: any[], chunkData: any[], indexMaps: any[], arkitData: any, dataVersion: string, batchSize: number = 3) {
    if (!images || images.length === 0 || !chunkData || 
        chunkData.length === 0 || !indexMaps || indexMaps.length === 0) {
      throw new Error("No data");
    }

    // Process chunks in batches
    for (let i = 0; i < chunkData.length; i += batchSize) {
      const batchPromises = [];
      
      // Process only batchSize number of chunks at a time
      for (let j = i; j < Math.min(i + batchSize, chunkData.length); j++) {
        const chunkDataItem = chunkData[j];
        batchPromises.push(
          (async () => {
            const { imageWidth, imageHeight, chunkCanvas } = await this.loadImage(images[j], chunkDataItem.boundingBox, dataVersion);
            const frames = [];
            for (let frameIndex = chunkDataItem.firstFrameId; 
                 frameIndex <= chunkDataItem.lastFrameId; frameIndex += 1) {
              frames.push(arkitData.frames[frameIndex]);
            }
            return {
              imageWidth,
              imageHeight,
              chunkCanvas,
              chunkBoundaries: chunkDataItem.boundingBox,
              frames: frames,
              indexMap: indexMaps[j],
            };
          })()
        );
      }

      // Wait for current batch to complete before moving to next batch
      const batchResults = await Promise.all(batchPromises);
          
      // Process the batch results
      batchResults.forEach(({
        chunkCanvas,
        chunkBoundaries,
        frames,
        indexMap,
      }) => {
        this.chunkManager.addChunk(
          new Chunk(
            chunkCanvas,
            Rectangle.fromArray(chunkBoundaries),
            frames,
            indexMap,
          )
        );
      });
    }

    // Create floor groupings after all chunks are loaded
    this.floorGroupings = createFloorGroups(arkitData.frames);
  }

  getFixCaseData = async (): Promise<[any, string, any]> => {
    showLoadingModal();
    // TODO: get rid of window reference?
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.ticketId = urlParams.get('ticket_id');
  
    try {
      if (this.ticketId !== null) {
        // Get files from ticket
        const data =  await this.getTicketData(this.ticketId);
        hideLoadingModal();
        return data;
      } else {
        // Get files from file upload
        hideLoadingModal();
        const dropArea = this.document.getElementById('drop-area');
        if (!dropArea) {
          throw new Error("Drop area not found");
        }
        dropArea.style.visibility = 'visible';
        
        return new Promise((resolve, reject) => {
          const uploader = new FileUploader(this.document, async (uploadContent) => {
            try {
              showLoadingModal();
              const config = JSON.parse(
                await uploadContent.files['config.json'].async('string')
              );
              const arkitFile = JSON.parse(
                await uploadContent.files[config['arKit'][0]].async('string')
              );
              const videoFile = await uploadContent.files[config['video_lowres'][0]].async('blob');
              const chunkDataFiles = await uploadContent.files[config['chunk_data'][0]].async('blob');

              const videoObjectUrl = await BlobObjectURL(videoFile, 'video/mp4');
              resolve([arkitFile, videoObjectUrl, chunkDataFiles]);
            } catch (error) {
              reject(error);
            } finally {
              hideLoadingModal();
            }
          });
        });
      }
    } catch (error) {
      hideLoadingModal();
      throw error;
    }
  }

  async loadImage(file: Blob, boundingBox: any, dataVersion: string): Promise<{ imageWidth: number; imageHeight: number; chunkCanvas: OffscreenCanvas }> {
    return new Promise((resolve, reject) => {

      const url = URL.createObjectURL(file); // create a URL for the Blob
      const img = new Image();
      img.src = url;
  
      img.onload = () => {
        // Create a new canvas to draw the image on
        const chunkCanvas = new OffscreenCanvas(boundingBox.width, boundingBox.height);        const chunkCtx = chunkCanvas.getContext("2d", { willReadFrequently: true });
        if (chunkCtx) {
          // Before version 2.0 all chunk images had same size which was the size of the whole scene
          if (isVersionOrLater(dataVersion, "2.0")) {
            chunkCtx.drawImage(img, 0, 0, img.width, img.height);
          } else {
            chunkCtx.drawImage(
              img,                      // source image
              boundingBox.x,            // source x
              boundingBox.y,            // source y
              boundingBox.width,        // source width
              boundingBox.height,       // source height
              0,                        // destination x
              0,                        // destination y
              boundingBox.width,        // destination width
              boundingBox.height        // destination height
            );
          }
          URL.revokeObjectURL(url); // Revoke the URL to free up memory
          resolve({ imageWidth: img.width, imageHeight: img.height, chunkCanvas });
        } else {
          reject(new Error("Failed to get 2D context"));
        }
      };
  
      img.onerror = (err) => {
        console.error(`Error loading image ${file}:`, err);
        URL.revokeObjectURL(url); // Revoke the URL to free up memory
        reject(err);
      };
    });
  }

  exportData() {
    const newArkitData = rotateArkitData(
      this.arkitData,
      this.chunkManager.getAllChunks()
    );

    const newFixroundFixes = this.chunkManager.getAllChunks().map((chunk) => {
      return {
        frameNumber: chunk.firstFrameNumber,
        rotationDeg: Number(chunk.rotation.toFixed(3)),
        translationM: [chunk.shift.x / 100.0, 0, chunk.shift.y / 100.0].map(
          (f) => Number(f.toFixed(3))
        ),
        source: "sourceFixTool",
      };
    });

    const fixRounds = this.arkitData.fixRounds;
    const newFixRound = {
      fixRoundNum: fixRounds.length,
      type: "manualSourceFix",
      fixes: newFixroundFixes.filter(
        (fix) => {
          const hasRotation = fix.rotationDeg !== 0;
          const hasTranslation = fix.translationM[0] !== 0 || fix.translationM[2] !== 0; // Note: using [2] for Z coordinate
          return hasRotation || hasTranslation; // Keep if there's either rotation OR translation
        }
      ),
    };

    const newData = { ...newArkitData, fixRounds: [...fixRounds, newFixRound] };

    const newFloorGroups = createFloorGroups(newArkitData.frames);
    if (!_.isEqual(this.floorGroupings, newFloorGroups)) {
      newData.floor_grouping = newFloorGroups;
      const difference = floorGroupingDifference(this.floorGroupings, newFloorGroups, newArkitData.frames.length);
      
      // Step 1: Aggregate changes
      const aggregatedDifferences = {};
      Object.entries(difference).forEach(([key, value]) => {
        if (!aggregatedDifferences[value]) {
          aggregatedDifferences[value] = { startFrame: parseInt(key, 10), endFrame: parseInt(key, 10) };
        } else {
          aggregatedDifferences[value].endFrame = parseInt(key, 10);
        }
      });

      // Step 2: Create objects with grouped frames
      Object.entries(aggregatedDifferences).forEach(([value, { startFrame, endFrame }]) => {
        if (startFrame === endFrame) {
          // If the start and end frames are the same, it means only one frame has this difference
          newFixRound.fixes.push({ "frameNumber": startFrame, "floorDifference": parseInt(value, 10), source: "manualSourceFix" });
        } else {
          // For multiple frames with the same difference, include both the start frame and the last applied frame
          newFixRound.fixes.push({ "frameNumber": startFrame, "lastAppliedFrame": endFrame, "floorDifference": parseInt(value, 10), source: "manualSourceFix" });
        }
      });
    }

    if (newFixRound.fixes.length === 0) {
      return this.arkitData;
    }
    return newData;
  }

  attachButtonListeners = () => {
    const followCameraButton = this.document.getElementById("follow-camera-btn");
    followCameraButton?.addEventListener("click", () => {
      this.toggleCameraFollow();
    });
  
    const nextChunkButton = document.getElementById("nextChunk");
    nextChunkButton?.addEventListener("click", () => {
      const [floorIndex, chunk] = this.chunkEditor.selectNextChunk();
      updateInfobox(floorIndex, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(chunk), this.chunkEditor?.zoom);
      //updateDebugPanel();
    });
  
    const previousChunkButton = document.getElementById('prevChunk');
    previousChunkButton?.addEventListener('click', () => {
      const [floorIndex, chunk] = this.chunkEditor.selectPrevChunk();
      updateInfobox(floorIndex, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(chunk), this.chunkEditor?.zoom);
      //updateDebugPanel();
    });
  
    const exportButton = document.getElementById('export');
    exportButton?.addEventListener('click', () => {
      showLoadingModal();
      setTimeout(() => {
        const arkitData = this.exportData();
        const data = JSON.stringify(arkitData);
        const blob = new Blob([data], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'arkitData.json';
        a.click();
        hideLoadingModal();
      }, 100);
    });
  
    const playButton = document.getElementById("play-pause-btn") as HTMLButtonElement;
    playButton?.addEventListener("click", () => {
      this.togglePlay();
    });

    // Add button to show keyboard shortcuts
    const helpButton = document.getElementById('help-button');
    if (helpButton) {
      helpButton.addEventListener('click', () => {
        showKeyboardShortcuts();
      });
    }
  
    const speedMultiplier = document.getElementById("speed-multiplier") as HTMLSelectElement;
    speedMultiplier?.addEventListener("change", () => {
      this.chunkEditor.videoController?.setMultiplier(parseInt(speedMultiplier.value));
    });
  
    const rerunAutomationButton = document.getElementById('rerun-automation');
    if (this.ticketId && rerunAutomationButton) {
      rerunAutomationButton?.addEventListener('click', () => {
        const saveDialog = document.getElementById('save-dialog');
        saveDialog.style.visibility = 'visible';
  
        const cancelButton = document.getElementById('cancel-button');
        const rerunButton = document.getElementById('rerun-button');

        if (!cancelButton || !rerunButton || !saveDialog) {
          return;
        }
  
        cancelButton.addEventListener('click', () => {
          saveDialog.style.visibility = 'hidden';
        });
  
        rerunButton.addEventListener('click', () => {
          showLoadingModal();
          saveDialog.style.visibility = 'hidden';
          const configOptions = {
            fix_mode: 0,
            realign_chunks: 0,
            chunking_method: 'spatial',
            fix_relocalizations: 0,
            use_depth: 1,
          };
  
          const intKeys = [
            'fix_mode',
            'realign_chunks',
            'fix_relocalizations',
            'use_depth',
          ];
  
          Object.keys(configOptions).forEach((optionName) => {
            const selector = `input[name="${optionName}"]:checked`;
            const value = this.document.querySelector(selector)?.value;
            configOptions[optionName] = intKeys.includes(optionName)
              ? parseInt(value, 10)
              : value;
          });
  
          const arkitData = this.exportData();
          arkitData.data_configs = configOptions;
          this.api.createSourceFix(this.ticketId!, arkitData).then((response) => {
            hideLoadingModal();
            showCompleteModal();
          });
        });
      });
    } else if (rerunAutomationButton) {
      rerunAutomationButton.style.display = 'none';
    }
  };

  private handleRotationKeys(event: KeyboardEvent, shiftPressed: boolean) {
    if (event.code !== KeyboardShortcut.ROTATE_LEFT.key && event.code !== KeyboardShortcut.ROTATE_RIGHT.key) return;
    
    if (this.chunkEditor?.isMovingChunk()) {
      event.preventDefault();
      const rotationAmount = shiftPressed ? 22.5 : 0.5;
      const rotateCommand = this.chunkEditor?.rotateSelectedChunk(
        event.code === KeyboardShortcut.ROTATE_LEFT.key ? -rotationAmount : rotationAmount
      );
      if (rotateCommand) {
        this.commandHistory.execute(rotateCommand);
      }
    } 
  }

  private handleZoomKeys(event: KeyboardEvent) {
    if (event.key !== KeyboardShortcut.ZOOM_IN.key && event.key !== KeyboardShortcut.ZOOM_OUT.key) return;
    
    event.preventDefault();
    this.chunkEditor?.zoomToCenter(event.key === KeyboardShortcut.ZOOM_IN.key ? 0.1 : -0.1);
    updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
  }

  private handleCancel(event: KeyboardEvent) {
    if (event.key === KeyboardShortcut.CANCEL.key) {
      event.preventDefault();
      this.changeModeTo(EditorMode.DEFAULT);
    }
  }


  private handleChunkSelectionKeys(event: KeyboardEvent, shiftPressed: boolean, ctrlPressed: boolean, metaPressed: boolean) {
    if (!isChunkSelectionKey(event.key) || (shiftPressed || ctrlPressed || metaPressed)) return;
    event.preventDefault();
    
    // Toggle chunk editing mode
    if (this.currentMode === EditorMode.CHUNK_EDITING && event.key === KeyboardShortcut.SELECT_CHUNK_BY_FRAME.key) {
        this.changeModeTo(EditorMode.DEFAULT);
        return;
    }

    this.changeModeTo(EditorMode.CHUNK_EDITING);

    // Handle different chunk selection actions
    let [floorIndex, chunk] = this.selectChunkBasedOnKey(event.key, shiftPressed, ctrlPressed, metaPressed);
    
    // Update UI only if a chunk was selected
    if (chunk) {
        const floorAmount = this.chunkEditor.chunkManager.getFloorAmount();
        updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
      }
  }

  private selectChunkBasedOnKey(key: string, shiftPressed: boolean, ctrlPressed: boolean, metaPressed: boolean): [number | null, Chunk | null] {
    // Select previous chunk
    if (key === KeyboardShortcut.SELECT_PREV_CHUNK.key && !shiftPressed && !ctrlPressed && !metaPressed) {
        const [floorIndex, chunk] = this.chunkEditor.selectPrevChunk();
        if (chunk) {
            this.updateVideo(chunk.firstFrameNumber);
        }
        return [floorIndex, chunk];
    }
    
    // Select next chunks
    if (key === KeyboardShortcut.SELECT_NEXT_CHUNK.key && !shiftPressed && !ctrlPressed && !metaPressed) {
        const [floorIndex, chunk] = this.chunkEditor.selectNextChunk();
        if (chunk) {
            this.updateVideo(chunk.firstFrameNumber);
        }
        return [floorIndex, chunk];
    }
    
    // Select chunk by current frame
    if (key === KeyboardShortcut.SELECT_CHUNK_BY_FRAME.key) {
        const [floorIndex, chunk] = this.chunkEditor.selectChunkByCurrentFrame();
        this.updateVideo(this.chunkEditor.currentFrame);
        return [floorIndex, chunk];
    }

    return [null, null];
  }

  private handleChunkOpacityKeys(event: KeyboardEvent, shiftPressed: boolean, ctrlPressed: boolean, metaPressed: boolean) {
    if (!isChunkOpacityKey(event.key) || this.currentMode !== EditorMode.CHUNK_EDITING) {
      return;
    }
    if (event.key === KeyboardShortcut.TOGGLE_OPACITY.key) {
      event.preventDefault();
      this.chunkEditor.toggleChunkOpacity();
    }
  }


  private handleChunkManipulationKeys(event: KeyboardEvent, shiftPressed: boolean, ctrlPressed: boolean, metaPressed: boolean) {
    if (this.currentMode !== EditorMode.CHUNK_EDITING) {
      return;
    }
    
    const key = event.key.toLowerCase();

    // Split chunk
    if (key === KeyboardShortcut.SPLIT_CHUNK.key && this.chunkEditor?.selectedChunk) {
      showLoadingModal();
      event.preventDefault();
      setTimeout(() => {
        const splitCommand = this.chunkEditor?.splitChunk();
        if (splitCommand) {
          this.commandHistory.execute(splitCommand);
          const selectedChunk = this.chunkManager.getChunkByFrame(this.chunkEditor.currentFrame);
          if (selectedChunk) {
            this.chunkEditor.selectChunk(selectedChunk);
          }
          this.chunkEditor.redrawChunks();
        }
        hideLoadingModal();
        //updateDebugPanel(this.chunkEditor?.selectedChunk?.frames.length);
      }, 100);
    }

    // Floor movement
    if (key === KeyboardShortcut.MOVE_CHUNK_UP.key) {
      event.preventDefault();
      const command = this.chunkEditor.moveSelectedChunkToUpperFloor(shiftPressed, ctrlPressed || metaPressed);
      if (command) {
        this.commandHistory.execute(command);
      }
      updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
    }

    if (key === KeyboardShortcut.MOVE_CHUNK_DOWN.key) {
      event.preventDefault();
      const command = this.chunkEditor.moveSelectedChunkToLowerFloor(shiftPressed, ctrlPressed || metaPressed);
      if (command) {
        this.commandHistory.execute(command);
      }
      updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
    }

    // Floor swapping
    if (key === KeyboardShortcut.SWAP_FLOOR_UP.key) {
      event.preventDefault();
      const swapCommand = this.chunkEditor.swapSelectedChunkFloorWithUpperFloor();
      if (swapCommand) {
        this.commandHistory.execute(swapCommand);
      }
      updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
    }

    if (key === KeyboardShortcut.SWAP_FLOOR_DOWN.key) {
      event.preventDefault();
      const swapCommand = this.chunkEditor.swapSelectedChunkFloorWithBelowFloor();
      if (swapCommand) {
        this.commandHistory.execute(swapCommand);
      }
      updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
    }
  }

  private handeHelpLineKeys(event: KeyboardEvent) {
    if (!isLineDrawingKey(event.key)) {
      return;
    }
    event.preventDefault();    

    if (event.key === KeyboardShortcut.TOGGLE_LINE_DRAWING.key) {
      if (this.currentMode === EditorMode.LINE_DRAWING) {
        this.changeModeTo(EditorMode.DEFAULT);
        return;
      }

      if (this.canvas) {
        this.canvas.style.cursor = 'crosshair';
      }

      this.changeModeTo(EditorMode.LINE_DRAWING);

    } else if (event.key === KeyboardShortcut.DELETE_LINE.key) {
      const command = this.chunkEditor?.removeSelectedHelpLine();
      if (command) {
        this.commandHistory.execute(command);
      }
    } else if (event.key === KeyboardShortcut.COPY_LINE_TO_FLOORS.key) {
      const command = this.chunkEditor?.copySelectedLineToAllFloors();
      if (command) {
        this.commandHistory.execute(command);
      }
    }
  }

  private handleVideoControlKeys(event: KeyboardEvent, key: string, shiftPressed: boolean, ctrlPressed: boolean) {
    if (key === KeyboardShortcut.NEXT_FRAME.key) {
      const currentFrame = this.nextFrame(shiftPressed ? 10 : 1);
      this.updateVideo(currentFrame);
      if (this.cameraFollowing) {
        this.centerToCamera();
      }
      this.chunkEditor.frameChanged(currentFrame);
    } else if (key === KeyboardShortcut.PREV_FRAME.key) {
      const currentFrame = this.prevFrame(shiftPressed ? 10 : 1);
      this.updateVideo(currentFrame);
      if (this.cameraFollowing) {
        this.centerToCamera();
      }
      this.chunkEditor.frameChanged(currentFrame);
    } else if (key === KeyboardShortcut.TOGGLE_PLAY.key) {
      if (!shiftPressed && !ctrlPressed) {
        this.togglePlay();
      } else if (shiftPressed) {
        this.videoManager?.incrementMultiplier(); 
        if (this.cameraFollowing) {
          this.centerToCamera();
        }
        updateSpeedMultiplier(this.videoManager?.multiplier.toString() ?? "1");
      } else if (ctrlPressed) {
        this.videoManager?.decrementMultiplier();
        updateSpeedMultiplier(this.videoManager?.multiplier.toString() ?? "1");
      }
    } else if (key === KeyboardShortcut.TOGGLE_VIDEO.key) {
      toggleVideoVisibility();
    } else if (key === KeyboardShortcut.TOGGLE_FOLLOW_CAMERA.key) {
      this.toggleCameraFollow();
    } else if (key === KeyboardShortcut.CENTER_TO_CAMERA.key) {
      this.centerToCamera();
    }
  }

  private handleUndoRedoKeys(event: KeyboardEvent, shiftPressed: boolean, ctrlPressed: boolean, metaPressed: boolean) {
    if (event.key !== KeyboardShortcut.UNDO.key && event.key !== KeyboardShortcut.REDO.key) {
      return;
    }

    if (event.key === KeyboardShortcut.UNDO.key && (ctrlPressed || (metaPressed) && !shiftPressed)) {
      event.preventDefault();
      this.commandHistory.undo();
    } 
    
    if ((event.key === KeyboardShortcut.UNDO.key && shiftPressed && (metaPressed || ctrlPressed)) || 
               (event.key === KeyboardShortcut.REDO.key && (ctrlPressed || metaPressed))) {
        event.preventDefault();
        this.commandHistory.redo();
    }
    this.chunkEditor.redrawChunks();
    this.updateVideo(this.currentFrame);
    updateInfobox(this.chunkEditor?.currentFloor, this.chunkManager.getFloorAmount(), this.chunkManager.getIndexForChunk(this.chunkEditor?.selectedChunk), this.chunkEditor?.zoom);
  }

  nextFrame(frameAmount: number): number {
    this.currentFrame = this.videoManager?.nextFrame(frameAmount) ?? this.currentFrame;
    this.videoManager?.pause()
    this.chunkEditor.frameChanged(this.currentFrame);
    return this.currentFrame;
  }

  prevFrame(frameAmount: number): number {
    this.currentFrame = this.videoManager?.prevFrame(frameAmount) ?? this.currentFrame;
    this.videoManager?.pause()
    this.chunkEditor.frameChanged(this.currentFrame);
    return this.currentFrame;
  }

  private togglePlay = () => {
    // Throttled version of updateVideo
    const throttledUpdateVideo = throttle((frame) => {
      this.updateVideo(frame);
    }, 10);
  
    this.videoManager?.togglePlay((currentFrame) => {
      throttledUpdateVideo(currentFrame);
      if (this.cameraFollowing) {
        this.centerToCamera();
      }
      this.chunkEditor.frameChanged(currentFrame);
    });
    updateVideoPlayButton();
  };
  
  private toggleCameraFollow() {
    this.cameraFollowing = !this.cameraFollowing;
    updateVideoFollowCameraButton();
  }

  private centerToCamera() {
    const frameNumber = this.chunkEditor.currentFrame;
    const chunk = this.chunkEditor.chunkManager.getChunkByFrame(frameNumber);
    if (chunk) {
      const point = chunk!.getCameraPosition(frameNumber);
      this.chunkEditor.centerToPoint(point)
      this.chunkEditor.redrawChunks();
    }
  }

  updateVideo = _.throttle(
    (frameNumber: number) => {
      const frameIndicator = document.getElementById('frame-indicator');
      const chunkIndicator = document.getElementById('chunk-indicator');
      const deviceModelIndicator = document.getElementById(
        'device-model-indicator',
      );
      const elevationCanvas = document.getElementById(
        'elevation-canvas',
      ) as HTMLCanvasElement;
  
      const ctx = elevationCanvas.getContext('2d');
      if (
        !frameIndicator ||
        !chunkIndicator ||
        !deviceModelIndicator ||
        !elevationCanvas ||
        !ctx
      ) {
        return;
      }
  
      // Update video position
      requestAnimationFrame(() => {
        this.videoManager?.seekToFrame(frameNumber);
      });
  
      // Update UI in next frame to avoid blocking
      requestAnimationFrame(() => {
        if (
          !frameIndicator ||
          !chunkIndicator ||
          !deviceModelIndicator ||
          !elevationCanvas
        ) {
          console.error("elevation canvas not found");
          return;
        }
  
        const frame = this.arkitData.frames[frameNumber];
        frameIndicator.textContent = `Frame: ${frameNumber} `;
        chunkIndicator.textContent = `Chunk: ${frame ? frame.chunk : 'undefined'}`;
  
        deviceModelIndicator.textContent = `Device Model: ${this.arkitData.phoneMarketName}, ${this.arkitData.phoneModel}`;
  
        // Draw elevation curve
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, elevationCanvas.width, elevationCanvas.height);
  
        // get elevation data and gather min and max values
        const elevationData: number[] = [];
        let min = Infinity;
        let max = -Infinity;
        const minElevationPerFloor: Record<number, number> = {};
        let firstFrameFloor = this.arkitData.frames[0].floorNumber;
        const floorChangeFrames = [[0, firstFrameFloor]];
        this.arkitData.frames.forEach((frame: any) => { // TODO: typesafe
          const elevation = frame.cameraTransform[3][1] * 100;
          elevationData.push(elevation);
          min = Math.min(min, elevation);
          max = Math.max(max, elevation);
  
          const floor = frame.floorNumber;
  
          if (
            !minElevationPerFloor[floor] ||
            elevation < minElevationPerFloor[floor]
          ) {
            minElevationPerFloor[floor] = elevation;
          }
          if (floor !== firstFrameFloor) {
            floorChangeFrames.push([frame.frameNumber, floor]);
            firstFrameFloor = floor;
          }
        });
  
        // Draw elevation curve
        ctx.beginPath();
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 1;
        const width = elevationCanvas.width;
        const height = elevationCanvas.height;
        const step = width / elevationData.length;
        ctx.moveTo(0, height - 10);
        elevationData.forEach((elevation, i) => {
          const x = i * step;
          const y =
            height - 20 - ((elevation - min) / (max - min)) * (height - 25);
          ctx.lineTo(x, y);
        });
        ctx.stroke();
  
        // draw floor levels
        floorChangeFrames.forEach(([frame, floor]) => {
          ctx.beginPath();
          ctx.strokeStyle = '#00f';
          ctx.lineWidth = 1;
          ctx.moveTo(step * frame, 0);
          ctx.lineTo(step * frame, elevationCanvas.height);
          ctx.stroke();
  
          if (floor === -1) {
            return;
          }
          ctx.save();
          ctx.font = '18px Monaco ';
          ctx.fillStyle = '#000';
          ctx.fillText(
            `${floor + 1}`,
            step * frame + 5,
            elevationCanvas.height - 5,
          );
          ctx.restore();
        });
  
        // Draw current frame indicator
        ctx.beginPath();
        ctx.strokeStyle = '#f00';
        ctx.lineWidth = 1;
        const x = (frameNumber / elevationData.length) * width;
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
        ctx.stroke();
      });
    },
    33,
  ); 
}
