import JSZip from "jszip";

export class FileUploader {
  private doc: Document;
  private callback: (zip: JSZip) => void;
  private fileUpload: HTMLInputElement;
  private uploadButton: HTMLElement;
  private dropArea: HTMLElement;

  constructor(doc: Document, callback: (zip: JSZip) => void) {
    this.doc = doc;
    this.callback = callback;
    
    // Get required elements and check if they exist
    const uploadButton = this.doc.getElementById("upload-button");
    const dropArea = this.doc.getElementById("drop-area");
    
    if (!uploadButton || !dropArea) {
      throw new Error("Required elements not found");
    }
    
    this.fileUpload = this.doc.getElementById("file-upload") as HTMLInputElement;
    this.uploadButton = uploadButton;
    this.dropArea = dropArea;

    if (!this.fileUpload) {
      throw new Error("File upload element not found. Element with id 'file-upload' is required.");
    }
    if (!this.uploadButton) {
      throw new Error("Upload button element not found. Element with id 'upload-button' is required.");
    }
    if (!this.dropArea) {
      throw new Error("Drop area element not found. Element with id 'drop-area' is required.");
    }

    this.setupFileUpload();
  }

  setupFileUpload() {
    this.uploadButton.addEventListener("click", () => {
      this.fileUpload.click();
    });

    this.fileUpload.addEventListener("change", (e: Event) => {
      const input = e.target as HTMLInputElement;
      if (!input.files?.length) {
        console.error("No files selected");
        return;
      }
      this.handleFiles(input.files);
    });

    this.dropArea.addEventListener("dragover", (e) => {
      e.preventDefault();
    });

    this.dropArea.addEventListener("drop", (e: DragEvent) => {
      e.preventDefault();
      if (!e.dataTransfer?.files.length) {
        console.error("No files dropped");
        return;
      }
      this.handleFiles(e.dataTransfer.files);
    });
  }

  private handleFiles(files: FileList): void {
    if (files.length === 0) {
      throw new Error("No files provided to handleFiles");
    }

    const file = files[0];
    const reader = new FileReader();
    
    reader.onerror = () => {
      console.error("Error reading file:", reader.error);
    };

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const result = e.target?.result;
      if (!result) {
        console.error("Failed to read file contents");
        return;
      }

      JSZip.loadAsync(result).then((zip) => {
        this.callback(zip);
        this.dropArea.style.display = "none";
      }).catch((error) => {
        console.error("Failed to process zip file:", error);
      });
    };
    
    reader.readAsArrayBuffer(file);
  }
}
