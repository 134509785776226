import { Point } from "../types";
import ChunkEditor from '../chunkEditor';
import { Line } from "../types";

export class RemoveHelpLineCommand {
    private imageChunkMover: ChunkEditor;
    private line: Line;
    private lineIndex: number;
    private floor: number;

    constructor(imageChunkMover: ChunkEditor, index: number, floor: number) {
        this.imageChunkMover = imageChunkMover
        this.lineIndex = index
        this.floor = floor

        this.line = this.imageChunkMover.helpLines[this.floor][index]
    }

    execute() {
        this.imageChunkMover.helpLines[this.floor] = this.imageChunkMover.helpLines[this.floor].filter((helpLine) => helpLine !== this.line)
        this.imageChunkMover.selectedLineIndex = null
    }

    undo() {
        this.imageChunkMover.selectedLineIndex = null
        this.imageChunkMover.helpLines[this.floor].splice(this.lineIndex, 0, this.line)
    }
}