class Video {
  fileObjectUrl: string;
  arkitData: any; 
  currentFrame: number;
  framerate: number;
  playEvent: number | null;
  paused: boolean;
  lastFrame: number;
  video: HTMLVideoElement;
  videoWidth: number;
  videoHeight: number;
  lastTimestamp: number | null;


  constructor(fileObjectUrl: string, arkitData: any) {
    this.fileObjectUrl = fileObjectUrl;
    this.arkitData = arkitData;
    this.currentFrame = 0;
    this.framerate = 1 / 10;
    this.playEvent = null;
    this.paused = true;
    this.lastFrame = this.arkitData.frames.length - 1;

    this.videoWidth = 0;
    this.videoHeight = 0;

    this.video = document.getElementById("video") as HTMLVideoElement;
    this.video.src = fileObjectUrl;
    this.video.autoplay = false;
    this.video.loop = true;
    this.video.muted = true;
    this.video.preload = "auto";
    this.video.load();
    this.video.addEventListener("loadeddata", () => {
      this.videoWidth = this.video.videoWidth;
      this.videoHeight = this.video.videoHeight;
    });
    this.lastTimestamp = null;
  }

  nextFrame(frameAmount: number) {
    if (this.currentFrame + frameAmount >= this.lastFrame) {
      return this.lastFrame;
    }
    this.currentFrame += frameAmount;
    const frame = this.arkitData.frames[this.currentFrame];
    this.video.currentTime += this.framerate * frameAmount;
    return frame.frameNumber;
  }

  prevFrame(frameAmount: number) {
    if (this.currentFrame - frameAmount <= 0) {
      return 0;
    }
    this.currentFrame = Math.max(this.currentFrame - frameAmount, 0);
    const frame = this.arkitData.frames[this.currentFrame];
    this.video.currentTime -= this.framerate * frameAmount;
    return frame.frameNumber;
  }

  seekToFrame(frameNumeber: number) {
    this.currentFrame = frameNumeber;
    const frame = this.arkitData.frames[this.currentFrame];
    this.video.currentTime = this.framerate * frame.frameNumber;
  }

  togglePlay(multiplier: number, callback: (frame: number) => void) {
    const play = (timestamp: number) => {
      if (!this.lastTimestamp) {
        this.lastTimestamp = timestamp;
      }

      const elapsed = timestamp - this.lastTimestamp;

      if (elapsed > this.framerate * 1000) {
        const nextFrame = this.nextFrame(multiplier);
        callback(nextFrame);
        this.lastTimestamp = timestamp;

        if (nextFrame === this.lastFrame) {
          this.paused = true;
        }
      }

      if (!this.paused) {
        this.playEvent = requestAnimationFrame(play);
      }
    };

    if (this.paused) {
      this.paused = false;
      this.playEvent = requestAnimationFrame(play);
    } else {
      this.paused = true;
    }
  }
}


export default Video;
