import Chunk from "./chunk";
import { KeyboardShortcut, getKeyboardShortcutDescriptions } from "./keyboard";
export function showLoadingModal(): void {
  const modal = document.getElementById('loading-modal');
  if (!modal) throw new Error('Loading modal element not found');
  modal.style.display = 'flex';
}

export function hideLoadingModal(): void {
  const modal = document.getElementById('loading-modal');
  if (!modal) throw new Error('Loading modal element not found');
  modal.style.display = 'none';
}

export function hideCompleteModal(): void {
  const modal = document.getElementById('completed-modal');
  if (!modal) throw new Error('Complete modal element not found');
  modal.style.display = 'none';
}

export function showCompleteModal(): void {
  const modal = document.getElementById('completed-modal');
  if (!modal) throw new Error('Complete modal element not found');
  modal.style.display = 'flex';
}

export const getFloorIndicator = (
  floorIndex: number | null,
  floorAmount: number,
): string => {
  const floors = [];
  for (let i = 0; i < floorAmount; i += 1) {
    if (i === floorIndex) {
      floors.push(`<span id="selectedFloor">${i + 1}</span>`);
    } else {
      floors.push(`<span id="floor">${i + 1}</span>`);
    }
  }
  return floors.join(' | ');
};

export const updateInfobox = (floorNumber: number | null, floorAmount: number, chunkIndex: number | null, zoom: number) => {
  const ui = document.getElementById('ui');
  const infobox = document.getElementById('infobox');
  if (!ui || !infobox) {
    return;
  }

  ui.style.visibility = 'visible';
  infobox.innerHTML = `Floor: ${getFloorIndicator(
    floorNumber,
    floorAmount
  )} Selected chunk: ${chunkIndex !== null ? chunkIndex : 'None'} Zoom: ${Math.round(
    zoom * 100,
  )}%`;
};

export const updateDebugPanel = (chunks: Chunk[], chunkIndex: number) => {
  const chunkInfoDiv = document.getElementById('chunk-info');
  if (!chunkInfoDiv) {
    return;
  }

  const debugTableRow = (label: string, value: string) => {
    return `<tr><td>${label}</td><td>${value}</td></tr>`;
  };

  let html = '<table><thead>';
  chunks.forEach((chunk: Chunk) => {
    html += `<tr><th>Chunk ${chunkIndex}, f:${chunk.floor} c:${chunk.frames[0].chunk}</th></tr></thead><tbody>`;
    html += debugTableRow('Coordinates', chunk.position.toString());
    html += debugTableRow('PathStartPoint', chunk.pathStartPosition.toString());
    html += debugTableRow(
      'GlobalRotation',
      `${chunk.globalRotation.toFixed(1)}°`,
    );
    html += debugTableRow('Rotation', `${chunk.rotation.toFixed(1)}°`);
    html += debugTableRow('Shift', chunk.shift.toString());
  });
  html += '</tbody></table>';
  chunkInfoDiv.innerHTML = html;
};

export const toggleVideoVisibility = () => {
  const videoContainer = document.getElementById('video-container');
  if (videoContainer) {
    videoContainer.style.display = videoContainer.style.display === 'block' ? 'none' : 'block';
  }
}

export const toggleDebugPanel = () => {
  const debugPanel = document.getElementById('debug-panel');
  if (debugPanel.style.display === 'none' || debugPanel.style.display === '') {
    debugPanel.style.display = 'block';
  } else {
    debugPanel.style.display = 'none';
  }
};

export const updateVideoPlayButton = () => {
  const playPauseButton = document.getElementById(
    'play-pause-btn',
  ) as HTMLButtonElement;
  const playIcon = playPauseButton.querySelector('.play-icon');
  const pauseIcon = playPauseButton.querySelector('.pause-icon');

  if (playIcon?.classList.contains('hidden')) {
    playIcon.classList.remove('hidden');
    pauseIcon?.classList.add('hidden');
  } else {
    playIcon?.classList.add('hidden');
    pauseIcon?.classList.remove('hidden');
  }
};

export const updateSpeedMultiplier = (value: string) => {
  const speedMultiplier = document.getElementById("speed-multiplier") as HTMLSelectElement;
  speedMultiplier.value = value;
}

export const updateVideoFollowCameraButton = () => {
  const followCameraButton = document.getElementById("follow-camera-btn") as HTMLButtonElement;
  followCameraButton.classList.toggle('active');
}

export const showKeyboardShortcuts = () => {
  // Get the modal and overlay elements
  const shortcutsModal = document.getElementById('shortcuts-modal');
  const overlay = document.getElementById('shortcuts-modal-overlay');
  const shortcutsGrid = document.getElementById('shortcuts-grid');
  const closeButton = document.getElementById('close-shortcuts-btn');
  
  if (!shortcutsModal || !overlay || !shortcutsGrid || !closeButton) {
    console.error('Could not find keyboard shortcuts modal elements');
    return;
  }
  
  // Clear existing content in the grid
  shortcutsGrid.innerHTML = '';
  
  // Get the keyboard shortcut descriptions
  const shortcutDescriptions = getKeyboardShortcutDescriptions();
  
  // Group shortcuts by category
  const categories = {
    "Movement": ["MOVE_LEFT", "MOVE_RIGHT", "MOVE_UP", "MOVE_DOWN"],
    "Rotation": ["ROTATE_LEFT", "ROTATE_RIGHT"],
    "Zoom": ["ZOOM_IN", "ZOOM_OUT"],
    "Chunk Selection": ["SELECT_PREV_CHUNK", "SELECT_NEXT_CHUNK", "SELECT_CHUNK_BY_FRAME"],
    "Floor Editing": ["TOGGLE_FLOOR_EDITING", "RESET_FLOORS"],
    "Chunk Operations": ["SPLIT_CHUNK", "TOGGLE_LINE_DRAWING", "COPY_LINE_TO_FLOORS", "DELETE_LINE"],
    "View Controls": ["TOGGLE_VIDEO", "NEXT_FRAME", "PREV_FRAME", "TOGGLE_PLAY", "TOGGLE_FOLLOW_CAMERA"],
    "Floor Operations": ["MOVE_CHUNK_UP", "MOVE_CHUNK_DOWN", "SWAP_FLOOR_UP", "SWAP_FLOOR_DOWN"],
    "History": ["UNDO", "REDO"],
    "Other": ["CANCEL", "TOGGLE_OPACITY", "CENTER_TO_CAMERA", "TOGGLE_DEBUG", "SHOW_HELP"]
  };
  
  // Add shortcuts by category
  Object.entries(categories).forEach(([category, shortcuts]) => {
    const categoryDiv = document.createElement('div');
    
    const categoryTitle = document.createElement('h3');
    categoryTitle.textContent = category;
    categoryDiv.appendChild(categoryTitle);
    
    shortcuts.forEach(shortcut => {
      if (shortcutDescriptions[shortcut]) {
        const shortcutDiv = document.createElement('div');
        shortcutDiv.className = 'shortcut-item';
        
        const keySpan = document.createElement('span');
        keySpan.className = 'shortcut-key';
        keySpan.textContent = KeyboardShortcut[shortcut].key;
        
        const descSpan = document.createElement('span');
        descSpan.textContent = KeyboardShortcut[shortcut].description;
        
        shortcutDiv.appendChild(keySpan);
        shortcutDiv.appendChild(descSpan);
        categoryDiv.appendChild(shortcutDiv);
      }
    });
    
    shortcutsGrid.appendChild(categoryDiv);
  });
  
  // Prevent wheel events from propagating to the canvas
  shortcutsModal.addEventListener('wheel', (e) => {
    e.stopPropagation();
  }, { passive: false });
  
  // Show the modal and overlay
  overlay.style.display = 'block';
  shortcutsModal.style.display = 'block';
  
  // Set up the close button
  const closeModal = () => {
    shortcutsModal.style.display = 'none';
    overlay.style.display = 'none';
  };
  
  closeButton.addEventListener('click', closeModal);
  
  // Close on overlay click
  overlay.addEventListener('click', closeModal);
  
  // Close on ESC key
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === KeyboardShortcut.CANCEL.key) {
      closeModal();
      document.removeEventListener('keydown', handleKeyPress);
    }
  };
  
  document.addEventListener('keydown', handleKeyPress);
  
  // Make sure the modal doesn't close when clicking inside it
  shortcutsModal.addEventListener('click', (e) => {
    e.stopPropagation();
  });
}