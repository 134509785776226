import ChunkEditor from '../chunkEditor';
import { Line } from "../types";
import { copyHelpLines } from "./commandUtils";

export class CopyHelpLinesToAllFloorsCommand {
    private imageChunkMover: ChunkEditor;
    private line: Line;
    private floor: number;
    private originalHelpLines: { [key: number]: Line[] };

    constructor(imageChunkMover: ChunkEditor, line: Line, floor: number) {
        this.imageChunkMover = imageChunkMover
        this.line = line.copy()
        this.floor = floor
        this.originalHelpLines = {}
    }

    execute() {
        if (this.imageChunkMover.helpLines[this.floor] === undefined) {
            this.imageChunkMover.helpLines[this.floor] = []
        }
        this.originalHelpLines = copyHelpLines(this.imageChunkMover.helpLines)

        for (let i = 0; i < this.imageChunkMover.chunkManager.getFloorAmount(); i++) {
            if (i === this.floor) {
                continue;
            }
            if (this.imageChunkMover.helpLines[i] === undefined) {
                this.imageChunkMover.helpLines[i] = []
            }
            this.imageChunkMover.helpLines[i].push(this.line)
        }
        this.imageChunkMover.selectedLineIndex = null
    }

    undo() {
        this.imageChunkMover.helpLines = copyHelpLines(this.originalHelpLines)
        this.imageChunkMover.selectedLineIndex = null
    }
}