import { Command } from "./command";

class CommandHistory {
    private history: Command[] = [];
    private pointer = -1;
  
    execute(command: Command) {
      if (this.history.length - 1 > this.pointer) {
        this.history = this.history.slice(0, this.pointer + 1);
      }
      command.execute();
      this.history.push(command);
      this.pointer++;
    }
  
    undo() {
      if (this.pointer < 0) {
        return;
      }
      this.history[this.pointer].undo();
      this.pointer--;
    }
  
    redo() {
      if (this.pointer === this.history.length - 1) {
        return;
      }
      this.pointer++;
      this.history[this.pointer].execute();
    }
  }

export { CommandHistory };